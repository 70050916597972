<script setup>
import { computed } from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: true
  },
  severity: { // pv prop
    type: String
  },
  variant: { // bs prop that passes in to p-message's severity
    type: String
  },
  closable: { // pv prop
    type: Boolean
  },
  dismissible: { // bs prop that passes in to p-message's closable
    type: Boolean
  }
})

const emit = defineEmits(['dismissed'])

const mappedSeverity = computed(() => {
  // using severity prop
  if (props.severity) {
    return props.severity
  }

  // using bootstraps variant prop to primevue's severity prop
  if (props.variant) {
    const map = {
      primary: 'primary',
      secondary: 'secondary',
      success: 'success',
      info: 'info',
      warning: 'warn',
      danger: 'error'
    }
    if (map[props.variant]) {
      return map[props.variant]
    }
  }

  return 'info'
})

</script>

<template>
  <p-message v-if="show"
             :severity="mappedSeverity"
             :closable="closable || dismissible"
             @close="emit('dismissed')"
             style="margin-bottom: 1rem"
             :pt="{
                content: 'align-items-start'
             }">
    <slot></slot>
  </p-message>
</template>

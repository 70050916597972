const index = {
  'text.gap': '0.25rem',
  summary: {
    font: { 
      size: '0.9rem'
    }
  },
  detail: {
    font: {
      weight: 450,
      size: '0.8rem'
    }
  },
  colorScheme: {
    light: {
      success: {
        background: '{success.lighten}',
        detail: {
          color: '{success.darken}'
        },
        border: {
          color: '{success.200}'
        },
        'close.button.focus.ring.color': '{success.lighten}'
      },
      info: {
        background: '{info.lighten}',
        detail: {
          color: '{info.darken}'
        },
        border: {
          color: '{info.200}'
        },
        'close.button.focus.ring.color': '{info.lighten}'
      },
      warn: {
        background: '{warning.lighten}',
        detail: {
          color: '{warning.darken}'
        },
        border: {
          color: '{warning.200}'
        },
        'close.button.focus.ring.color': '{warning.lighten}'
      },
      error: {
        background: '{danger.lighten}',
        detail: {
          color: '{danger.darken}'
        },
        border: {
          color: '{danger.200}'
        },
        'close.button.focus.ring.color': '{danger.lighten}'
      }
    }
  }
}

const pt = {
  root: {
    style: {
      zIndex: '11200' 
    }
  }
}

export { index as default, pt }

import { MarkdownPlugin } from './directives/markdown'
import { ClickCopyPlugin } from './directives/click-copy'
import { IndeterminatePlugin } from './directives/indeterminate'

export default {
  install (Vue, options) {
    MarkdownPlugin.install(Vue, options)
    ClickCopyPlugin.install(Vue, options)
    IndeterminatePlugin.install(Vue, options)
  }
}

export const Currency = {
  SEK: {
    // symbol: 'kr '
    symbol: '$'
  },
  CAD: {
    // symbol: 'CA$'
    symbol: '$'
  },
  USD: {
    symbol: '$'
  }
}

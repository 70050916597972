<script setup>
import { onBeforeMount, ref, nextTick, watch } from 'vue'
const props = defineProps({
  target: {
    required: true
  },
  show: {
    type: Boolean,
    required: false,
    default: false
  },
  resizeHelper: {
    type: Boolean,
    required: false,
    default: false
  }
})
defineOptions({
  inheritAttrs: false
})
const emits = defineEmits(['update:show', 'disabled', 'enabled', 'hidden', 'hide', 'show', 'shown'])

const teleport = ref(false)
const teleportTarget = ref('')
onBeforeMount(() => {
  teleportTarget.value = `tasty-popover-teleport-target-${Math.round(Math.random() * 1000)}`
})

const popoverShowControl = ref(false)
const handleShowControlUpdate = (val) => {
  popoverShowControl.value = val
  emits('update:show', val)
}

defineExpose({ handleShowControlUpdate })

watch(() => props.show, (newVal) => popoverShowControl.value = newVal, { immediate: true })

const dispatchResize = (intervals = [0]) => {
  intervals.forEach(int => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, int)
  })
}

const onDisabled = (bvEvent) => {
  emits('disabled', bvEvent)
}
const onEnabled = (bvEvent) => {
  emits('enabled', bvEvent)
}
const onHidden = async (bvEvent) => {
  emits('hidden', bvEvent)
}
const onHide = async (bvEvent) => {
  emits('hide', bvEvent)
  await nextTick()
  teleport.value = false
}
const onShow = (bvEvent) => {
  emits('show', bvEvent)
}
const onShown = async (bvEvent) => {
  emits('shown', bvEvent)
  await nextTick()
  teleport.value = true

  if (props.resizeHelper) {
    /*
     * Used to help with dynamic content that might drastically change the height of the popover after it's been
     * positioned on the screen, especially handy for bottom aligned popovers
     */
    dispatchResize([0, 100, 500, 1500])
  }
}

</script>

<template>
  <div>
    <b-popover v-bind="$attrs"
               :target="target"
               :show="popoverShowControl"
               @update:show="handleShowControlUpdate"
               @disabled="onDisabled"
               @enabled="onEnabled"
               @hidden="onHidden"
               @hide="onHide"
               @show="onShow"
               @shown="onShown">
      <template #title>
        <slot name="title" :close="handleShowControlUpdate"/>
      </template>
      <div :id="teleportTarget"></div>
    </b-popover>

    <Teleport v-if="teleport"
              :to="`#${teleportTarget}`">
      <slot />
    </Teleport>
  </div>
</template>

<style scoped lang="scss">
</style>

import 'bootstrap'
import {
  // AspectPlugin
  // AvatarPlugin
  // ButtonToolbarPlugin
  // BreadcrumbPlugin
  // FormRatingPlugin
  // FormSpinbuttonPlugin
  // FormTimepickerPlugin,
  // JumbotronPlugin,
  // MediaPlugin
  // PaginationNavPlugin,
  // SkeletonPlugin
  // SpinnerPlugin
  // TimePlugin,
  // ToastPlugin

  // AlertPlugin,
  // BAlert,

  // BadgePlugin,
  BBadge,

  // ButtonPlugin,
  BButton,
  // BButton as BBtn,
  BButtonClose,
  // BButtonClose as BBtnClose,

  // ButtonGroupPlugin,
  BButtonGroup,
  // BButtonGroup as BBtnGroup,

  // CalendarPlugin, // unsure if this is used yet
  // BCalendar,

  // CardPlugin,
  BCard,
  BCardHeader,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardImg,
  BCardImgLazy,
  BCardText,
  BCardGroup,

  // CarouselPlugin,
  // BCarousel,
  // BCarouselSlide,

  // CollapsePlugin,
  BCollapse,

  // DropdownPlugin,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BDropdownDivider,
  BDropdownForm,
  BDropdownText,
  BDropdownGroup,
  BDropdownHeader,

  // EmbedPlugin,
  // BEmbed,

  // FormPlugin,
  BForm,
  BFormText,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormDatalist,

  // FormCheckboxPlugin,
  // BFormCheckbox,
  // BFormCheckboxGroup,

  // FormDatepickerPlugin,
  // BFormDatepicker,

  // FormFilePlugin,
  BFormFile,

  // FormGroupPlugin,
  BFormGroup,

  // FormInputPlugin,
  BFormInput,

  // FormRadioPlugin,
  // BFormRadio,
  // BFormRadioGroup,

  // FormTagsPlugin,
  // BFormTags,
  // BFormTag,

  // FormSelectPlugin,
  // BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,

  // FormTextareaPlugin,
  BFormTextarea,

  // ImagePlugin,
  // BImg,
  // BImgLazy,
  //
  // InputGroupPlugin,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroupText,
  BInputGroupAddon,

  // LayoutPlugin,
  // BContainer,
  // BCol,
  // BRow,
  // BFormRow,

  // LinkPlugin,
  // BLink,

  // ListGroupPlugin,
  // BListGroup,
  // BListGroupItem,

  // ModalPlugin,
  BModal,

  // NavPlugin,
  BNav,
  BNavText,
  BNavForm,
  BNavItem,
  BNavItemDropdown,

  // NavbarPlugin,
  // BNavbar,
  // BNavbarBrand,
  // BNavbarNav,
  // BNavbarToggle,

  // OverlayPlugin,
  // BOverlay,

  // PaginationPlugin,
  // BPagination,

  // PopoverPlugin,
  BPopover,

  // ProgressPlugin,
  // BProgress,
  // BProgressBar,

  // SidebarPlugin,
  // BSidebar,

  // TablePlugin, TableLitePlugin, TableSimplePlugin,
  // BTable,
  // BTableLite,
  // BTableSimple,
  // BTbody,
  // BThead,
  // BTfoot,
  // BTr,
  // BTh,
  // BTd,

  // TabsPlugin,
  BTab,
  BTabs,

  // TooltipPlugin,
  // BTooltip,

  // directives
  // VBHoverPlugin
  // VBScrollspyPlugin
  // VBVisiblePlugin

  // VBModalPlugin,
  VBModal,

  // VBPopoverPlugin,
  VBPopover,

  // VBTogglePlugin,
  VBToggle,

  // VBTooltipPlugin,
  // VBTooltip,

  BVModalPlugin,
  BVToastPlugin

} from 'bootstrap-vue'

import TastyCheckbox from 'core-ui/components/tasty/tastyCheckbox.vue'
import TastyRadio from 'core-ui/components/tasty/tastyRadio.vue'
import TastyRadioGroup from 'core-ui/components/tasty/tastyRadioGroup.vue'
import TastyCheckboxGroup from 'core-ui/components/tasty/tastyCheckboxGroup.vue'
import TastySelect from 'core-ui/components/tasty/tastySelect.vue'

// BAlert.compatConfig = { MODE: 2 }
// BBadge.compatConfig = { MODE: 2 }
[ // BAlert,
  BBadge,
  BButton, BButtonClose, BButtonGroup,
  // BCalendar,
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BCardFooter, BCardImg, BCardImgLazy, BCardText, BCardGroup,
  // BCarousel, BCarouselSlide,
  BCollapse,
  BDropdown, BDropdownItem, BDropdownItemButton, BDropdownDivider, BDropdownForm, BDropdownText, BDropdownGroup, BDropdownHeader,
  // BEmbed,
  BForm, BFormText, BFormInvalidFeedback, BFormValidFeedback, BFormDatalist,
  // BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  // BFormTags, BFormTag,
  BFormSelectOption, BFormSelectOptionGroup,
  BFormTextarea,
  // BImg, BImgLazy,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend, BInputGroupText, BInputGroupAddon,
  // BContainer, BCol, BRow, BFormRow,
  // BLink,
  // BListGroup, BListGroupItem,
  BModal,
  BNav, BNavText, BNavForm, BNavItem, BNavItemDropdown,
  // BNavbar, BNavbarBrand, BNavbarNav, BNavbarToggle,
  // BOverlay,
  // BPagination,
  BPopover,
  // BProgress,
  // BProgressBar,
  // BSidebar,
  // BTable, BTableLite, BTableSimple, BTbody, BThead, BTfoot, BTr, BTh, BTd,
  BTab, BTabs,
  // BTooltip
]
  .forEach(comp => {
    comp.compatConfig = {
      MODE: 2
      // INSTANCE_LISTENERS: true
    }
  })

export const bootstrapVueNuxtInstall = (nuxtApp) => {
  const Vue = nuxtApp.vueApp
  const components = [
    // { BAlert },
    { BBadge },
    {
      BButton,
      BBtn: BButton,
      BButtonClose,
      BBtnClose: BButtonClose
    },
    {
      BButtonGroup,
      BBtnGroup: BButtonGroup
    },
    // { BCalendar },
    { BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BCardFooter, BCardImg, BCardImgLazy, BCardText, BCardGroup },
    // { BCarousel, BCarouselSlide },
    { BCollapse },
    {
      BDropdown,
      BDd: BDropdown,
      BDropdownItem,
      BDdItem: BDropdownItem,
      BDropdownItemButton,
      BDropdownItemBtn: BDropdownItemButton,
      BDdItemButton: BDropdownItemButton,
      BDdItemBtn: BDropdownItemButton,
      BDropdownHeader,
      BDdHeader: BDropdownHeader,
      BDropdownDivider,
      BDdDivider: BDropdownDivider,
      BDropdownForm,
      BDdForm: BDropdownForm,
      BDropdownText,
      BDdText: BDropdownText,
      BDropdownGroup,
      BDdGroup: BDropdownGroup
    },
    // { BEmbed },
    {
      BForm,
      BFormDatalist,
      BDatalist: BFormDatalist,
      BFormText,
      BFormInvalidFeedback,
      BFormFeedback: BFormInvalidFeedback,
      BFormValidFeedback
      // Added here for convenience
      // BFormRow
    },
    {
      BFormCheckbox: TastyCheckbox,
      BCheckbox: TastyCheckbox,
      BCheck: TastyCheckbox,
      BFormCheckboxGroup: TastyCheckboxGroup,
      BCheckboxGroup: TastyCheckboxGroup,
      BCheckGroup: TastyCheckboxGroup
    },

    // { BFormDatepicker, BDatepicker: BFormDatepicker },
    { BFormFile, BFile: BFormFile },
    { BFormGroup, BFormFieldset: BFormGroup },
    { BFormInput, BInput: BFormInput },
    {
      BFormRadio: TastyRadio,
      BRadio: TastyRadio,
      BFormRadioGroup: TastyRadioGroup,
      BRadioGroup: TastyRadioGroup
    },
    // { BFormTags, BTags: BFormTags, BFormTag, BTag: BFormTag },
    {
      BFormSelect: TastySelect,
      BFormSelectOption,
      BFormSelectOptionGroup,
      BSelect: TastySelect,
      BSelectOption: BFormSelectOption,
      BSelectOptionGroup: BFormSelectOptionGroup
    },
    { BFormTextarea, BTextarea: BFormTextarea },
    // { BImg, BImgLazy },
    { BInputGroup, BInputGroupAddon, BInputGroupPrepend, BInputGroupAppend, BInputGroupText },
    // { BContainer, BRow, BCol, BFormRow },
    // { BLink },
    {
      // BListGroup, // : TastyListGroup,
      // BListGroupItem // : TastyListGroupItem
    },
    { BModal },
    {
      BNav,
      BNavItem,
      BNavText,
      BNavForm,
      BNavItemDropdown,
      BNavItemDd: BNavItemDropdown,
      BNavDropdown: BNavItemDropdown,
      BNavDd: BNavItemDropdown
    },
    // { BNavbar, BNavbarNav, BNavbarBrand, BNavbarToggle, BNavToggle: BNavbarToggle },
    // { BOverlay },
    // { BPagination },
    {
      BPopover
    },
    // { BProgress, BProgressBar },
    // { BSidebar },
    // { BTable, BTableLite, BTableSimple, BTbody, BThead, BTfoot, BTr, BTd, BTh },
    { BTabs, BTab },
    // { BTooltip }
  ]
  components.forEach(optObject => {
    Object.entries(optObject).forEach(([name, component]) => {
      Vue.component(name, component)
    })
  })

  Vue.directive('VBModal', VBModal)
  Vue.directive('b-modal', VBModal)
  Vue.use(BVModalPlugin)

  Vue.directive('VBPopover', VBPopover)
  Vue.directive('b-popover', VBPopover)

  Vue.directive('VBToggle', VBToggle)
  Vue.directive('b-toggle', VBToggle)
  //
  // Vue.directive('VBTooltip', VBTooltip)
  // Vue.directive('b-tooltip', VBTooltip)

  Vue.use(BVToastPlugin)
}

import { marked } from 'marked'

export const Markdown = {
  beforeMount (el, bindings, vnode) {
    mkdStringToHtml(el, bindings)
  },
  updated (el, bindings) {
    mkdStringToHtml(el, bindings)
  }
}
const mkdStringToHtml = (el, bindings) => {
  const val = bindings.value || ''
  const mkdString = marked.parse(val)
  el.innerHTML = `<div class="fluency-markdown">${mkdString}</div>`
  return el
}

export const MarkdownPlugin = {
  install (Vue, options) {
    Vue.directive('markdown', Markdown)
  }
}

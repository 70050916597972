import axios from 'axios'
import { AuthV2 } from '@/plugins/api-routes/authV2'

const $axios = axios.create({
  method: 'POST',
  responseType: 'json',
  baseURL: 'https://api.fluencyinc.co/api/auth/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 60000,
  withCredentials: false
})

const debug = false // turn on for logging
// const oktaDomain = 'dev-470886.oktapreview.com'
const oktaDomain = 'login.fluencyinc.co'
const appHost = window.location.host
let apiOrigin
let env
switch (appHost) {
  case 'localhost:3000':
  case 'backpack-staging.fluencyinc.co':
  case 'internal-staging.fluencyinc.co':
  case 'backpack-staging-vue3.fluencyinc.co':
    apiOrigin = 'https://api-dev.fluencyinc.co'
    env = 'staging'
    break
  case 'canary.fluencyinc.co':
  case 'internal-canary.fluencyinc.co':
    apiOrigin = 'https://api-canary.fluencyinc.co'
    env = 'canary'
    break
  default:
    env = 'prod'
    apiOrigin = 'https://api.fluencyinc.co'
}
const knownHosts = [
  // don't put sso domains in here
  'localhost:3000',
  'backpack-staging.fluencyinc.co',
  'backpack-staging-vue3.fluencyinc.co',
  'canary.fluencyinc.co',
  'one.empowermm.com',
  'backpack.fluencyinc.co'
]
const internalSsoHosts = [
  'internal-staging.fluencyinc.co',
  'internal-canary.fluencyinc.co',
  'internal.fluencyinc.co'
]
const externalSsoHosts = [
  'intuit.fluencyinc.co'
]
const $axios2 = axios.create({
  method: 'POST',
  responseType: 'json',
  baseURL: apiOrigin + '/api',
  headers: {
    'Content-Type': 'text/plain'
  },
  timeout: 60000,
  withCredentials: false
})
const $oktaDirect = axios.create({
  method: 'GET',
  responseType: 'json',
  baseURL: `https://${oktaDomain}/api/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 60000,
  withCredentials: true
})
const getRedirectUrl = () => {
  const h = window?.location.host
  if (h === 'internal-canary.fluencyinc.co') {
    return 'https://canary.fluencyinc.co/login/'
  } else if (h === 'internal-staging.fluencyinc.co') {
    return 'https://backpack-staging.fluencyinc.co/login/'
  }
  for (const host of knownHosts) {
    if (h === host) {
      const p = host === 'localhost:3000' ? 'http://' : 'https://'
      return p + host + '/login/'
    }
  }
  return 'https://backpack.fluencyinc.co/login/'
}
const getClientId = () => {
  // The id of the app to log in to. We've separated staging and prod
  const hn = window.location.host
  switch (hn) {
    case 'localhost:3000':
    case 'backpack-staging.fluencyinc.co':
    case 'internal-staging.fluencyinc.co':
    case 'backpack-staging-vue3.fluencyinc.co':
      return '0oa1vr13gpbzW6iBt0h8'
    default:
      return '0oa1vr45fr8SQnUkT0h8'
  }
}
const iframePassThrough = (sessionToken) => {
  debug && console.log('attempting iframe passthrough')
  return new Promise((resolve, reject) => {
    const cleanUp = () => {
      window.removeEventListener('message', messageCallback)
      const iframe = document.getElementById('loginRedirectIframe')
      if (iframe) {
        iframe.remove()
      }
    }
    const messageCallback = async (event) => {
      if (event.origin === `https://${oktaDomain}`) {
        debug && console.log('received message', event)
        cleanUp()
        resolve(event.data)
      }
    }
    window.addEventListener('message', messageCallback, false)
    const iFrame = document.createElement('iframe')
    let src = `https://${oktaDomain}/oauth2/default/v1/authorize?client_id=${getClientId()}&redirect_uri=${getRedirectUrl()}&response_type=id_token%20token&scope=openid%20offline_access&state=SUCCESS&nonce=whatisnonce&response_mode=okta_post_message`
    if (sessionToken) {
      src += `&sessionToken=${sessionToken}`
    }
    iFrame.setAttribute('width', '0')
    iFrame.setAttribute('height', '0')
    iFrame.setAttribute('id', 'loginRedirectIframe')
    iFrame.classList.add('border-0')
    iFrame.classList.add('position-absolute')
    iFrame.onload = () => {
      window.setTimeout(() => {
        debug && console.log('Didn\'t receive message after 2 seconds ... killing')
        cleanUp()
        reject(new Error('User does not have an active session'))
      }, 2000)
    }
    iFrame.onerror = () => {
      cleanUp()
      reject(new Error('iFrame pass through failed'))
    }
    iFrame.src = src
    document.body.appendChild(iFrame)
  })
}

const getCookie = (cname) => {
  // copied from W3 Schools
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const methods = (nuxtApp) => ({
  apiCall: $axios,
  getClientId,
  getRedirectUrl,
  knownHosts,
  internalSsoHosts,
  externalSsoHosts,
  apiOrigin,
  env,
  apiCall2: AuthV2({
    async post (url, data) {
      return $axios2({ url, data })
    }
  }),
  async getOktaUserFromSession () {
    try {
      const response = await $oktaDirect({ url: '/users/me' })
      return response.data
    } catch (e) {
      console.log(e)
      return false
    }
  },
  async getCurrentOktaSession () {
    try {
      const response = await $oktaDirect({ url: '/sessions/me' })
      return response.data
    } catch (e) {
      console.log(e)
      return false
    }
  },
  getAccessToken: function () {
    return nuxtApp.$store.getters.oktaAccessToken
  },
  getRedirect: function () {
    const redirect = localStorage.getItem('referrerPath')
    localStorage.removeItem('referrerPath')
    if (redirect === '/login/') {
      return undefined
    }
    return redirect
  },
  fetchUser: async function () {
    const fUser = await nuxtApp.$res.fetch.user()
    if (!fUser) {
      this.logout()
      return
    }

    const user = {
      ...fUser,
      roles: fUser.authorities?.map(e => e.toLowerCase()) || [],
      defaultHomePage: fUser.defaultHomePage,
      capabilities: fUser.capabilities,
      preferred_username: fUser.userName,
      email: fUser.userName,
      name: fUser.firstName + ' ' + fUser.lastName,
      given_name: fUser.firstName,
      family_name: fUser.lastName
    }
    nuxtApp.$store.commit('user', user)
    return user
  },
  handleAuthentication ({ accessToken, idToken }) {
    nuxtApp.$store.commit('updateOkta', {
      accessToken,
      idToken
    })
  },
  isAuthenticated: function () {
    return !!this.getAccessToken()
  },
  async validateSession () {
    const session = await this.getCurrentOktaSession()
    debug && console.log(JSON.stringify(session))
    if (session?.status !== 'ACTIVE') {
      this.rememberPageAndLogout()
    }
  },
  async loginRedirect (response, extendSession, dontLogOut) {
    const logout = !dontLogOut
    debug && console.log(response ? 'logging in' : extendSession ? 'extending session' : 'checking user login status')
    if (!this.isAuthenticated() || extendSession) {
      // only necessary if the user does not have an access token, or the extend session flag is present
      if (!response) {
        // no login response so this method call is to refresh or extend a session. Check with Okta to see if a valid session exists.
        const currentSession = await this.getCurrentOktaSession()
        debug && console.log(JSON.stringify(currentSession))
        if (!currentSession || currentSession.status !== 'ACTIVE') {
          debug && console.log('user does not have an active session, sending to login page')
          logout && this.rememberPageAndLogout()
          return false
        }
      }
      try {
        // getting session access token from iframe
        const sessionData = await iframePassThrough(response?.data?.sessionToken)
        if (sessionData.access_token) {
          this.handleAuthentication({ accessToken: sessionData.access_token, idToken: sessionData.id_token })
          return true
        } else {
          debug && console.log('session data did not have an access token')
          logout && this.rememberPageAndLogout()
          return false
        }
      } catch (e) {
        debug && console.log(e)
        logout && this.rememberPageAndLogout()
        return false
      }
    }
  },
  rememberPageAndLogout () {
    if (localStorage) {
      localStorage.setItem('referrerPath', nuxtApp._route.fullPath)
    }
    this.logout(['referrerPath', 'actingAsUser'])
  },
  logout (cacheKeys = []) {
    const keys = ['backpack.app.settings', ...cacheKeys]
    const retainedCache = keys.map(key => ({ key, data: localStorage.getItem(key) }))
    const idToken = nuxtApp.$store.getters.oktaIdToken
    let ssoDomain = getCookie('backpackBrandedLogin-' + env)
    if (!internalSsoHosts.includes(ssoDomain) && !externalSsoHosts.includes(ssoDomain)) {
      ssoDomain = ''
    }
    let redirect = ssoDomain ? `https://${ssoDomain}/login/` : `${window.location.origin}/login/`
    localStorage.clear()
    retainedCache.forEach(key => {
      if (key.data) {
        localStorage.setItem(key.key, key.data)
      }
    })

    if (idToken) {
      redirect = `https://${oktaDomain}/oauth2/default/v1/logout?post_logout_redirect_uri=${redirect}&id_token_hint=${idToken}`
    }
    try {
      window.location = redirect
    } catch (e) {
      // if we can't set the window location for some reason, remove JWT and route to login
      nuxtApp.$store.commit('updateOkta', {
        accessToken: undefined,
        idToken: undefined
      })
      nuxtApp.$router.push('/login/')
    }
  }
})

export const authnNuxtInstall = (nuxtApp) => {
  const auth = methods(nuxtApp)
  nuxtApp.vueApp.config.globalProperties.$authn = auth
  nuxtApp.provide('authn', auth)
}

export const vue3WithCompat = () => ({
  MODE: 3,
  INSTANCE_DESTROY: true, // bv,
  TRANSITION_CLASSES: true, // fluency still has this. need to migrate
  GLOBAL_MOUNT: true, // bv still uses
  GLOBAL_EXTEND: true, // bv still uses, bv-modal for sure
  GLOBAL_PROTOTYPE: true, // bv still uses
  INSTANCE_SET: true,
  INSTANCE_EVENT_EMITTER: true, // bv still uses, bv-tooltip for sure
  INSTANCE_LISTENERS: true, // bv still uses this
  INSTANCE_SCOPED_SLOTS: true, // bv still uses this
  INSTANCE_ATTRS_CLASS_STYLE: true,
  OPTIONS_BEFORE_DESTROY: true, // bv still uses this
  OPTIONS_DESTROYED: true, // bv still uses this
  CUSTOM_DIR: true, // - bv still has outdated hook names. backpack is good
  ATTR_FALSE_VALUE: true, // bootstrapVue still hits this. backpack is acceptable
  COMPONENT_FUNCTIONAL: true, // bv still uses this
  COMPONENT_V_MODEL: true, // bv + fluency
  RENDER_FUNCTION: true // bv
})

export const vue3Only = () => ({
  MODE: 3,
  INSTANCE_DESTROY: false,
  TRANSITION_CLASSES: false,
  GLOBAL_MOUNT: false,
  GLOBAL_EXTEND: false,
  GLOBAL_PROTOTYPE: false,
  INSTANCE_SET: false,
  INSTANCE_EVENT_EMITTER: false,
  INSTANCE_LISTENERS: false,
  INSTANCE_SCOPED_SLOTS: false,
  INSTANCE_ATTRS_CLASS_STYLE: false,
  OPTIONS_BEFORE_DESTROY: false,
  OPTIONS_DESTROYED: false,
  CUSTOM_DIR: false,
  ATTR_FALSE_VALUE: false,
  COMPONENT_FUNCTIONAL: false,
  COMPONENT_V_MODEL: false,
  RENDER_FUNCTION: false
})


// note that there are components that have specific compat configs - this was done to make fluency compoennts compatible while bootstrapVue still has vue2 behavior
export const vue2WithCompat = () => ({
  MODE: 2,
  GLOBAL_MOUNT_CONTAINER: false,
  CONFIG_DEVTOOLS: false,
  CONFIG_IGNORED_ELEMENTS: false,
  PROPS_DEFAULT_THIS: false,
  INSTANCE_DESTROY: true, // bv,
  GLOBAL_PRIVATE_UTIL: false,
  CONFIG_PRODUCTION_TIP: false,
  CONFIG_SILENT: false,
  TRANSITION_CLASSES: true, // fluency still has this. need to migrate
  GLOBAL_MOUNT: true, // bv still uses
  GLOBAL_EXTEND: true, // bv still uses, bv-modal for sure
  GLOBAL_PROTOTYPE: true, // bv still uses
  GLOBAL_SET: false,
  GLOBAL_DELETE: false,
  GLOBAL_OBSERVABLE: false,
  CONFIG_KEY_CODES: false,
  CONFIG_WHITESPACE: false,
  INSTANCE_SET: true, // bv table uses this
  INSTANCE_DELETE: false,
  INSTANCE_EVENT_EMITTER: true, // bv still uses, bv-tooltip for sure
  INSTANCE_EVENT_HOOKS: false,
  INSTANCE_CHILDREN: false,
  INSTANCE_LISTENERS: true, // bv still uses this
  INSTANCE_SCOPED_SLOTS: true, // bv still uses this
  INSTANCE_ATTRS_CLASS_STYLE: true, // date range picker or anything with "inheritAttrs: false"
  OPTIONS_DATA_FN: false,
  OPTIONS_DATA_MERGE: false,
  OPTIONS_BEFORE_DESTROY: true, // bv still uses this
  OPTIONS_DESTROYED: true, // bv still uses this
  WATCH_ARRAY: false,
  V_ON_KEYCODE_MODIFIER: false,
  CUSTOM_DIR: true, // - bv still has outdated hook names. backpack is good
  ATTR_FALSE_VALUE: true, // bootstrapVue still hits this. backpack is acceptable
  ATTR_ENUMERATED_COERCION: false,
  TRANSITION_GROUP_ROOT: false,
  COMPONENT_ASYNC: false,
  COMPONENT_FUNCTIONAL: true, // bv still uses this
  COMPONENT_V_MODEL: true, // bv + fluency
  RENDER_FUNCTION: true, // bv
  FILTERS: false
})

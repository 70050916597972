
export const state = () => ({
  // flagsListData: [],
  builderData: {},
  sectionElementEditor: {
    show: false,
    sectionIndex: null,
    elementIndex: null
  }
})

export const mutations = {
  builderData (state, builderData) {
    state.builderData = builderData
  },
  openSectionElementEditor (state, { sectionIndex, elementIndex }) {
    state.sectionElementEditor = {
      show: true,
      sectionIndex,
      elementIndex
    }
  },
  closeSectionElementEditor (state) {
    state.sectionElementEditor = {
      show: false,
      sectionIndex: null,
      elementIndex: null
    }
  }
}

export const actions = {
  async fetchBuilderData ({ commit, state }, { accountPlanId, forceFetch }) {
    if (!accountPlanId) {
      return
    }
    if (state.builderData?.accountPlanId === accountPlanId && !forceFetch) {
      return state.builderData
    }
    commit('builderData', {})
    const resp = await this.$res.colab.getBuilderData({ accountPlanId })
    if (resp) {
      commit('builderData', resp)
    }
  }
}

// TODO do i want to add the account details in here that delegates to the root vuex?

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

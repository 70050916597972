export const Suggest = (flujax) => ({
  get (payload) {
    /*
     * FORMAT OF `payload` OBJECT IS:
     * {
     *     accountPlanId: 23381,
     *     objectType: 'AccountPlan',
     *     objectId: 23381,
     *     objectRequested: 'creative.images',
     *     responseFormat: 'json' (optional for 'keywords' only right now)
     * }
     *
     * CURRENT SUPPORTED VALUES FOR `objectRequested` ARE:
     * 'keywords'
     * 'negatives'
     * 'creative.headlines'
     * 'creative.descriptions'
     * 'creative.images'
     * 'creative.logos'
     */

    return flujax.post('/suggest/get', payload)
  },
  help (payload) {
    return flujax.post('/suggest/help', payload)
  },
  rateResponse (id, rating) {
    const payload = {
      trailguideResponseId: id,
      userResponseScore: rating
    }

    if (payload.trailguideResponseId === undefined && payload.userResponseScore === undefined) {
      return
    }

    return flujax.post('/suggest/help/response', payload)
  }
})

import { shallowRef } from 'vue'

const accountsByIdRef = shallowRef(new Map())

export function useAccountsById () {
  return {
    accountsByIdRef,
    getAccountsById: () => accountsByIdRef.value,
    updateAccountsById (newAccountsById) {
      let old = accountsByIdRef.value
      accountsByIdRef.value = new Map(newAccountsById)

      if (newAccountsById) {
        newAccountsById.clear()
        newAccountsById = null
      }
      if (old) {
        old.clear()
        old = null
      }
    }
  }
}

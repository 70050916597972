import moment from 'moment'

/**
 * These are standardized GA keys defined as custom dimensions in GA.
 * (GA Analyics -> Admin - Data Display -> Custom Definitions)
 *
 * If a tracking event has a category passed in that matches one of the
 * keys in TRACKING_CATEGORY_PROPS, it will try to auto add these values for you.
 * This variable is just to point it to a real value.
 *
 * Example, using `this.$track.event('open notes', {}, 'manage', event.target.closest('button'))`
 * where 'manage' is the category and the button is the element,
 * it will auto add active_item_name, active_item_plan_type, etc... all the key values
 * from TRACKING_CATEGORY_PROPS.manage
 * and send those as event meta.
 */
const get$store = (vueApp) => vueApp.$store
const get$route = (vueApp) => vueApp._route

export const TRACKING_KEY_MAP = {
  active_item_name: (vueApp) => get$store(vueApp).getters.activeItem?.name,
  active_item_plan_type: (vueApp) => get$store(vueApp).getters.activeItem?.planType,
  active_item_plan_id: (vueApp) => get$store(vueApp).getters.activeItem?.[get$store(vueApp).getters.activeItem?.planType + 'PlanId'],

  settings_item_name: (vueApp) => get$store(vueApp).getters.settingsItem?.name,
  settings_item_plan_type: (vueApp) => get$store(vueApp).getters.settingsItem?.planType,
  settings_item_plan_id: (vueApp) => get$store(vueApp).getters.settingsItem?.[get$store(vueApp).getters.settingsItem?.planType + 'PlanId'],

  table_type: (vueApp) => get$store(vueApp).getters['table/appliedFiltersHash'] || get$route(vueApp).query?.table,
  table_selected_rows: (vueApp) => {
    const selectedRows = get$store(vueApp).getters['table/selectedRowObjs'](TRACKING_KEY_MAP.table_type(vueApp))
    if (!selectedRows) return
    return selectedRows?.length
  },

  date_range: (vueApp) => {
    const [startDateTime, endDateTime] = get$store(vueApp).getters.timeRange
    if (!startDateTime || !endDateTime) return

    const start = moment(startDateTime).format('MM/DD/YYYY')
    const end = moment(endDateTime).format('MM/DD/YYYY')

    return `${start} - ${end}` // todo: what format works best here?
  },

  date_range_compare: (vueApp) => {
    return get$store(vueApp).getters.timeRangeCompare
  },

  is_drawer_open: (vueApp) => get$route(vueApp).query?.hasOwnProperty('settingsItemId'),

  icon: (vueApp, element) => {
    const classes = element?.querySelector('.fluency-icon, .fluency-svg-icon')?.classList

    if (!classes) return

    return Array.from(classes).join(', ')
  },
  label: (vue, element) => {
    let html = element?.innerText?.trim()

    if (html) {
      html = html.replace(/[^a-zA-Z0-9\s]/g, '')
    }

    return html
  },
  hover_label: (vue, element) => element?.getAttribute('title') || element?.getAttribute('data-original-title'),
  is_pwa: () => !!(window?.matchMedia('(display-mode: standalone)')?.matches)
}

/**
 * Mapping for standardized GA keys to corresponding data from store or from a node.
 * All keys listed here must be able to be mapped to data in the TRACKING_KEY_MAP
 */
export const TRACKING_CATEGORY_PROPS = {
  global: [
    'icon',
    'label',
    'hover_label',
    'date_range',
    'date_range_compare',
    'is_pwa'
  ],
  manage: [
    'active_item_name',
    'active_item_plan_type',
    'active_item_plan_id',
    'settings_item_name',
    'settings_item_plan_type',
    'settings_item_plan_id',
    'is_drawer_open',
    'table_type',
    'table_selected_rows'
  ],
  data_explorer: [],
  notifications: [],
  dashboard: [],
  launch: [],
  blueprints: [],
  insights: [],
  navigation: []
}

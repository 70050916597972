export const state = () => ({
  filters: {},
  selectedRowObjs: {},
  currentSort: {},
  columns: {},
  accountTotals: undefined,
  appliedFiltersHash: ''
})
export const mutations = {
  columnFilters: (state, { options, table, key }) => {
    const newState = { ...state.filters }
    const newStateTable = { ...newState[table] }
    newStateTable[key] = options
    newState[table] = newStateTable
    state.filters = newState
  },
  removeColumnFilter: (state, { table, key }) => {
    const newState = { ...state.filters }
    const newStateTable = { ...newState[table] }
    delete newStateTable[key]
    newState[table] = newStateTable
    state.filters = newState
  },
  removeAllColumnFilters: (state, table) => {
    state.filters[table] = {}
  },
  selectedRowObjs: (state, { rows, type }) => {
    const newState = { ...state.selectedRowObjs }
    newState[type] = rows
    state.selectedRowObjs = newState
  },
  clearAllSelectedRows: (state) => {
    state.selectedRowObjs = {}
  },
  currentSort: (state, { desc, table, key }) => {
    state.currentSort = { desc, table, key }
  },
  columns: (state, columns) => {
    state.columns = columns
  },
  accountTotals: (state, totals) => {
    state.accountTotals = totals
  },
  appliedFiltersHash: (state, hash) => {
    state.appliedFiltersHash = hash
  }
}
export const getters = {
  selectedRowObjs: (state) => (type) => {
    return state.selectedRowObjs[type]
  },
  columnFilters: (state) => (type) => {
    return state.filters[type]
  },
  currentSort (state) {
    return state.currentSort
  },
  accountTotals (state) {
    return state.accountTotals
  },
  columns (state) {
    return state.columns
  },
  selectedColumnSets (state) {
    return function (columnKey) {
      const fieldOrder = state.columns[columnKey]?.order
      if (!fieldOrder) return []
      return fieldOrder.selectedColumnSets || []
    }
  },
  visibleFields (state) {
    return function (columnKey, defaultTableFields) { // columnKey
      const fieldOrder = state.columns[columnKey]?.order
      if (!fieldOrder) return defaultTableFields
      const columns = fieldOrder.columnList || fieldOrder

      // let columnMap = columns.toMap(col => JSON.parse(col.columnName))
      const columnMap = columns.toMap(col => col.columnName)

      const visibleFields = defaultTableFields.map((field, index) => {
        const fieldKey = field.slot || field.key || field
        return {
          fieldKey,
          index: columnMap[fieldKey]?.columnOrder ?? index,
          field,
          column: columnMap[fieldKey]
        }
      }).filter(fieldColumnPair => !fieldColumnPair.column || fieldColumnPair.column.visible) // column not defined then we added the field, or column is visible
        .chainedSort((a, b) => a.index - b.index)
        .map(fieldColumnPair => fieldColumnPair.field)

      return visibleFields
    }
  },
  appliedFiltersHash (state) {
    return state.appliedFiltersHash
  },
  hasSelectedRows (state) {
    return Object.keys(state.selectedRowObjs || {}).length > 0
  }
}

export const actions = {
  async getColumns (context, { columnKey, type }) {
    if (context.getters.columns[columnKey]?.[type]) {
      return context.getters.columns[columnKey][type]
    } else {
      const key = type === 'order' ? columnKey : `${columnKey}_${type}`
      const columnData = await this.$res.fetch.columnFields(key)
      if (columnData) {
        if (type === 'order' && (columnData.columnList?.length > 0 || columnData?.length > 0)) {
          // this sucks, but have to support the original for now
          const list = columnData.columnList || columnData
          list.forEach(col => {
            if (col.columnName.startsWith('"')) {
              col.columnName = JSON.parse(col.columnName) // pre-parse the JSON if needed (we used to serialize the whole field object, instead of just the column name)
            }
          })
        }
        const columns = { ...context.getters.columns }
        columns[columnKey] = {
          ...columns[columnKey],
          [type]: columnData
        }
        context.commit('columns', columns)
      }
      return columnData || []
    }
  },
  async setColumns (context, { columnKey, columnObject, type }) {
    const columns = { ...context.getters.columns }
    const columnsKey = { ...columns[columnKey] }
    columnsKey[type] = columnObject
    columns[columnKey] = columnsKey
    context.commit('columns', columns)
    const key = type === 'order' ? columnKey : `${columnKey}_${type}`
    // set column data in DB
    await this.$res.set.columnFields(key, columnObject)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

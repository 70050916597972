<script>
import { BFormRadio } from 'bootstrap-vue'

export default {
  extends: BFormRadio,
  render: null,
  computed: {
    hasDefaultSlot () {
      return this.$slots.default
    }
  },
  methods: {
    getInputProps () {
      return {
        computedAttrs: this.computedAttrs,
        isPlain: this.isPlain,
        isCustom: this.isCustom,
        hasDefaultSlot: this.hasDefaultSlot,
        isBtnMode: this.isBtnMode,
        stateClass: this.stateClass,
        value: this.value,
        isChecked: this.isChecked,
        handleChange: this.handleChange,
        handleFocus: this.handleFocus
      }
    }
  }
}
</script>

<script setup>
import { defineComponent, h } from 'vue'

// this is a little helper component so we don't have to repeat the same input template three times.
const InnerInput = defineComponent((props) => {
  return () => {
    return h('input', {
      ...props.computedAttrs,
      class: [{
        'form-check-input': props.isPlain,
        'custom-control-input': props.isCustom,
        'position-static': props.isPlain && !props.hasDefaultSlot
      }, props.isBtnMode ? '' : props.stateClass],
      value: props.value,
      checked: props.isChecked,
      onChange: ($event) => props.handleChange($event),
      onFocus: ($event) => props.isBtnMode ? props.handleFocus($event) : {},
      onBlur: ($event) => props.isBtnMode ? props.handleFocus($event) : {},
      key: 'input'
    })
  }
}, {
  props: ['computedAttrs', 'isPlain', 'isCustom', 'hasDefaultSlot', 'isBtnMode', 'stateClass', 'value', 'isChecked', 'handleChange', 'handleFocus']
})

</script>

<template>
  <div v-if="isBtnMode && !isGroup" class="btn-group-toggle d-inline-block">
    <label :class="buttonClasses">
      <inner-input v-bind="getInputProps()" ref="input"/>
      <slot></slot>
    </label>
  </div>
  <label v-else-if="isBtnMode && isGroup" :class="buttonClasses">
    <inner-input v-bind="getInputProps()" ref="input"/>
    <slot></slot>
  </label>
  <div v-else :class="[{
    'form-check': isPlain,
    'form-check-inline': isPlain && isInline,
    'custom-control': isCustom,
    'custom-control-inline': isCustom && isInline,
    'custom-checkbox': isCustom && !isRadio && !isSwitch,
    'custom-switch': isSwitch,
    'custom-radio': isCustom && isRadio,
    [`b-custom-control-${computedSize}`] : computedSize && !isBtnMode
  }, bvAttrs.class]"
       :style="bvAttrs.style">
    <inner-input v-bind="getInputProps()" ref="input"/>

    <!--    <input v-bind="computedAttrs"-->
    <!--           :class="[{-->
    <!--              'form-check-input': isPlain,-->
    <!--              'custom-control-input': isCustom,-->
    <!--              'position-static': isPlain && !hasDefaultSlot-->
    <!--            }, isBtnMode ? '' : stateClass]"-->
    <!--           :value="value"-->
    <!--           :checked="isChecked"-->
    <!--           @change="handleChange($event)"-->
    <!--           @focus="isBtnMode ? handleFocus($event) : {}"-->
    <!--           @blur="isBtnMode ? handleFocus($event) : {}"-->
    <!--           key="input"-->
    <!--           ref="input"/>-->

    <label v-if="!isPlain || hasDefaultSlot"
           :for="safeId()"
           :class="{
              'form-check-label': isPlain,
              'custom-control-label': isCustom
           }"
    >
      <slot></slot>
    </label>
  </div>
</template>

<style scoped lang="scss">

</style>

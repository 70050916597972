
export const state = () => ({
  accountPlan: null,
  budgetPlan: null,
  campaignPlan: null,
  adGroupPlan: null,
  keywordPlans: [],
  extensionPlans: [],
  creativePlans: [],
  formPlan: null,
  loading: {
    account: false,
    budget: false,
    campaign: false,
    adGroup: false,
    keywords: false,
    extensions: false,
    creatives: false,
    form: false
  },
  validatorSkeleton: {
    isValid: false,
    ref: null, // provide the ref or the el, not sure which will be more useful
    el: null,
    isOverride: false // TODO allow internal users to override
  },
  exitTo: 'manage',
  hasAttemptedSave: false
})

export const mutations = {
  setAccountPlan (state, accountPlan) {
    state.accountPlan = accountPlan
  },
  setBudgetPlan (state, budgetPlan) {
    state.budgetPlan = budgetPlan
  },
  setCampaignPlan (state, campaignPlan) {
    state.campaignPlan = campaignPlan
  },
  setAdGroupPlan (state, adGroupPlan) {
    state.adGroupPlan = adGroupPlan
  },
  setKeywordPlans (state, keywordPlans) {
    state.keywordPlans = keywordPlans
  },
  updateKeywordPlan (state, { keywordPlan, index }) {
    if ((!index || index < 0) && !keywordPlan.keywordPlanId) {
      console.warn('cannot update keyword plans state without index or plan id')
    }
    if (index && index >= 0) {
      state.keywordPlans[index] = keywordPlan
      state.keywordPlans = [...state.keywordPlans]
    } else {
      let indexOf = state.keywordPlans.findIndex(kp => kp.keywordPlanId === keywordPlan.keywordPlanId)
      if (indexOf >= 0) {
        state.keywordPlans[indexOf] = keywordPlan
        state.keywordPlans = [...state.keywordPlans]
      } else {
        console.warn('Could not find keyword plan with id ' + keywordPlan.keywordPlanId + ' in keywordPlans')
      }
    }
  },

  setExtensionPlans (state, extensionPlans) {
    state.extensionPlans = extensionPlans
  },
  addExtensionPlan (state, extensionPlan) {
    state.extensionPlans.push(extensionPlan)
  },
  setCreativePlans (state, creativePlans) {
    state.creativePlans = creativePlans
  },
  setFormPlan (state, formPlan) {
    state.formPlan = formPlan
  },
  loading (state, loading) {
    // e.g. commit('createFlow/loading', { creatives: false })
    state.loading = {
      ...state.loading,
      ...loading
    }
  },
  exitTo (state, exitTo) {
    // the app the router sends you to when you click exit, namely launch,  manage, settings
    state.exitTo = exitTo
  },
  hasAttemptedSave (state, value) {
    state.hasAttemptedSave = value
  }
}

export const actions = {
  async fetchAccountPlan ({ commit, state }, accountPlanId) {
    if (!accountPlanId) {
      commit('setAccountPlan', null)
      return
    }
    if (state.loading.account) {
      return // something else already kicked this off
    }
    if (accountPlanId === state.accountPlan?.accountPlanId) {
      return // already loaded
    }
    commit('loading', { account: true })
    let accountPlan = await this.$res.fetch.accountDetails('', { accountPlanId })
    let toCommit = accountPlan?.length > 0 ? accountPlan[0] : null
    commit('setAccountPlan', toCommit)
    commit('loading', { account: false })
  },
  async fetchBudgetPlan ({ commit, state }, budgetPlanId) {
    if (!budgetPlanId) {
      commit('setBudgetPlan', null)
      return
    }
    if (state.loading.budget) {
      return
    }
    if (budgetPlanId === state.budgetPlan?.budgetPlanId) {
      return
    }
    commit('loading', { budget: true })
    let budgetPlan = await this.$res.budget.getDetail({ budgetPlanId })
    commit('setBudgetPlan', budgetPlan || null)
    commit('loading', { budget: false })
  },
  async fetchCampaignPlan ({ commit, state, dispatch }, campaignPlanId) {
    if (!campaignPlanId) {
      commit('setCampaignPlan', null)
      return
    }
    if (state.loading.campaign) {
      return
    }
    if (campaignPlanId === state.campaignPlan?.campaignPlan?.campaignPlanId) {
      return
    }
    commit('loading', { campaign: true })
    let response = await this.$res.fetch.plan('campaign', { campaignPlanId })
    let campaignPlan = null
    if (response?.length > 0) {
      // this might be race conditiony -- need to get the full budgetDTO onto the campaignPlan -- maybe the backend can support?
      campaignPlan = response[0]
      await dispatch('fetchBudgetPlan', campaignPlan.budgetPlan?.budgetPlanId)
      if (state.budgetPlan) {
        campaignPlan.budgetPlan = {
          ...campaignPlan.budgetPlan,
          ...state.budgetPlan
        }
      }
    }
    commit('setCampaignPlan', campaignPlan)
    commit('loading', { campaign: false })
  },
  async fetchAdGroupPlan ({ commit, state }, { accountPlanId, adGroupPlanId }) {
    if (!adGroupPlanId || !accountPlanId) {
      commit('setAdGroupPlan', null)
      return
    }
    if (state.loading.adGroup) {
      return
    }
    if (adGroupPlanId === state.adGroupPlan?.adGroupPlanId) {
      return
    }
    commit('loading', { adGroup: true })
    const [adGroupPlan, targeting, criterionPlans, unmanagedTargeting] = await Promise.all([
      this.$res.fetch.plan('adgroup', { adGroupPlanId }),
      this.$res.fetch.adGroupTargeting({ adGroupPlanId, accountPlanId })
      // this.$res.fetch.criterionPlans({ accountPlanId, adGroupPlanId }),
      // this.$res.fetch.unmanagedTargeting({ accountPlanId, adGroupPlanId })
    ])
    const plan = adGroupPlan?.length > 0 ? adGroupPlan[0] : null
    if (plan) {
      plan.criterionPlans = targeting?.criterionPlanDtos ?? []
      plan.unmanagedTargeting = targeting?.adGroupPlanUnmanagedTargeting?.length > 0 ? targeting.adGroupPlanUnmanagedTargeting[0] : null
      plan.dynamicProductAudiences = targeting?.dynamicProductAudiences,
      plan.geoTargetDTO = targeting?.geoTargetDTO
    }
    commit('setAdGroupPlan', plan)
    commit('loading', { adGroup: false })
  },
  async fetchKeywordPlans ({ commit, state }, adGroupPlanId) {
    if (!adGroupPlanId) {
      commit('setKeywordPlans', [])
      return
    }
    if (state.loading.keywords) {
      return
    }
    if (state.keywordPlans.length > 0 && state.keywordPlans.every(kp => kp.adGroupPlanId === adGroupPlanId)) {
      return
    }
    commit('loading', { keywords: true })
    let keywordPlans = await this.$res.fetch.plan('keyword', { adGroupPlanId })
    commit('setKeywordPlans', keywordPlans?.length > 0 ? keywordPlans : [])
    commit('loading', { keywords: false })
  },
  async fetchExtensionPlans ({ commit, state }, { accountPlanId, campaignPlanId, adGroupPlanId }) {
    if (!accountPlanId && !campaignPlanId && !adGroupPlanId) {
      commit('setExtensionPlans', [])
      return
    }
    if (state.loading.extensions) {
      return
    }
    if (state.extensionPlans.length > 0 && state.extensionPlans.every(ep => ep.accountPlanId === accountPlanId && ep.campaignPlanId === campaignPlanId && ep.adGroupPlanId === adGroupPlanId)) {
      return
    }
    commit('loading', { extensions: true })
    let extensionPlans = await this.$res.fetch.plan('extension', { accountPlanId, campaignPlanId, adGroupPlanId }, '?fetchChildren=false')
    commit('setExtensionPlans', extensionPlans?.length > 0 ? extensionPlans : [])
    commit('loading', { extensions: false })
  },
  async fetchCreativePlans ({ commit, state }, { accountPlanId, adGroupPlanId }) {
    if (!adGroupPlanId) {
      commit('setCreativePlans', [])
      return
    }
    if (state.loading.creatives) {
      return
    }
    if (state.creativePlans.length > 0 && state.creativePlans.every(cp => cp.adGroupPlan === adGroupPlanId)) {
      return
    }
    commit('loading', { creatives: true })
    let creativePlans = await this.$res.fetch.plan('creative', { accountPlanId, adGroupPlanId })
    creativePlans = creativePlans?.length > 0 ? creativePlans : []
    creativePlans = creativePlans.filter(c => c.status !== 'REMOVED')
    commit('setCreativePlans', creativePlans)
    commit('loading', { creatives: false })
  },
  async fetchFormPlan ({ commit, state }, { formPlanId }) {
    if (!formPlanId) {
      commit('setFormPlan', null)
      return
    }
    if (state.loading.form) {
      return
    }
    if (state.formPlan && state.formPlan.formPlanId === formPlanId) {
      return
    }
    commit('loading', { form: true })
    let response = await this.$res.fetch.plan('form', { formPlanId })
    commit('setFormPlan', response?.length > 0 ? response[0] : null)
    commit('loading', { form: false })
  },
  async fetchAll ({ dispatch }, { accountPlanId, budgetPlanId, campaignPlanId, adGroupPlanId, formPlanId }) {
    await Promise.all([
      dispatch('fetchAccountPlan', accountPlanId),
      dispatch('fetchBudgetPlan', budgetPlanId),
      dispatch('fetchCampaignPlan', campaignPlanId),
      dispatch('fetchAdGroupPlan', { accountPlanId, adGroupPlanId }),
      dispatch('fetchKeywordPlans', adGroupPlanId),
      dispatch('fetchExtensionPlans', { accountPlanId, campaignPlanId, adGroupPlanId }),
      dispatch('fetchCreativePlans', { accountPlanId, adGroupPlanId }),
      dispatch('fetchFormPlan', { formPlanId })
    ])
  }

}

export const getters = {
  accountPlan (state) {
    return state.accountPlan
  },
  campaignPlan (state) {
    return state.campaignPlan
  },
  adGroupPlan (state) {
    return state.adGroupPlan
  },
  hasAttemptedSave (state) {
    return state.hasAttemptedSave
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

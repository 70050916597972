/*
MCD - I sniped this code out of URL Utils for humans.
* https://github.com/unjs/ufo
*
* These functions are needed to handle the pathLastSlash routing required by s3 static hosting
* Nuxt3 doesn't automatically default its router to use trailing slashes, so I'm implementing
* the pages:extend hook as indicated by https://github.com/nuxt/nuxt/issues/14012#issuecomment-1397340525
*
* */
const TRAILING_SLASH_RE = /\/$|\/\?/

export function hasTrailingSlash (input = '', queryParameters = false) {
  if (!queryParameters) {
    return input.endsWith('/')
  }
  return TRAILING_SLASH_RE.test(input)
}

export function withoutTrailingSlash (input = '', queryParameters = false) {
  if (!queryParameters) {
    return (hasTrailingSlash(input) ? input.slice(0, -1) : input) || '/'
  }
  if (!hasTrailingSlash(input, true)) {
    return input || '/'
  }
  const [s0, ...s] = input.split('?')
  return (s0.slice(0, -1) || '/') + (s.length > 0 ? `?${s.join('?')}` : '')
}

export function withTrailingSlash (input = '', queryParameters = false) {
  if (!queryParameters) {
    return input.endsWith('/') ? input : input + '/'
  }
  if (hasTrailingSlash(input, true)) {
    return input || '/'
  }
  const [s0, ...s] = input.split('?')
  return s0 + '/' + (s.length > 0 ? `?${s.join('?')}` : '')
}

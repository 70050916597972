import FluencyIcon from 'core-ui/components/icons/fluencyIcon.vue'
import TastyPopover from 'core-ui/components/tasty/tastyPopover.vue'
import TastyButtonInline from 'core-ui/components/tasty/tastyButtonInline.vue'
import TastyButtonNavLink from 'core-ui/components/tasty/tastyButtonNavLink.vue'
import TastyAlert from 'core-ui/components/tasty/tastyAlert.vue'
import TastyListGroup from 'core-ui/components/tasty/tastyListGroup.vue'
import TastyListGroupItem from 'core-ui/components/tasty/tastyListGroupItem.vue'
import TastyRow from 'core-ui/components/tasty/tastyRow.vue'
import TastyCol from 'core-ui/components/tasty/tastyCol.vue'

import { vue3Only } from 'core-ui/assets/js/vueCompatFlags'

export const FluencyComponentsPlugin = {
  install (Vue, options) {
    Vue.component('FluencyIcon', FluencyIcon)
    Vue.component('TastyPopover', TastyPopover)

    TastyButtonInline.compatConfig = vue3Only()
    TastyButtonNavLink.compatConfig = vue3Only()
    TastyAlert.compatConfig = vue3Only()
    TastyListGroup.compatConfig = vue3Only()
    TastyListGroupItem.compatConfig = vue3Only()
    TastyRow.compatConfig = vue3Only()
    TastyCol.compatConfig = vue3Only()
    Vue.component('t-button-inline', TastyButtonInline)
    Vue.component('t-button-nav-link', TastyButtonNavLink)
    Vue.component('t-list-group', TastyListGroup)
    Vue.component('t-list-group-item', TastyListGroupItem)
    Vue.component('t-row', TastyRow)
    Vue.component('t-col', TastyCol)
    Vue.component('t-alert', TastyAlert)
  }
}

export default {
  install (Vue, options) {
    FluencyComponentsPlugin.install(Vue, options)
  }
}

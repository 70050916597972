<script>
import { BFormCheckboxGroup } from 'bootstrap-vue'
export default {
  extends: BFormCheckboxGroup,
  render: null
}
</script>

<template>
  <div v-bind="$attrs"
       :class="[groupClasses, 'bv-no-focus-ring']"
       :aria-describedby="null"
       :aria-labelledby="null"
       :aria-invalid=computedAriaInvalid
       :aria-required="required ? 'true' : null"
       :id="safeId()"
       role="group"
       tabindex="-1">
    <slot name="first"></slot>
    <b-form-checkbox v-bind="$attrs"
                     v-for="(option, index) of formOptions"
                     :disabled="option.disabled || false"
                     :id="safeId(`BV_option_${index}`)"
                     :aria-describedby="$attrs['aria-describedby']"
                     :aria-labelledby="$attrs['aria-labelledby']"
                     :value="option.value"
                     :key="`BV_option_${index}`">
      <span v-if="option.html" v-html="option.html"></span>
      <span v-else-if="option.text">{{option.text}}</span>
    </b-form-checkbox>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">

</style>

import useAssets from '@/composables/useAssets'

export const replaceImagePaths = async (content) => {
  if (content) {
    let completedContent = content

    // leaving this in place for older DB entries, but new images should be put in the:
    // `/static/img/newFeatures/` directory and coded as `/img/newFeatures/FILENAME.jpg` in the DB
    while (completedContent.indexOf('src="~') > -1) {
      const imageStart = completedContent.indexOf('src="~') + 5
      const imageEnd = completedContent.indexOf('"', imageStart)
      const image = completedContent.slice(imageStart, imageEnd).replace('~/assets/img/newFeatures/', '')
      const contentBeforeImage = completedContent.slice(0, imageStart)
      const contentAfterImage = completedContent.slice(imageEnd)
      const workingImagePath = await useAssets(`/assets/img/newFeatures/${image}`)
      completedContent = contentBeforeImage + workingImagePath + contentAfterImage
    }

    return completedContent
  }

  return null
}

// basis (17) supports CONTEXTUAL_TARGET and AUDIENCE for the targetType
// (can be lowercase)
// amazon (6) supports audience only
export const Tree = (flujax) => ({
  async search (partnerId, targetType, searchTerm) {
    return flujax.post(`/tree/targeting/search?partnerId=${partnerId}&targetType=${targetType}`, { term: searchTerm })
  },
  getNodeChildren (partnerId, targetType, nodeId) {
    return flujax.get(`/tree/targeting/${nodeId}/children?partnerId=${partnerId}&targetType=${targetType}`)
  },
  getRoots (partnerId, targetType) {
    return flujax.get(`/tree/targeting/roots?partnerId=${partnerId}&targetType=${targetType}`)
  }
})

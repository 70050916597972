import { shallowRef, triggerRef } from 'vue'

const campaignNavigationRef = shallowRef()
campaignNavigationRef.value = {}

export function useCampaignNavigation () {
  return {
    campaignNavigationRef,
    getCampaignNavigation: () => campaignNavigationRef.value,
    updateCampaignNavigation (newCampaignNavigation) {
      campaignNavigationRef.value = { ...campaignNavigationRef.value, ...newCampaignNavigation }
      triggerRef(campaignNavigationRef)
    },
    clearCampaignNavigation () {
      campaignNavigationRef.value = {}
    }
  }
}

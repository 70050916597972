import { shallowRef, triggerRef } from 'vue'

const activeBudgetsRef = shallowRef([])

export function useActiveBudgets () {
  return {
    activeBudgetsRef,
    getActiveBudgets: () => activeBudgetsRef.value,
    updateActiveBudgets (newActiveBudgets) {
      activeBudgetsRef.value = newActiveBudgets
    },
    updateBudgetInActiveBudgets (budget) {
      const index = activeBudgetsRef.value.findIndex(b => b.budgetPlanId === budget.budgetPlanId)
      if (index >= 0) {
        activeBudgetsRef.value[index] = { ...activeBudgetsRef.value[index], ...budget }
        triggerRef(activeBudgetsRef)
      }
    }
  }
}

const delim = ' :: '

const persist = (arr = []) => {
  if (!Array.isArray(arr)) {
    arr = [arr]
  }
  const pageTitle = arr.join(delim) + delim + 'Backpack' + delim + 'Fluency'
  document.title = pageTitle
  localStorage.setItem('backpack-pagetitle', pageTitle)
}

const replace = (input) => {
  localStorage.setItem('backpack-pagetitle', document.title)
  if (Array.isArray(input)) {
    input = input.join(delim)
  }
  const pageTitle = input + delim + 'Backpack' + delim + 'Fluency'
  document.title = pageTitle
}

const reset = () => {
  if (localStorage.getItem('backpack-pagetitle')) {
    document.title = localStorage.getItem('backpack-pagetitle')
  }
}

const set = (input, shouldPersist = false) => {
  if (!input) {
    reset()
  } else if (shouldPersist) {
    persist(input)
  } else {
    replace(input)
  }
}

export const pageTitleNuxtInstall = (nuxtApp) => {
  nuxtApp.vueApp.config.globalProperties.$pageTitle = set
}

// export default defineNuxtPlugin(nuxtApp => {
//   nuxtApp.vueApp.config.globalProperties.$pageTitle = set
// })
// export default function (vue, inject) {
//   inject('pageTitle', set)
// }

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"robots","content":"noarchive"},{"name":"robots","content":"noindex, nofollow"},{"name":"googlebot","content":"noindex, nofollow"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Fluency Backpack Client Management Tool"},{"name":"theme-color","content":"#fff"}],"link":[{"rel":"icon","href":"/favicon.ico?v=3.0.1","sizes":"48x48"},{"rel":"icon","href":"/favicon.svg?v=3.0.1","sizes":"any","type":"image/svg+xml"},{"rel":"apple-touch-icon","href":"/apple-touch-icon-180x180.png"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap"}],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=G-NF9LKYLHKX","async":true}],"noscript":[{"innerHTML":"This website requires JavaScript."}],"title":"Backpack"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false
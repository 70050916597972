<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  extends: BFormSelect,
  render: null
}

</script>

<template>

  <select :class="inputClass"
          :id="safeId()"
          :name="name"
          :form="form || null"
          :multiple="multiple || null"
          :size="size"
          :disabled="disabled"
          :required="required"
          :aria-required="required ? 'true' : null"
          :aria-invalid="computedAriaInvalid"
          v-model="localValue"
          @change="onChange"
          ref="input">
    <slot name="first"></slot>
    <template v-for="(formOption, index) of formOptions" :key="index">
      <b-form-select-option-group v-if="Array.isArray(formOption.options)"
                                  :label="formOption.label"
                                  :options="formOption.options" />
      <b-form-select-option v-else
                            :value="formOption.value"
                            :disabled="formOption.disabled">
        <span v-if="formOption.html" v-html="formOption.html" />
        {{formOption.text}}
      </b-form-select-option>
    </template>
    <slot></slot>
  </select>

</template>

<style scoped lang="scss">

</style>

import { shallowRef } from 'vue'

/**
 * Currently only breadcrumb headers consumes this info
 * The ancestors are populated from the initManage call.
 *
 * Note the accountPlan is not in here because it can be retrieved
 * via getAccountsById
 */

const activeBudget = shallowRef(null)
const activeCampaign = shallowRef(null)
const activeAdGroup = shallowRef(null)

export function useActiveItemAncestors () {

  return {
    activeBudget,
    getActiveBudget: () => activeBudget.value,
    activeCampaign,
    getActiveCampaign: () => activeCampaign.value,
    activeAdGroup,
    getActiveAdGroup: () => activeAdGroup.value
  }

}

<script setup>
defineProps({
  active: {
    type: Boolean,
    default: false
  }
})
const dtOverride = {
  colorScheme: {
    light: {
      text: {
        primary: {
          hoverBackground: 'none',
          activeBackground: 'none',
          color: '{slate.700}'
        }
      }
    }
  }
}
</script>

<template>
  <p-button text class="nav-link t-nav-link" :class="{ active }" :dt="dtOverride">
    <slot></slot>
  </p-button>
</template>

<style scoped lang="scss">
.p-button.p-button-text.nav-link {
  padding: 0.25rem 0.5rem;
  //color: $bionic-700;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}
.p-button.p-button-text.nav-link:hover,
.p-button.p-button-text.nav-link.active {
  color: $fluency-blue;
  border-bottom: 2px solid $fluency-blue;
  border-radius: 0;
}
</style>

import { setActiveInterval } from 'core-ui/utils/setActiveInterval'
import { ref } from 'vue'

const foundUpdate = ref(false)
const isInstalling = ref(false)
const isWaiting = ref(false)

const initializeRegistration = async () => {
  const registration = await navigator.serviceWorker.getRegistration()
  if (registration) {
    if (registration.installing) {
      isInstalling.value = true
    }
    if (registration.waiting) {
      isWaiting.value = true
    }

    registration.addEventListener('updatefound', () => {
      foundUpdate.value = true
      const newWorker = registration.installing
      isInstalling.value = true
      newWorker.addEventListener('statechange', () => {
        if (newWorker.state === 'installed') {
          isWaiting.value = true
        }
      })
    })
  }
}
initializeRegistration()

const skipWaiting = async () => {
  const registration = await navigator.serviceWorker.getRegistration()
  if (registration && !registration.installing && registration.waiting) {
    await deleteRuntimeCache()
    registration.waiting.postMessage({ type: 'SKIP_WAITING' }) // tell the waiting worker to skip waiting. it will install on reload
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 10_000)
    })
  }
}

const deleteRuntimeCache = async () => {
  const cacheKeys = await window.caches.keys()
  if (cacheKeys) {
    const runtimeCache = cacheKeys.find(key => key && (key.startsWith('backpack-runtime') || key.startsWith('workbox-runtime') ) )
    if (runtimeCache) {
      const result = await window.caches.delete(runtimeCache)
      console.log(result ? 'DELETED RUNTIME CACHE!!!!' : 'DID NOT DELETE RUNTIME CACHE :(')
    }
  }
}

const initServiceWorkerInterval = () => {
  /*
  * Ping for sw update on 30s interval when the tab is active
  * When there is an update, it triggers the 'updatefound' event, which is handled in this module
  * */
  // eslint-disable-next-line no-undef
  if (__IS_PROD_BUILD__) {
    // only do this in PROD envs
    const { $bugsnag } = useNuxtApp()
    let c = 0
    const int = setActiveInterval(async () => {
      const intervalRegistration = await navigator.serviceWorker.getRegistration()
      if (intervalRegistration) {
        try {
          await intervalRegistration.update()
          c = 0
        } catch (e) {
          c++
          if (c >= 5) {
            clearInterval(int)
            $bugsnag(new Error('Service Worker has failed 5 Consecutive Intervals'), (event) => {
              event.severity = 'info'
              event.addMetadata('error', e)
            })
          }
        }
      }
    }, 30_000)
  }
}

export const useServiceWorker = function () {
  return {
    foundUpdate,
    isInstalling,
    isWaiting,
    activateWaitingServiceWorker: skipWaiting,
    deleteRuntimeCache,
    initServiceWorkerInterval
  }
}

export const Customer = (flujax) => ({
  async setStatusReport (statusReport) {
    return flujax.post(`/customer/insights/set/status`, statusReport)
  },
  async setPriorities ({ customerId, priorities }){
    return flujax.post(`/customer/insights/priorities/set?customerId=${customerId}`, priorities)
  },
  async setAssignee ({ customerId, userRegistryId }) {
    return flujax.post(`/customer/insights/assignee/set?customerId=${customerId}&userRegistryId=${userRegistryId}`)
  },
  async health ({ customerId, health }) {
    return flujax.post(`/customer/insights/health/set?customerId=${customerId}&health=${health}`)
  },
  async logoUpload (payload, customerId, onUploadProgress, opts = {}) {
    const query = []
    if (opts.enhancedData) query.push('enhanced=true')
    if (opts.useShare) query.push('useShare=true')
    if (customerId) query.push(`customerId=${customerId}`)
    return flujax.set.post(`customer/insights/logo/set?${query.join('&')}`, payload, { onUploadProgress, timeout: 1000 * 5 })
  },
  async insights () {
    return flujax.post('/customer/insights/get')
  },
  async previewDataSourceSetting (customerSetting) {
    if (customerSetting) {
      return flujax.post('/customer/previewDataSourceSetting', customerSetting, { responseType: 'text' })
    }
  },
  async trainingVideos () {
    // return flujax.post('/customer/getTrainingVideos')
    return flujax.post('/video/get')
  },
  async getSupportedConnectorDrivers () {
    return flujax.post('/customer/getSupportedConnectorDrivers')
  },
  async getDataSources () {
    return flujax.post('/customer/getDataSources')
  },
  async webhooks () {
    return flujax.post('/customer/getWebhooks')
  },
  async bills () {
    return flujax.post('/customer/listBills')
  },
  async billPDF (key) {
    return flujax.fetch.get(`/customer/getBillPdf?key=${key}`, {}, { responseType: 'arraybuffer' })
  },
  async getTerms () {
    return flujax.post('/customer/getTermsAndConditions')
  },
  async checkTerms (payload) {
    return flujax.post('/customer/checkIfTermsAccepted', payload)
  },
  async downloadCustomerSheet (id = null) {
    const params = (id) ? `?forCustomerId=${id}` : ''

    const response = await flujax.fetch.get(`/customer/downloadCustomerSheet${params}`)

    // NOTE: this endpoint should return an object like: { csv: '"Account Name", Budget, UUID, "Zip Code"\n"Bob\'s Burgers", 100, 1234, "01234"' }
    // so essentially just a huge string containing CSV formatting inside the value for the key of `csv`, which is
    // why we're only going to return response.csv from this method
    if (response?.csv) {
      return response.csv
    }

    return null
  },
  async state () {
    return flujax.fetch.get('/customer/state')
  },
  async getSettings () {
    return flujax.fetch.get('/customer/getSettings')
  },
  async connect (partner, customRedirectPath) {
    let redirectPath = `/setup/${partner}`
    if (['googlecontent', 'googlegmb', 'youtube'].includes(partner)) {
      redirectPath = '/settings/customer/?customerForm=accounts'
    }
    if (customRedirectPath) {
      redirectPath = customRedirectPath
    }

    return flujax.fetch.get(`/connect/${partner}?redirectPath=${redirectPath}`)
  },
  async get () {
    return flujax.fetch.post('/customer/get')
  },
  async verticals () {
    return flujax.fetch.post('/customer/getVerticals')
  },
  async syncStatus () {
    return flujax.fetch.post('/customer/getPollingStatus')
  },
  async themes (payload) {
    return flujax.fetch.post('/customer/getThemes', payload)
  },
  async all (payload) {
    return flujax.fetch.post('/customer/getAll', payload)
  },
  async getFromHubspot () {
    return flujax.fetch.post('/customer/getFromHubspot')
  },
  async linkAccountsForBilling (accountPlans) {
    return flujax.set.post('/customer/linkAccountsForBilling', accountPlans)
  },
  async setDataSources (dataSource) {
    return flujax.post('/customer/setDataSource', dataSource)
  },
  async removeCustomer (id = null) {
    const params = (id) ? `?forCustomerId=${id}` : ''

    return flujax.fetch.get(`/customer/remove${params}`)
  },
  async setWebhook (payload) {
    return flujax.set.post('/customer/setWebhook', payload)
  },
  async acceptTerms (payload) {
    return flujax.set.post('/customer/acceptTerms', payload)
  },
  async refreshPolling () {
    return flujax.set.post('/customer/refreshPolling')
  },
  async set (payload) {
    return flujax.set.post('/customer/set', payload)
  },
  async getPricingPackages () {
    return flujax.set.post('/customer/pricingPackages/get')
    // @RequestMapping(produces = MediaType.APPLICATION_JSON_VALUE, value = "/api/customer/pricingPackages/get")
    //   public ResponseEntity<?> getPricingPackages(Principal principal) {
    //     final UserRegistry userRegistry = userService.getUserByName(principal.getName());
    //     return ResponseEntity.ok(pricingPackageService.getPricingPackages());
    //   }
  },
  async setPricingPackages (payload) {
    return flujax.set.post('/customer/pricingPackages/set', payload)
    // @RequestMapping(produces = MediaType.APPLICATION_JSON_VALUE, value = "/api/customer/pricingPackages/set")
    //   public ResponseEntity<?> getPricingPackages(@RequestBody PricingPackageService.PricingPackage pricingPackage, Principal principal) {
    //     final UserRegistry userRegistry = userService.getUserByName(principal.getName());
    //     final Customer customer = customerService.getById(userRegistry.getCustomerId());
    //     return ResponseEntity.ok(pricingPackageService.enrollCustomer(customer, pricingPackage));
    //   }
  },
  async regenerateInvoice (payload) {
    return flujax.set.post('/customer/regenerateBilling', payload)
  },
  async setSettings (payload = []) {
    if (!payload || payload.length === 0) {
      return
    }
    return flujax.set.post('/customer/updateSettings', payload)
  },
  async getAccountIngests () {
    return flujax.fetch.post('/customer/getAccountIngests')
  },
  async getAccountIngestCopy (payload) {
    return flujax.fetch.post('/customer/getAccountIngestCopy', payload, { responseType: 'text' })
  },
  async getAccountIngestDetails (payload) {
    return flujax.fetch.post('/customer/getAccountIngestDetails', payload)
  },
  async setAccountIngest (payload) {
    return flujax.set.post('/customer/setAccountIngest', payload)
  },
  async refreshAccountIngest (payload) {
    return flujax.post('/customer/refreshAccountIngest', payload)
  },
  async viewAccountIngestSource (customerAccountSync) {
    return flujax.post('/customer/viewAccountIngestSource', customerAccountSync)
  },
  async previewAccountIngest (payload) {
    return flujax.post('/customer/previewAccountIngest', payload)
  },
  async executePreviewedAccountIngest (payload) {
    return flujax.post('/customer/executePreviewedAccountIngest', payload)
  },
  async getSdf (type = 'customer', payload = {}) {
    // valid `type` values: 'customer', 'account', 'campaign'
    const opts = {
      responseType: 'blob',
      returnFullResponse: true
    }
    const response = await flujax.post(`/sdf/${type}`, payload, opts)

    if (response) {
      const data = window.URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = data
      const fileName = response.headers['x-filename']
      link.download = fileName || 'sdf.zip'
      link.click()

      return true
    }

    return false
  },
  async previewImpressionTagSubmission (payload) {
    const opts = {
      responseType: 'blob',
      returnFullResponse: true
    }
    const response = await flujax.post('googleimpressiontag/account/preview', payload, opts)

    if (response) {
      const data = window.URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = data
      const fileName = response.headers['x-filename']
      link.download = fileName || `impression-tag-submission-${payload.accountPlanId}-${Date.now()}.xlxs`
      link.click()

      return true
    }

    return false
  },
  async submitImpressionTagSubmission (payload) {
    return flujax.post('/googleimpressiontag/account/submit', payload)
  },
  async generateDatasetUrl (dataset, expires) {
    let path = '/customer/generateSelfSignedUrlForDataset'
    if (expires) {
      path += `?expires=${expires}`
    }
    return flujax.post(path, dataset)
  },
  async fetchDatasetUrls () {
    return flujax.post('/customer/fetchSelfSignedUrls')
  },
  async updateDatasetUrlExpiration (selfSignedUrl) {
    return flujax.post('/customer/updateExpirationForSelfSignedUrl', selfSignedUrl)
  },
  async deleteDatasetUrl (selfSignedUrl) {
    return flujax.post('/customer/deleteSelfSignedUrl', selfSignedUrl)
  },
  async channelCapabilities (channelIds, entity) {
    return flujax.post('/customer/channels/capability', { channelIds, entity })
  },
  async fetchUnlinkedAccounts () {
    return flujax.fetch.post('/customer/account/import/get')
  },
  async batchImportAccounts (selectedAccounts) {
    return flujax.fetch.post('/account/batch/import', selectedAccounts)
  }
})

import moment from 'moment'
const customerId = localStorage?.getItem('actingAsUser') || JSON.parse(localStorage?.getItem('backpack.user') || '{}').customerId || 0

const CAMPAIGN = {
  'Campaign Name': {
    label: 'Name',
    type: 'STRING',
    required: true
  },
  'Campaign Type': {
    label: 'Channel',
    hidden: true,
    type: 'ENUM',
    default: 'SEARCH',
    placeholder: 'Please choose a Channel',
    enumValues: [
      { value: 'SEARCH', text: 'Search' },
      { value: 'SHOPPING', text: 'Shopping' },
      { value: 'DISPLAY', text: 'Display' },
      { value: 'FACEBOOK', text: 'Facebook' },
      { value: 'WAZE', text: 'Waze' },
      { value: 'YOUTUBE', text: 'YouTube' },
      // { value: 'DISPLAY', text: 'Smart Display' },
      { value: 'APP', text: 'App' },
      { value: 'DDCRTB_TOPIC', text: 'DDC RTB Topic' },
      { value: 'DDCRTB_REMARKETING', text: 'DDC RTB Remarketing' },
      { value: 'DDCRTB_PREMIUM', text: 'DDC RTB Premium' },
      { value: 'DDCRTB_AUDIENCE', text: 'DDC RTB Audience Targeting' },
      { value: 'DDCRTB_VIDEO', text: 'DDC RTB Video' },
      { value: 'PERFORMANCE_MAX', text: 'Performance Max' },
      { value: 'PINTEREST', text: 'Pinterest' },
      { value: 'DV360', text: 'DV360' },
      { value: 'AMAZON', text: 'Amazon' },
      { value: 'DSP_DISPLAY', text: 'DSP Display' },
      { value: 'DSP_VIDEO', text: 'DSP Video' }
    ],
    immutable: true
  },
  existingCampaignMatchStrategy: {
    label: 'Existing Campaign Matching Strategy',
    default: 'NAME',
    hint: 'When determining the campaign to operate on, use this strategy for finding existing campaigns. *This is a very advanced function, please use only if sure of it\'s use.',
    enumValues: [
      { value: 'NAME', text: 'Use the literal name of the campaign to find existing campaigns.' },
      { value: 'FORMULA_BLUEPRINT', text: 'Use the blueprint and template\'s "formula" to find the existing campaign, when only one campaign is generated from the formula' }
    ],
    type: 'ENUM'
  },
  startDate: {
    label: 'Start Date',
    placeholder: 'ex: 2020-12-01',
    hint: 'This is the earliest a campaign will start. Campaigns launched after this date, but before the ending date, will be eligible on the date launched. The format must be yyyy-mm-dd.<br><br>Instead of a fixed date, you can also specify the Launch date as the start date, e.g. LAUNCH, and optionally use a Launch Offset, e.g. LAUNCH+5 Days. The blueprint will accept Days, Weeks, or Months as the unit.<br><br>Once the campaign starts, the blueprint will not modify the start date.',
    type: 'STRING',
    warningValidation: [
      {
        validate: (value) => {
          if (value) {
            const regex = /^\d+\s+(WEEK|MONTH|DAY)(S)?$/
            if (regex.test(value)) { return true }
          }

          if (value && value.indexOf('[') === -1 && value.indexOf('LAUNCH') === -1) {
            return moment(value, 'YYYY-MM-DD', true).isValid()
          }

          return true
        },
        failText: 'This is not a valid date format. Please use YYYY-MM-DD or a value like \'12 DAYS\', \'1 WEEK\', etc.'
      }
    ],
    dynamicConfig: {
      'Campaign Type': {
        DSP_DISPLAY: {
          required: true
        }
      }
    }
  },
  endDate: {
    label: 'End Date',
    placeholder: 'ex: 2020-12-31',
    hint: 'This campaign will be eligible up until this date. After this date, the campaign will no longer be valid and will be taken down. The format must be yyyy-mm-dd.<br /><br />Instead of a fixed date, you can also specify a length from the start date, e.g. 2 Weeks. The blueprint will accept Days, Weeks and Months as the unit.',
    type: 'STRING',
    warningValidation: [
      {
        validate: (value) => {
          if (value) {
            const regex = /^\d+\s+(WEEK|MONTH|DAY)(S)?$/
            if (regex.test(value)) { return true }
          }

          if (value && value.indexOf('[') === -1 && value.indexOf('LAUNCH') === -1) {
            return moment(value, 'YYYY-MM-DD', true).isValid()
          }

          return true
        },
        failText: 'This is not a valid date format. Please use YYYY-MM-DD or a value like \'12 DAYS\', \'1 WEEK\', etc.'
      },
      {
        validate: (value) => {
          if (value) {
            const regex = /^\d+\s+(WEEK|MONTH|DAY)(S)?$/
            if (regex.test(value)) { return true }
          }

          if (value && value.indexOf('[') === -1 && value.indexOf('LAUNCH') === -1) {
            return !moment(value, 'YYYY-MM-DD').isSameOrBefore(moment())
          }

          return true
        },
        failText: 'This end date is in the past. No campaigns will be launched from this blueprint.'
      }
    ]
  },
  campaignGroup: {
    label: 'Campaign Group',
    type: 'STRING',
    advanced: true,
    required: 'capabilities.CampaignGroupRequired',
    placeholder: 'Optionally enter a campaign group identifier (name or id)',
    hint: 'You may specify a fully formed text entry, a tag value, or an id field that will resolve to a campaign group. If one does not exist, it will be created for you. If the group exists, but is not addressable by the account, this campaign will not generate.'
  },
  'Campaign Meta': {
    label: 'Custom Attributes',
    type: 'STRING',
    placeholder: 'Comma-Delimited (or carriage return delimited) KEY=VALUE',
    multiline: true
  },
  'Campaign Naming Pattern Override': {
    label: 'Campaign Naming Override Pattern',
    type: 'STRING',
    placeholder: 'Format statement for overriding Campaign names at partner integrations'
  },
  'Ad Group Naming Pattern Override': {
    label: 'Ad Group Naming Override Pattern',
    type: 'STRING',
    placeholder: 'Format statement for overriding Ad Group names at partner integrations'
  },
  'Creative Naming Pattern Override': {
    label: 'Creative Naming Override Pattern',
    type: 'STRING',
    placeholder: 'Format statement for overriding Creative names at partner integrations'
  },
  'Update Model': {
    label: 'Auto-Pilot Settings',
    advanced: true,
    default: 'AUTOMATIC',
    hint: 'This determines if this campaign is updated automatically by the strategy and data or through manual means only.',
    type: 'ENUM',
    enumValues: [
      { value: 'AUTOMATIC', text: 'Automatic (Full Automation, at Launch and on Changes)' },
      { value: 'ADD IF MISSING', text: 'Add Only If Missing (Not Managed After Creation)' },
      { value: 'NONE', text: 'Do Not Auto-Manage (Create for New Launches Only)' }
    ],
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  nameOverlapStrategy: {
    label: '"Non-Blueprint" Name Collision Handling',
    type: 'ENUM',
    hint: 'Campaign names must be unique. If we encounter an existing campaign with the exact same name, we can handle this with any of the options below.',
    default: 'BLUEPRINT_DEFAULT',
    enumValues: [
      { value: 'BLUEPRINT_DEFAULT', text: 'Use the top level settings of the blueprint overtake strategy to manage name collisions.' },
      { value: 'RENAME_EXISTING', text: 'Rename the existing campaigns with a suffix of [NBP {date}] and change no other settings.' },
      { value: 'RENAME_EXISTING_PAUSE', text: 'Rename the existing campaigns with a suffix of [NBP {date}] and ensure the non-blueprint campaign is paused.' },
      { value: 'OVERTAKE', text: 'Manage the existing campaign (in-place), changing what needs to be changed. (best used when blueprints closely resemble existing campaign structures)' }
    ]
  },
  'Takeover Strategy': {
    label: '"Inherit From" Name Patterns',
    type: 'TEXT',
    placeholder: 'Set patterns for displacing non-blueprint managed campaigns, multi-lines are OR, * wildcards are supported',
    hint: 'Entering values here will tell Fluency to find non-managed campaigns that match your pattern (* wildcards are supported), and upon launch will allow you to pause any matching that are ENABLED, move the spend of the campaigns under the current budget, retain legacy geo settings, or extensions.',
    multiline: true,
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  pauseInheritFrom: {
    label: '"Inherit From" Status',
    type: 'ENUM',
    default: 'PAUSE',
    placeholder: 'Choose any updating of status that name matched "inherit from". Typically this is used to pause existing.',
    enumValues: [
      { value: 'PAUSE', text: 'Pause campaigns that match name patterns. (default)' },
      { value: 'DO_NOTHING', text: 'Do not modify the status of matched campaigns.' }
    ],
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  transferInheritFromSpend: {
    label: '"Inherit From" existing Spend',
    type: 'ENUM',
    placeholder: 'When transitioning from old campaigns to new, knowing how to count the existing campaign spend is critical.',
    enumValues: [
      { value: 'TRANSFER', text: 'Move the existing campaigns under the blueprint campaign budget. (default)' },
      { value: 'DO_NOTHING', text: 'Take no action. Historic spend may not count against blueprint campaign budgets.' }
    ],
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  takeoverGeo: {
    label: '"Inherit From" Geo Strategy',
    default: 'NONE',
    hint: 'For matching non-blueprint campaigns, any existing geo targets may be used in conjunction with, or instead of the geo strategy defined in this launch. If there are no matches, it will default to the blueprint strategy',
    type: 'ENUM',
    enumValues: [
      { value: 'NONE', text: 'Use the blueprint defined geo strategy for campaigns' },
      { value: 'COMBINED', text: 'Combine all matching campaigns geos into this campaign\'s geo strategy' },
      { value: 'FULL', text: 'Use geos from matching campaigns, set them for this campaign and lock' }
    ],
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  takeoverExtensions: {
    label: '"Inherit From" Extension Strategy',
    default: 'NONE',
    hint: 'For matching non-blueprint campaigns, any existing extensions may be used in conjunction with, or instead of the extensions defined in this launch. If there are no matches, it will default to the blueprint strategy. This applies to callout and structured snippets currently.',
    type: 'ENUM',
    enumValues: [
      { value: 'NONE', text: 'Use the extensions defined in the blueprint only' },
      { value: 'COMBINED', text: 'Combine all matching extensions into this campaign\'s set of extensions' },
      { value: 'FULL', text: 'Use extensions from matching campaigns, set them for this campaign and lock' }
    ],
    hiddenCampaignTypes: [
      'DV360'
    ]
  },

  'Default Budget': {
    required: false,
    label: 'Budget',
    type: 'TEXT',
    placeholder: '0.00',
    hideIfAny: {
      'Campaign Type': [
        'DV360'
      ]
    }
  },
  'Exact Budget': {
    label: 'Exact Budget Amount',
    advanced: true,
    hint: 'Exact Budgets are NOT included in the Launch Budget.  Non-Exact Monthly Recurring budgets will be scaled proportionally to sum up to the Launch Budget.',
    type: 'CHECKBOX',
    hideIfAny: {
      'Campaign Type': [
        'DV360'
      ]
    },
    dynamicConfig: {
      'Campaign Type': {
        DV360: {
          required: false
        }
      }
    }
  },
  'Linked Budget': {
    label: 'Linked Budget',
    type: 'COMBO',
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY',
      'FACEBOOK',
      'YOUTUBE',
      'WAZE',
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE',
      'DDCRTB_VIDEO',
      'AMAZON'
    ]
  },
  'Budget Frequency': {
    label: 'Budget Frequency',
    hint: 'One Time Flight Budgets are NOT included in the Launch Budget.  Non-Exact Monthly Recurring budgets will be scaled proportionally to sum up to the Launch Budget.',
    default: 'MONTHLY',
    type: 'ENUM',
    enumValues: [
      { value: 'MONTHLY', text: 'Monthly Recurring' },
      { value: 'ONCE', text: 'One Time Flight' }
    ],
    applicableCampaignTypes: [
      'FACEBOOK',
      'YOUTUBE'
    ]
  },
  'Budget Update Model': {
    label: 'Auto-Pilot Settings',
    placeHolderText: 'This determines if this budget configuration is updated automatically by the strategy and data or through manual means only.',
    type: 'ENUM',
    enumValues: [
      { value: 'AUTOMATED', text: 'Automatic (Full Automation, at Launch and on Changes)' },
      { value: 'NONE', text: 'Do Not Auto-Manage (Create for New Launches Only)' }
    ]
  },
  'Campaign Launch Status': {
    label: 'Status',
    required: true,
    placeholder: 'Set your campaign status for creation',
    default: 'ENABLED',
    type: 'ENUM',
    enumValues: [
      { value: 'ENABLED', text: 'Enabled' },
      { value: 'PAUSED', text: 'Paused' },
      { value: 'RETAIN_EXISTING_ENABLED_NEW', text: 'Launched as Enabled, Not Updated After Create' }
    ],
    dynamicConfig: {
      'Campaign Type': {
        DV360: {
          type: 'TEXT'
        }
      }
    }
  },
  'Special Ad Category': {
    label: 'Special Ad Categories',
    default: 'None',
    placeholder: 'Select one or more values',
    type: 'MULTICOMBO',
    enumValues: [
      { value: 'CREDIT', text: 'Credit' },
      { value: 'EMPLOYMENT', text: 'Employment' },
      { value: 'HOUSING', text: 'Housing' }
    ],
    applicableCampaignTypes: [
      'FACEBOOK'
    ]
  },
  activationStrategy: {
    label: 'Activation Strategy',
    hint: 'Activation strategy will be evaluated every 4 hours. When the activation strategy has been satisified, the campaign will unpause, takeovers will occur, and the users above will be notified.',
    type: 'ENUM',
    enumValueLoader: '/api/template/getValidActivationStrategies',
    enumValueLoaderParams: {
      'Campaign Type': 'Campaign Type',
      'Campaign Goal': 'Campaign Goal',
      'Special Ad Category': 'Special Ad Category'
    }
  },
  activationNotify: {
    label: 'Notify on Activation',
    placeholder: 'Comma delimited email distribution list to notify when activation is complete for campaign.',
    hint: 'Activation strategy will be evaluated every 4 hours.  When the activation strategy has been satisified, the campaign will unpause, takeovers will occur, and the users above will be notified.',
    default: 'ENABLED',
    type: 'TEXT'
  },
  siteLinkManagementStrategy: {
    label: 'Site Link Control',
    hint: 'Use this to determine how Sitelinks are managed for this campaign.',
    default: 'ADD',
    type: 'ENUM',
    enumValues: [
      { value: 'ADD', text: 'Sitelinks that do not match the blueprint are eligible to remain.' },
      { value: 'FULL', text: 'Fully managed by blueprints, does not allow custom sitelinks.' }
    ],
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  promotedPageOverride: {
    label: 'Promoted Page Override',
    placeholder: 'Facebook Page ID or Page Handle, eg. 1961598690794557 or fluencyinc',
    hint: 'Fluency will automatically use the main page associated with the account, but sometimes campaigns, ad sets, or ads need to promote pages other than the main one.',
    type: 'TEXT',
    applicableCampaignTypes: [
      'FACEBOOK'
    ]
  },
  'Campaign Valid When': {
    label: 'Valid When',
    placeholder: 'Conditions that cause campaign to be valid',
    type: 'TEXT',
    multiline: true
  },
  'Tracking Template': {
    label: 'Tracking Template',
    placeholder: 'URL Tracking Template',
    type: 'TEXT',
    applicableCampaignTypes: [
      'FACEBOOK',
      'DISPLAY',
      'PERFORMANCE_MAX',
      'DISCOVERY',
      'SEARCH',
      'SHOPPING',
      'YOUTUBE'
    ],
    dynamicConfig: {
      'Campaign Type': {
        FACEBOOK: {
          label: 'URL Parameters',
          placeholder: 'URL Parameters'
        }
      }
    }
  },
  'Campaign Goal': {
    label: 'Goal',
    placeholder: 'Choose your success metric for your campaigns',
    required: true,
    applicableCampaignTypes: [
      'FACEBOOK',
      'YOUTUBE',
      'DISPLAY',
      'DV360'
    ],
    type: 'ENUM',
    enumValueLoader: '/api/campaign/getGoals',
    enumValueLoaderParams: {
      'Campaign Type': 'Campaign Type'
    },
    dynamicConfig: {
      'Campaign Type': {
        FACEBOOK: {
          immutable: true,
          immutableOverride: true,
          immutableReason: 'Facebook does not allow you to change the Goal of existing campaigns.',
          hint: 'The Goal is the action you want people to make when they see your ads.<br><br>You cannot change the goal of existing Facebook campaigns.<br><br>Instead, create a new campaign in your blueprint with a new goal.'
        },
        YOUTUBE: {
          immutable: true,
          immutableOverride: true,
          immutableReason: 'Youtube does not allow you to change the Goal of existing campaigns.',
          hint: 'The Goal is the action you want people to make when they see your ads.<br><br>You cannot change the goal of existing YouTube campaigns.<br><br>Instead, create a new campaign in your blueprint with a new goal.'
        },
        DV360: {
          type: 'COMBO'
        }
      }
    }
  },
  'Final URL Suffix': {
    label: 'Final URL Suffix (used for tracking purposes)',
    placeholder: 'Final URL Suffix',
    type: 'TEXT',
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  'Bidding Strategy': {
    placeholder: 'Optionally, select a bid strategy. If nothing is selected, use Fluency defaults.',
    default: 'NONE',
    label: 'Bid Strategy',
    type: 'ENUM',
    enumValues: [
      { value: 'NONE', text: 'None (Use System Defaults)', type: 'SEARCH' },
      { value: 'NONE', text: 'None (Use System Defaults)', type: 'FACEBOOK' },
      { value: 'NONE', text: 'None (Use System Defaults)', type: 'DISPLAY' },
      { value: 'NONE', text: 'None (Use System Defaults)', type: 'YOUTUBE' },
      { value: 'NONE', text: 'None (Use System Defaults)', type: 'SHOPPING' },
      { value: 'MANUAL_CPC', text: 'Manual CPC', type: 'SEARCH' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'SEARCH' },
      { value: 'MAXIMIZE_CONVERSION_VALUE', text: 'Maximize conversion value', type: 'SEARCH' },
      { value: 'TARGET_CPA', text: 'Target CPA (GA integrations)', type: 'SEARCH' },
      { value: 'TARGET_ROAS', text: 'Target ROAS (GA integrations)', type: 'SEARCH' },
      { value: 'TARGET_IMPRESSION_SHARE', text: 'Target Impression Share', type: 'SEARCH' },
      { value: 'MAXIMIZE_CLICKS', text: 'Maximize clicks', type: 'SEARCH' },
      { value: 'MAXIMIZE_CLICKS', text: 'Maximize clicks', type: 'DISPLAY' },
      { value: 'LOWEST_COST', text: 'Lowest Cost Automated Bidding', type: 'FACEBOOK' },
      { value: 'LOWEST_COST_WITH_BID_CAP', text: 'Lowest Cost with Bid Cap', type: 'FACEBOOK' },
      { value: 'TARGET_SPEND', text: 'Target Cost', type: 'FACEBOOK' },
      { value: 'COST_CAP', text: 'Cost Cap', type: 'FACEBOOK' },
      { value: 'TARGET_CPA', text: 'Target CPA (GA integrations)', type: 'DISPLAY' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'DISPLAY' },
      { value: 'MANUAL_CPC', text: 'Manual CPC', type: 'DISPLAY' },
      { value: 'MANUAL_CPM', text: 'Viewable CPM', type: 'DISPLAY' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'YOUTUBE' },
      { value: 'TARGET_CPA', text: 'Target CPA', type: 'YOUTUBE' },
      { value: 'MANUAL_CPV', text: 'Cost per View', type: 'YOUTUBE' },
      { value: 'TARGET_CPM', text: 'Target CPM', type: 'YOUTUBE' },
      { value: 'MANUAL_CPC', text: 'Manual CPC', type: 'SHOPPING' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'SHOPPING' },
      { value: 'MAXIMIZE_CONVERSION_VALUE', text: 'Maximize conversion value', type: 'SHOPPING' },
      { value: 'MAXIMIZE_CLICKS', text: 'Maximize clicks', type: 'SHOPPING' },
      { value: 'TARGET_ROAS', text: 'Target ROAS (GA integrations)', type: 'SHOPPING' },
      { value: 'TARGET_CPA', text: 'Target CPA', type: 'APP' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'APP' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'PERFORMANCE_MAX' },
      { value: 'MAXIMIZE_CONVERSION_VALUE', text: 'Maximize conversion value', type: 'PERFORMANCE_MAX' },
      { value: 'MAXIMIZE_CONVERSIONS', text: 'Maximize conversions', type: 'DISCOVERY' },
      { value: 'MAXIMIZE_CONVERSION_VALUE', text: 'Maximize conversion value', type: 'DISCOVERY' },
      { value: 'MAXIMIZE_CLICKS', text: 'Maximize clicks', type: 'DISCOVERY' },
      { value: 'MANUAL_CPV', text: 'Cost per View', type: 'DV360' },
      { value: 'TARGET_CPA', text: 'Target CPA', type: 'DV360' }
    ],
    applicableCampaignTypes: [
      'FACEBOOK',
      'SEARCH',
      'DISPLAY',
      'YOUTUBE',
      'SHOPPING',
      'PERFORMANCE_MAX',
      'DISCOVERY',
      'APP',
      'DV360'
    ],
    hideIfAny: {
      'Campaign Sub-Type': [
        'OUTSTREAM'
      ]
    },
    dynamicConfig: {
      'Campaign Type': {
        APP: {
          placeholder: 'Select a bid strategy.',
          required: true,
          default: 'TARGET_CPA'
        }
      },
      'Campaign Sub-Type': {
        VIDEO_VIEWS: {
          enumValues: [
            { value: 'MANUAL_CPV', text: 'Cost per View', type: 'YOUTUBE' },
            { value: 'TARGET_CPM', text: 'Target CPM (Invalid for Video Views)', type: 'YOUTUBE' }
          ]
        },
        EFFICIENT_REACH: {
          enumValues: [
            { value: 'MANUAL_CPV', text: 'Cost per View (Invalid for Efficient Reach)', type: 'YOUTUBE' },
            { value: 'TARGET_CPM', text: 'Target CPM', type: 'YOUTUBE' }
          ]
        },
        NON_SKIPPABLE_IN_STREAM: {
          enumValues: [
            { value: 'MANUAL_CPV', text: 'Cost per View (Invalid for Non-skippable)', type: 'YOUTUBE' },
            { value: 'TARGET_CPM', text: 'Target CPM', type: 'YOUTUBE' }
          ]
        }
      }
    }
  },
  'Target Roas': {
    label: 'Target ROAS',
    type: 'TEXT',
    placeholder: 'Target Return on Ad Spend',
    showIfAny: {
      'Bidding Strategy': [
        'PAGE_ONE_PROMOTED',
        'TARGET_ROAS',
        'MAXIMIZE_CONVERSION_VALUE'
      ]
    }
  },
  'Maximum CPV': {
    label: 'Maximum CPV',
    type: 'TEXT',
    placeholder: 'Enter a dollar amount for the target cost-per-view (CPV).',
    applicableCampaignTypes: [
      'DV360'
    ],
    showIfAny: {
      'Bidding Strategy': [
        'MANUAL_CPV'
      ]
    }
  },
  'Bidding Strategy Goal Type': {
    required: true,
    label: 'Bidding Strategy Goal Type',
    placeholder: 'Select a bidding strategy goal type.',
    type: 'ENUM',
    enumValues: [
      { value: 'OPTIMIZE_INSTALLS_TARGET_INSTALL_COST', text: 'Optimize installs (target install cost)' },
      { value: 'OPTIMIZE_INSTALLS_WITHOUT_TARGET_INSTALL_COST', text: 'Optimize installs (without targeting install cost)' },
      { value: 'OPTIMIZE_IN_APP_CONVERSIONS_TARGET_INSTALL_COST', text: 'Optimize app conversions (target install cost)' },
      { value: 'OPTIMIZE_IN_APP_CONVERSIONS_TARGET_CONVERSION_COST', text: 'Optimize app conversions (target conversion cost)' }
    ],
    applicableCampaignTypes: [
      'APP'
    ]
  },
  'Dynamic Search Campaign': {
    label: 'Dynamic Search Enabled',
    advanced: true,
    hint: 'When Dynamic Search is enabled, you\'ll be able to create Dynamic Search Ad Groups containing Expanded Dynamic Search Ads.  These allow you to target entire websites, or portions of them, without having to create keywords and ads for all the possible matches.',
    type: 'CHECKBOX',
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  'Target CPA': {
    label: 'Target CPA',
    showIfAny: {
      'Bidding Strategy': [
        'TARGET_CPA',
        'MAXIMIZE_CONVERSIONS'
      ]
    },
    placeholder: 'Enter a dollar amount for the target cost-per-action (CPA).',
    type: 'TEXT'
  },

  'Geo Description': {
    label: 'Geo Targeting Description',
    advanced: true,
    placeholder: 'Describe your targeting',
    hint: 'This is for presentation only and does not drive any behavior. Totally optional.',
    type: 'TEXT',
    applicableCampaignTypes: [
      'SEARCH',
      'FACEBOOK',
      'DISPLAY'
    ]
  },
  postalCodeUse: {
    label: 'Postal Code Use',
    advanced: true,
    hint: 'You may choose to convert all postal codes to radii for your targeting strategy.',
    type: 'ENUM',
    default: 'NORMAL',
    enumValues: [
      { value: 'NORMAL', text: 'Directly Use Postal Codes' },
      { value: 'CONVERT', text: 'Convert Postal Codes To Radius Targets' }
    ],
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  'Default Geo Strategy': {
    required: true,
    label: 'Geo Strategy',
    placeholder: 'drive:30',
    type: 'STRING',
    multiline: true,
    default: 'drive:30',
    hint: 'You can enter any valid geo item from Google, or use one of Fluency\'s dynamic geo strategies.<br><br>Valid directives include national, state, city, radius:xx, or drive:yy. Drive time is an algorithm that will determine the zip codes driveable within yy minutes. Radius:xx is in terms of miles.',
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  'Positive Geo Strategy': {
    label: 'Positive Geo Target Strategy',
    default: 'DONT_CARE',
    type: 'ENUM',
    enumValueLoader: '/api/geo/getPositiveGeoTargetMethodEnum',
    enumValueLoaderParams: {
      'Campaign Type': 'Campaign Type'
    },
    applicableCampaignTypes: [
      'SEARCH',
      // 'FACEBOOK',
      'DISPLAY',
      'PERFORMANCE_MAX'
    ]
    // dynamicConfig: {
    //   'Campaign Type': {
    //     FACEBOOK: {
    //       label: 'Location Types'
    //     }
    //   }
    // }
  },
  'Negative Geo Strategy': {
    label: 'Negative Geo Target Strategy',
    default: 'LOCATION_OF_PRESENCE',
    type: 'ENUM',
    enumValues: [
      { value: 'LOCATION_OF_PRESENCE', text: 'People in your excluded locations' },
      { value: 'DONT_CARE', text: '(Deprecated) People in, or who show interest in, your excluded locations' }
    ],
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY'
    ]
  },
  'Network Setting': {
    label: 'Network Targeting',
    type: 'TEXT',
    multiline: true,
    advanced: true,
    hint: 'Choose each network you want to advertise on. Partner Search Network is not currently supported by Fluency.',
    placeholder: '{"googleSearch":"true", "searchNetwork":"false", "contentNetwork":"false", "partnerSearchNetwork":"false"}',
    default: '{"googleSearch":"true", "searchNetwork":"false", "contentNetwork":"false", "partnerSearchNetwork":"false"}',
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY',
      'YOUTUBE'
    ],
    dynamicConfig: {
      'Campaign Type': {
        YOUTUBE: {
          hint: 'Choose each network you want to advertise on.',
          placeholder: '{"youtube":"true", "contentNetwork":"true", "googleTvNetwork":"false"}',
          default: '{"youtube":"true", "contentNetwork":"true", "googleTvNetwork":"false"}'
        }
      }
    }
  },
  'Ad Schedule Strategy': {
    label: 'Ad Schedule Strategy',
    placeholder: 'Set a Schedule Strategy',
    type: 'ENUM',
    enumValues: [
      { value: '24/7' },
      { value: 'Only When Open' },
      { value: 'Aggressive When Open' },
      { value: 'Start At Open' },
      { value: 'Custom (Advanced)' }
    ],
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY',
      'DISCOVERY',
      'SHOPPING',
      'PERFORMANCE_MAX',
      'WAZE'
    ]
  },
  'Custom Ad Schedule': {
    label: 'Custom Ad Schedule Instructions',
    placeholder: 'Sun(60%), Mon(125%), ...',
    hint: 'Define a custom ad schedule here.<br><br>The syntax is the 3 letter day of week followed by a percentage in parentheses - ex: Mon(125%).<br><br>Use an ampersand to optionally specify hours. - ex: Mon(125%@8am-5pm)<br><br>Chain hour ranges together with a space. - ex: Mon(0%@12am-8am 125%@8am-5pm)<br><br>Chain days together with a comma to separate them. - ex: Mon(125%), Tue(85%)<br><br>Days/hours that are not defined assume nominal (100%) value.<br>Turn days/hours off with a 0% adjustment.',
    default: '',
    type: 'TEXT',
    showIfAny: {
      'Ad Schedule Strategy': [
        'Custom (Advanced)'
      ]
    }
  },
  'Campaign Negative Keywords': {
    label: 'Negative Keywords',
    type: 'TEXT',
    multiline: true,
    applicableCampaignTypes: [
      'SEARCH',
      'SHOPPING'
    ]
  },
  Theme: {
    type: 'STRING',
    default: '',
    advanced: true,
    applicableCampaignTypes: [
      'SEARCH',
      'FACEBOOK',
      'DISPLAY'
    ]
  },
  'Campaign Shared Sets': {
    label: 'Campaign Keyword Lists',
    type: 'TEXT',
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY',
      'YOUTUBE'
    ]
  },
  'Campaign Labels': {
    label: 'Labels',
    type: 'TEXT',
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  'Campaign Labels Additive': {
    label: 'Labels Only Additive',
    advanced: true,
    hint: 'When enabled, existing labels will NOT be removed from campaigns.',
    type: 'CHECKBOX',
    hiddenCampaignTypes: [
      'DV360'
    ]
  },
  'Product Catalog Template': {
    label: 'Catalog Template',
    type: 'TEXT',
    applicableCampaignTypes: [
      'PERFORMANCE_MAX',
      'PINTEREST',
      'SHOPPING'
    ]
  },
  'Campaign GDN Product Set': {
    label: 'Dynamic Remarketing Product Feed',
    type: 'ENUM',
    enumValueLoader: '/api/shopping/product-scope/getEligibleForGoogleDisplay',
    applicableCampaignTypes: [
      'DISPLAY'
    ]
  },
  'Frequency Cap Interval': {
    label: 'Frequency Cap Interval',
    placeholder: 'Interval period',
    type: 'ENUM',
    enumValues: [
      { value: 'DAY', text: 'Day', type: 'FACEBOOK' },
      { value: 'WEEK', text: 'Week', type: 'FACEBOOK' },
      { value: 'MONTH', text: 'Month', type: 'FACEBOOK' },
      { value: 'DAY', text: 'Day', type: 'DISPLAY' },
      { value: 'WEEK', text: 'Week', type: 'DISPLAY' },
      { value: 'MONTH', text: 'Month', type: 'DISPLAY' },
      { value: 'HOUR', text: 'Day', type: 'DSP_DISPLAY' },
      { value: 'DAY', text: 'Day', type: 'DSP_DISPLAY' },
      { value: 'WEEK', text: 'Week', type: 'DSP_DISPLAY' },
      { value: 'MONTH', text: 'Month', type: 'DSP_DISPLAY' },
      { value: 'HOUR', text: 'Day', type: 'DSP_VIDEO' },
      { value: 'DAY', text: 'Day', type: 'DSP_VIDEO' },
      { value: 'WEEK', text: 'Week', type: 'DSP_VIDEO' },
      { value: 'MONTH', text: 'Month', type: 'DSP_VIDEO' }
    ],
    applicableCampaignTypes: [
      'FACEBOOK',
      'DISPLAY',
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
    // applicableCampaignGoals: [
    //   'REACH',
    //   'WEBSITE_TRAFFIC',
    //   'SALES',
    //   'LEADS'
    // ]
  },
  'Frequency Cap Max': {
    label: 'Max Frequency Cap',
    placeholder: 'The maximum frequency, between 1 and 90',
    type: 'TEXT',
    applicableCampaignTypes: [
      'FACEBOOK',
      'DISPLAY',
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
    // applicableCampaignGoals: [
    //   'REACH',
    //   'WEBSITE_TRAFFIC',
    //   'SALES',
    //   'LEADS'
    // ]
  },

  callToAction: {
    label: 'Call to Action',
    type: 'ENUM',
    hint: 'The action people should take when they see your ad.',
    enumValues: [
      { value: 'LEARN_MORE ', text: 'Learn More ', type: 'FACEBOOK' },
      { value: 'SHOP_NOW ', text: 'Shop Now ', type: 'FACEBOOK' },
      { value: 'WATCH_VIDEO ', text: 'Watch Video ', type: 'FACEBOOK' },
      { value: 'APPLY_NOW ', text: 'Apply Now ', type: 'FACEBOOK' },
      { value: 'BUY_NOW ', text: 'Buy Now ', type: 'FACEBOOK' },
      { value: 'BOOK_TRAVEL ', text: 'Book Now ', type: 'FACEBOOK' },
      { value: 'GET_DIRECTIONS ', text: 'Get Directions ', type: 'FACEBOOK' },
      { value: 'SHARE ', text: 'Share ', type: 'FACEBOOK' },
      { value: 'GET_QUOTE ', text: 'Get Quote ', type: 'FACEBOOK' },
      { value: 'CONTACT_US ', text: 'Contact Us ', type: 'FACEBOOK' },
      { value: 'SIGN_UP ', text: 'Sign Up ', type: 'FACEBOOK' },
      { value: 'MESSAGE_PAGE ', text: 'Send Message ', type: 'FACEBOOK' },
      { value: 'CALL_NOW ', text: 'Call Now ', type: 'FACEBOOK' },
      { value: 'DOWNLOAD ', text: 'Download ', type: 'FACEBOOK' },
      { value: 'INSTALL_MOBILE_APP ', text: 'Install Now ', type: 'FACEBOOK' },
      { value: 'USE_MOBILE_APP ', text: 'Use Mobile App ', type: 'FACEBOOK' },
      { value: 'OPEN_LINK ', text: 'Open Link ', type: 'FACEBOOK' },
      { value: 'WATCH_MORE ', text: 'Watch More ', type: 'FACEBOOK' },
      { value: 'USE_APP ', text: 'Use App ', type: 'FACEBOOK' },
      { value: 'PLAY_GAME ', text: 'Play Game ', type: 'FACEBOOK' },
      { value: 'GET_OFFER ', text: 'Get Offer ', type: 'FACEBOOK' },
      { value: 'GET_PROMOTIONS', text: 'Get Promotions', type: 'FACEBOOK' },
      { value: 'DRIVE_THERE ', text: 'Drive There ', type: 'WAZE' },
      { value: 'CALL_NOW ', text: 'Call Now ', type: 'WAZE' },
      { value: 'SAVE_LOCATION ', text: 'Save Location ', type: 'WAZE' },
      { value: 'SAVE_FOR_LATER ', text: 'Save for Later ', type: 'WAZE' },

      { value: 'APPLY_NOW ', text: 'Apply Now ', type: 'PERFORMANCE_MAX' },
      { value: 'BOOK_NOW ', text: 'Book Now ', type: 'PERFORMANCE_MAX' },
      { value: 'CONTACT_US ', text: 'Contact Us ', type: 'PERFORMANCE_MAX' },
      { value: 'DOWNLOAD ', text: 'Download ', type: 'PERFORMANCE_MAX' },
      { value: 'LEARN_MORE ', text: 'Learn More ', type: 'PERFORMANCE_MAX' },
      { value: 'SHOP_NOW ', text: 'Shop Now ', type: 'PERFORMANCE_MAX' },
      { value: 'SIGN_UP ', text: 'Sign Up ', type: 'PERFORMANCE_MAX' },
      { value: 'GET_QUOTE ', text: 'Get Quote ', type: 'PERFORMANCE_MAX' },
      { value: 'SUBSCRIBE ', text: 'Subscribe ', type: 'PERFORMANCE_MAX' },

      { value: 'APPLY_NOW ', text: 'Apply Now ', type: 'DISCOVERY' },
      { value: 'BOOK_NOW ', text: 'Book Now ', type: 'DISCOVERY' },
      { value: 'CONTACT_US ', text: 'Contact Us ', type: 'DISCOVERY' },
      { value: 'DOWNLOAD ', text: 'Download ', type: 'DISCOVERY' },
      { value: 'LEARN_MORE ', text: 'Learn More ', type: 'DISCOVERY' },
      { value: 'SHOP_NOW ', text: 'Shop Now ', type: 'DISCOVERY' },
      { value: 'SIGN_UP ', text: 'Sign Up ', type: 'DISCOVERY' },
      { value: 'GET_QUOTE ', text: 'Get Quote ', type: 'DISCOVERY' },
      { value: 'SUBSCRIBE ', text: 'Subscribe ', type: 'DISCOVERY' }
    ],
    applicableCampaignTypes: [
      'FACEBOOK',
      'WAZE',
      'PERFORMANCE_MAX'
    ]
  },
  messageText: {
    label: 'Message Text',
    type: 'STRING',
    applicableCampaignTypes: [
      'FACEBOOK'
    ],
    applicableDestinationTypes: [
      'MESSENGER'
    ]
  },
  'Enhanced CPC Enabled': {
    placeholder: 'Raise Bids even when budget constrained',
    type: 'ENUM',
    enumValues: [
      'TRUE',
      'FALSE'
    ],
    showIfAny: {
      'Bidding Strategy': [
        'MANUAL_CPC'
      ]
    }
  },
  'App Store': {
    required: true,
    default: 'GOOGLE_APP_STORE',
    placeholder: 'App Store where the app can be installed',
    type: 'ENUM',
    enumValues: [
      { value: 'GOOGLE_APP_STORE', text: 'Google App Store' },
      { value: 'APPLE_APP_STORE', text: 'Apple App Store' }
    ],
    applicableCampaignTypes: [
      'APP'
    ]
  },

  'Campaign Sub-Type': {
    label: 'Sub-Type',
    default: 'NONE',
    type: 'ENUM',
    applicableCampaignTypes: [
      'DISPLAY',
      'YOUTUBE',
      'SHOPPING',
      'APP',
      'DV360'
    ],
    dynamicConfig: {
      'Campaign Type': {
        SHOPPING: {
          hint: 'Select Smart Campaign for a Smart Shopping Campaign',
          enumValues: [
            { value: 'NONE', text: 'None' },
            { value: 'SHOPPING_SMART_ADS', text: 'Smart Campaign' }
          ]
        },
        DISPLAY: {
          hint: 'For a smart display campaign toggle this setting. This is not used otherwise.',
          enumValues: [
            { value: 'NONE', text: 'None' },
            { value: 'DISPLAY_SMART_CAMPAIGN', text: 'Smart Campaign' }
          ]
        },
        YOUTUBE: {
          enumValueLoader: '/api/campaign/getSubTypes',
          enumValueLoaderParams: {
            'Campaign Type': 'Campaign Type',
            'Campaign Goal': 'Campaign Goal'
          },
          enumReloadTriggers: {
            'Campaign Type': 'Campaign Type',
            'Campaign Goal': 'Campaign Goal'
          },
          showIfAny: {
            'Campaign Goal': [
              'REACH',
              'AWARENESS_CONSIDERATION',
              'NONE'
            ]
          }
        },
        APP: {
          default: 'APP_CAMPAIGN',
          enumValues: [
            { value: 'APP_CAMPAIGN', text: 'App Campaign' }
          ]
        },
        DV360: {
          default: 'video',
          enumValues: [
            { value: 'VIDEO', text: 'Video' }
          ]
        }
      }
    }
  },
  'Multi-Format Ads': {
    label: 'Multi-Format Ads',
    hint: 'When Multi-Format Ads is enabled, Google will attempt to find the right mix of ad formats for your campaign.',
    type: 'CHECKBOX',
    applicableCampaignTypes: [
      'YOUTUBE'
    ],
    showIfAny: {
      'Campaign Sub-Type': [
        'VIDEO_VIEWS',
        'EFFICIENT_REACH'
      ]
    }
  },
  'Multi-Format Ad Types': {
    label: 'Multi-Format Ad Types',
    default: ['IN_STREAM', 'IN_FEED', 'SHORTS'],
    placeholder: 'Select one or more values',
    type: 'MULTICOMBO',
    hidden: true,
    enumValues: [
      { value: 'IN_STREAM', text: 'In-Stream ads (bumper, skippable)' },
      { value: 'IN_FEED', text: 'In Feed ads' },
      { value: 'SHORTS', text: 'Shorts ads' }
    ],
    applicableCampaignTypes: [
      'YOUTUBE'
    ],
    showIfAny: {
      'Campaign Sub-Type': [
        'EFFICIENT_REACH'
      ]
    },
    dynamicConfig: {
      'Multi-Format Ads': {
        true: {
          hidden: false
        },
        false: {
          hidden: true
        }
      }
    }
  },
  'Supported Locales': {
    label: 'Supported Locales',
    placeholder: 'Select one or more values',
    advanced: true,
    default: 'en',
    type: 'MULTICOMBO',
    enumValueLoader: '/api/enum/language/get?textValueFormat=true',
    hiddenCampaignTypes: [
      'DV360'
    ],
    dynamicConfig: {
      'Campaign Type': {
        FACEBOOK: {
          label: 'Languages'
        },
        DSP_DISPLAY: {
          label: 'Languages',
          default: 'all_l'
        },
        DSP_VIDEO: {
          label: 'Languages',
          default: 'all_l'
        }
      }
    }
  },

  Filter: {
    label: 'Data Filter',
    multiline: true,
    type: 'TEXT',
    hint: 'Enter expressions to filter your data. Examples:\n\n[inventory]\nprice>100\n\n[inventory]\ncondition=NEW\nrating>3'
  },

  'Campaign RTB Audience Selector': {
    label: 'Audience Auto-Selection Patterns',
    placeholder: 'Wildcards and tags are supported',
    type: 'TEXT',
    applicableCampaignTypes: [
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE',
      'DDCRTB_VIDEO'
    ],
    dynamicConfig: {
      'Campaign Type': {
        DDCRTB_AUDIENCE: {
          description: 'Using an auto-selection pattern will disable use of the conditional audience editor.'
        },
        DDCRTB_VIDEO: {
          description: 'Using an auto-selection pattern will disable use of the conditional audience editor.'
        },
        DDCRTB_REMARKETING: {
          description: 'Using an auto-selection pattern will disable use of the conditional audience editor.'
        }
      }
    }
  },

  'Campaign RTB Targeting Audiences': {
    label: 'Targeting Audiences',
    default: [],
    type: 'MULTISELECT',
    enumValueLoader: '/api/user-list/getDDCRTBTargeting',
    description: 'Selecting targeting audiences will disable use of the conditional audience editor.',
    applicableCampaignTypes: [
      'DDCRTB_AUDIENCE'
    ]
  },

  // 'Campaign RTB Remarketing Audience': {
  //   label: 'Remarketing Audience',
  //   placeholder: 'Select an RTB Audience',
  //   default: '',
  //   type: 'ENUM',
  //   enumValueLoader: '/api/user-list/getDDCRTBRemarketing',
  //   description: 'Selecting a remarketing audience will disable use of the conditional audience editor.',
  //   applicableCampaignTypes: [
  //     'DDCRTB_REMARKETING'
  //   ]
  // },
  'Campaign RTB Placement Package': {
    label: 'Placement Package',
    placeholder: 'Choose One',
    required: true,
    type: 'ENUM',
    enumValueLoader: '/api/user-list/getDDCRTBVideoDealPackages',
    applicableCampaignTypes: [
      'DDCRTB_VIDEO'
    ]
  },
  'Campaign RTB Video Audiences': {
    label: 'RTB Video Audiences',
    placeholder: 'Please select a Placement Package first',
    description: 'Changing "Placement Package" will clear RTB Video Audiences. Selecting video audiences will disable use of the conditional audience editor.',
    applicableCampaignTypes: [
      'DDCRTB_VIDEO'
    ],
    enumValueLoader: '/api/user-list/getDDCRTBVideo',
    enumValueLoaderParams: {
      'Campaign RTB Placement Package': 'dealPackageId'
    },
    enumReloadTriggers: {
      'Campaign RTB Placement Package': 'dealPackageId'
    },
    type: 'MULTISELECT'
  },
  'Campaign RTB Product Set': {
    label: 'RTB Product Set',
    type: 'ENUM',
    enumValueLoader: '/api/shopping/product-scope/getEligibleForDDCRTB',
    applicableCampaignTypes: [
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE'
    ]
  },
  'Campaign RTB Domain Lists': {
    label: 'RTB Domain Targeting Lists',
    default: [],
    type: 'MULTISELECT',
    enumValueLoader: '/api/user-list/getDDCRTBDomainLists',
    applicableCampaignTypes: [
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE',
      'DDCRTB_VIDEO'
    ]
  },
  'Campaign RTB Click Tracker Type': {
    label: 'RTB Click Tracker Type',
    default: 'NONE',
    type: 'ENUM',
    enumValueLoader: '/api/campaign/getClickTrackerTypes',
    applicableCampaignTypes: [
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE'
    ]
  },
  'Campaign RTB Click Tracker Value': {
    label: 'RTB Click Tracker Value',
    type: 'TEXT',
    applicableCampaignTypes: [
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE'
    ]
  },
  'Campaign RTB Impression Pixel Type': {
    label: 'RTB Impression Pixel Type',
    default: 'NONE',
    enumValueLoader: '/api/campaign/getImpressionPixelTypes',
    type: 'ENUM',
    applicableCampaignTypes: [
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE'
    ]
  },
  'Campaign RTB Impression Pixel Value': {
    label: 'RTB Impression Pixel Value',
    type: 'TEXT',
    applicableCampaignTypes: [
      'DDCRTB_TOPIC',
      'DDCRTB_REMARKETING',
      'DDCRTB_PREMIUM',
      'DDCRTB_AUDIENCE'
    ]
  },
  'Campaign RTB Targeted Creative': {
    label: 'Targeted Creative Campaign',
    type: 'BOOLEAN',
    applicableCampaignTypes: [
      'DDCRTB_REMARKETING', // 14
      'DDCRTB_AUDIENCE', // 16
      'DDCRTB_VIDEO' // 18
    ]
  },
  'Campaign Sitelinks': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH',
      'PERFORMANCE_MAX'
    ]
  },
  'Campaign Call Extension': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY',
      'PERFORMANCE_MAX'
    ]
  },
  priceExtensions: {
    advanced: true,
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  promotionExtensions: {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  imageExtensions: {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  'Campaign Call-Outs': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  'Structured Snippets': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH'
    ]
  },
  GoogleTargeting: {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY',
      'YOUTUBE',
      'SHOPPING'
    ]
  },
  ContextualTargetSet: {
    label: 'Contextual Target Set(s)',
    type: 'MULTICOMBO',
    enumValueLoader: '/audience/getTargetSetPlanDto',
    enumValueLoaderData: { targetType: 'CONTEXTUAL_TARGET' },
    enumValueDataTransform: (response) => response.filter(ts => !ts.accountPlanId).map(r => ({ text: r.name, value: r.targetSetPlanId.toString() })),
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  AudienceTargetSet: {
    label: 'Audience Set(s)',
    type: 'MULTICOMBO',
    enumValueLoader: '/audience/getTargetSetPlanDto',
    enumValueLoaderData: { targetType: 'AUDIENCE' },
    enumValueDataTransform: (response) => response.filter(ts => !ts.accountPlanId).map(r => ({ text: r.name, value: r.targetSetPlanId.toString() })),
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  'Campaign RTB Conditional Audiences': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'DDCRTB_AUDIENCE',
      'DDCRTB_VIDEO',
      'DDCRTB_REMARKETING'
    ]
  },
  'Named Portfolio Bid Strategy': {
    label: 'Portfolio (Shared) Bidding Strategy',
    type: 'TEXT',
    placeholder: 'Use a named portfolio bidding scheme for Smart Bidding selections.',
    hint: 'Many "Campaign" Bid Strategies do not pair well with shared budgets, specifically "Smart Bidding" strategies. It is recommended that you create a named Portfolio Bid Strategy when using any of the following types:\n\nMaximize Conversions\nMaximize Conversion Value\nMaximize Clicks\nTarget ROAS\nTarget CPA\nTarget Impression Share\n\nThe system will automatically create a Portfolio Bid Strategy (name based on your blueprint) if you are using a shared budget with an incompatible type.',
    default: '',
    applicableAdTypes: [
      'SEARCH',
      'DISPLAY'
    ],
    showIfAny: {
      'Bidding Strategy': [
        'TARGET_IMPRESSION_SHARE',
        'MAXIMIZE_CLICKS',
        'MAXIMIZE_CONVERSIONS',
        'MAXIMIZE_CONVERSION_VALUE',
        'TARGET_CPA',
        'TARGET_CLICKS',
        'TARGET_ROAS'
      ]
    }
  },
  'Bid Ceiling': {
    label: 'Bid Ceiling',
    type: 'TEXT',
    placeholder: 'Enter a maximum bid amount, ex: 2.50',
    applicableAdTypes: [
      'SEARCH',
      'DISPLAY'
    ],
    showIfAny: {
      'Bidding Strategy': [
        'PAGE_ONE_PROMOTED',
        'TARGET_CPA',
        'TARGET_OUTRANK_SHARE',
        'TARGET_IMPRESSION_SHARE',
        'TARGET_ROAS',
        'MAXIMIZE_CLICKS',
        'LOWEST_COST_WITH_BID_CAP'
      ]
    },
    dynamicConfig: {
      'Bidding Strategy': {
        TARGET_IMPRESSION_SHARE: {
          required: true
        }
      }
    }
  },
  'Location Percentage': {
    label: 'Percentage of Time To Target Selected Position',
    type: 'TEXT',
    default: '90',
    placeholder: 'Enter a % value, ex: 90',
    showIfAny: {
      'Bidding Strategy': [
        'TARGET_IMPRESSION_SHARE'
      ]
    },
    applicableCampaignTypes: [
      'SEARCH',
      'DISPLAY'
    ]
  },
  'Product Filters': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'PERFORMANCE_MAX',
      'SHOPPING'
    ]
  },
  DV360: {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'DV360'
    ]
  },
  positiveDomainTargets: {
    label: 'Targeted Domains',
    type: 'MULTICOMBO',
    enumValueLoader: '/api/enum/domainLists/get',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  negativeDomainTargets: {
    label: 'Excluded Domains',
    type: 'MULTICOMBO',
    enumValueLoader: '/api/enum/domainLists/get',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspTrafficTypes: {
    label: 'Traffic Types',
    default: ['MOBILE_APP', 'MOBILE_OPTIMIZED_WEB', 'WEB', 'MOBILE_WEB'],
    placeholder: 'Select one or more values',
    type: 'MULTISWITCH',
    enumValueLoader: '/api/dsp/traffic_types',
    enumValueLoaderMethod: 'GET',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspPagePositions: {
    label: 'Page Positions',
    default: ['unknown', 'above_the_fold', 'below_the_fold'],
    placeholder: 'Select one or more values',
    type: 'MULTISWITCH',
    enumValueLoader: '/api/dsp/page_positions',
    enumValueLoaderMethod: 'GET',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspBiddingDefaultBid: {
    label: 'Default Bid',
    placeholder: 'Enter the default bid',
    type: 'STRING',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspBiddingMaxBid: {
    label: 'Max Bid',
    placeholder: 'Enter the max bid',
    type: 'STRING',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspViewabilityTracking: {
    label: 'Measure Viewability',
    hint: 'Measure whether creatives actually appear on the user\'s screen and can be viewed.',
    type: 'CHECKBOX',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspViewabilityProvider: {
    label: 'Viewability Provider',
    type: 'ENUM',
    hidden: true,
    enumValueLoader: '/api/dsp/viewability/providers',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ],
    dynamicConfig: {
      CampaignDspViewabilityTracking: {
        true: {
          hidden: false
        },
        false: {
          hidden: true
        }
      }
    }
  },
  CampaignDspViewabilityStandard: {
    label: 'Viewability Standard',
    type: 'ENUM',
    hidden: true,
    enumValueLoader: '/api/dsp/viewability/standards',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ],
    dynamicConfig: {
      CampaignDspViewabilityTracking: {
        true: {
          hidden: false
        },
        false: {
          hidden: true
        }
      }
    }
  },
  CampaignDspVideoConfigSkippability: {
    label: 'Video Skippability',
    hint: 'Sets the campaign\'s rules for skippable video ads. An ad is skippable if the user is allowed to skip it after seeing the first seconds of the video. When you set video skippability options, your campaign can bid only on ads that match your requirements.',
    type: 'MULTISWITCH',
    enumValues: [
      { value: 'UNKNOWN', text: 'Unknown' },
      { value: 'REQUIRED', text: 'Required' },
      { value: 'BLOCKED', text: 'Blocked' }
    ],
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ]
  },
  CampaignDspVideoConfigDeliveryTypes: {
    label: 'Delivery Types',
    hint: 'Specifies the video delivery type. You can include in-banner, in-stream, or both.',
    type: 'MULTISWITCH',
    enumValues: [
      { value: 'IN_STREAM', text: 'In-Stream' },
      { value: 'IN_BANNER', text: 'In-Banner' }
    ],
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ]
  },
  CampaignDspVideoConfigPlaybackModes: {
    label: 'Playback Modes',
    hint: 'Specifies the targeted video playback modes, which control when the video starts playing. If more than one video playback mode is included, the campaign targets impressions that meet any of the chosen playback modes.',
    type: 'MULTISWITCH',
    enumValues: [
      { value: 'AUTOMATIC_SOUND_ON', text: 'Automatic Sound On' },
      { value: 'AUTOMATIC_SOUND_OFF', text: 'Automatic Sound Off' },
      { value: 'ON_CLICK', text: 'On Click' },
      { value: 'ON_HOVER', text: 'On Hover' },
      { value: 'UNKNOWN', text: 'Unknown' }
    ],
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ]
  },
  CampaignDspVideoConfigStartDelay: {
    label: 'Start Delay',
    hint: 'Determines when in the video the ad is played.',
    type: 'MULTISWITCH',
    hidden: true,
    enumValues: [
      { value: 'PRE_ROLL', text: 'Pre-Roll' },
      { value: 'MID_ROLL', text: 'Mid-Roll' },
      { value: 'POST_ROLL', text: 'Post-Roll' },
      { value: 'UNKNOWN', text: 'Unknown' }
    ],
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ],
    dynamicConfig: {
      CampaignDspVideoConfigDeliveryTypes: {
        // this is a workaround.  ideally dynamic config would provide a way to check for any combination that includes in_stream.
        'IN_STREAM': {
          hidden: false
        },
        'IN_STREAM,IN_BANNER': {
          hidden: false
        },
        'IN_BANNER,IN_STREAM': {
         hidden: false
        }
      }
    }
  },
  CampaignDspVideoConfigAudioPlaybackModes: {
    label: 'Audio Playback Modes',
    hint: 'Determines when the ad is played.',
    type: 'MULTISWITCH',
    hidden: true,
    enumValues: [
      { value: 'AUTO', text: 'Auto' },
      { value: 'ON_ROLLOVER', text: 'On Rollover' },
      { value: 'USER_INITIATED', text: 'User Initiated' },
      { value: 'MUTE', text: 'Mute' }
    ],
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ],
    dynamicConfig: {
      CampaignDspVideoConfigDeliveryTypes: {
        // this is a workaround.  ideally dynamic config would provide a way to check for any combination that includes in_banner.
        'IN_BANNER': {
          hidden: false
        },
        'IN_STREAM,IN_BANNER': {
          hidden: false
        },
        'IN_BANNER,IN_STREAM': {
         hidden: false
        }
      }
    }
  },
  CampaignDspVideoConfigBlockUnknownPlayerDimensions: {
    label: 'Block Unknown Player Dimensions',
    hint: 'If set to true, bid only on video impressions that declare a player size.',
    type: 'CHECKBOX',
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ]
  },
  CampaignDspVideoConfigMinPlayerWidth: {
    label: 'Minimum Player Width',
    hint: 'The targeting options for video player dimensions.',
    type: 'TEXT',
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ]
  },
  CampaignDspVideoConfigMinPlayerHeight: {
    label: 'Minimum Player Height',
    hint: 'The targeting options for video player dimensions.',
    type: 'TEXT',
    applicableCampaignTypes: [
      'DSP_VIDEO'
    ]
  },
  CampaignDspEnableCrossDeviceTracking: {
    label: 'Enable Cross Device Tracking',
    type: 'BOOLEAN',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  'DSP Conversion Configuration': {
    type: 'EDITOR',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  'Custom Audience Targets': {
    label: 'Custom Audience Targets',
    type: 'MULTICOMBO',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  },
  CampaignDspDeviceTypes: {
    label: 'Device Types',
    default: ['CONNECTED_TV', 'PHONE', 'DESKTOP', 'TABLET', 'UNKNOWN', 'DIGITAL_OUT_OF_HOME'],
    hint: 'The targeted device types.',
    type: 'MULTISWITCH',
    enumValueLoader: '/api/dsp/devices',
    applicableCampaignTypes: [
      'DSP_DISPLAY',
      'DSP_VIDEO'
    ]
  }
}

export default CAMPAIGN

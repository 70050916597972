import { defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // const { route } = context
  const nuxtApp = useNuxtApp()
  // We implement this custom authRedirectGuard as router middleware because Okta's authRedirectGuard requires us to explicitly set requiresAuth: true on each page
  // we want all pages to be authenticated without having to go into each page and set up meta: {requiresAuth: true}
  // and we want to leave open the possibility that we might have public facing pages that don't require auth.
  // so the logic is: all matched routes must explicitly set requireAuth: false to be public facing.
  // if any matched route does not explicitly turn off auth, then authenticate

  let requiresAuth = false
  let authOptional = false
  for (const r of to.matched) {
    if (r.meta?.authOptional === true) {
      authOptional = true
    } else if (r.meta?.requiresAuth !== false) {
      requiresAuth = true
    }
  }

  if (!nuxtApp.$authn.isAuthenticated() && (requiresAuth || authOptional)) {
    const goodToGo = await nuxtApp.$authn.loginRedirect(null, null, authOptional)
    if (!goodToGo && requiresAuth) {
      nuxtApp.$authn.rememberPageAndLogout()
    }
  }
})

import validate from "/home/production/git/fluent-ui/node_modules/nuxt/dist/pages/runtime/validate.js";
import _001_45auth_45redirect_45guard_45global from "/home/production/git/fluent-ui/apps/backpack/src/middleware/001.authRedirectGuard.global.js";
import _010_45homepage_45redirect_45global from "/home/production/git/fluent-ui/apps/backpack/src/middleware/010.homepageRedirect.global.js";
import _020_45path_45last_45slash_45global from "/home/production/git/fluent-ui/apps/backpack/src/middleware/020.pathLastSlash.global.js";
import _030_45user_45page_45permissions_45global from "/home/production/git/fluent-ui/apps/backpack/src/middleware/030.userPagePermissions.global.js";
import manifest_45route_45rule from "/home/production/git/fluent-ui/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _001_45auth_45redirect_45guard_45global,
  _010_45homepage_45redirect_45global,
  _020_45path_45last_45slash_45global,
  _030_45user_45page_45permissions_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}
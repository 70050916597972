/* For use on native checkboxes */
export const Indeterminate = {
  updated (el, bindings) {
    el.indeterminate = bindings.value || false
  }
}

export const IndeterminatePlugin = {
  install (Vue, options) {
    Vue.directive('indeterminate', Indeterminate)
  }
}

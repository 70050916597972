import { shallowRef, triggerRef } from 'vue'

const activeChildItemsRef = shallowRef({})

export function useActiveChildItems () {
  return {
    activeChildItemsRef,
    getActiveChildItems: (type) => activeChildItemsRef.value[type],
    updateActiveChildItems (newActiveChildItems, type) {
      if (activeChildItemsRef.value[type] && activeChildItemsRef.value[type].clear) {
        activeChildItemsRef.value[type].clear()
      }
      activeChildItemsRef.value[type] = new Map(newActiveChildItems)
      if (newActiveChildItems.clear) {
        newActiveChildItems.clear()
        newActiveChildItems = null
      }
      triggerRef(activeChildItemsRef)
    },
    clearActiveChildItems () {
      for (const map of Object.values(activeChildItemsRef.value)) {
        if (map.clear) {
          map.clear()
        }
      }
      activeChildItemsRef.value = {}
    },
    clearActiveChildItemsTypes (types) {
      for (const type of types) {
        if (activeChildItemsRef.value[type]?.clear) {
          activeChildItemsRef.value[type].clear()
        }
        delete activeChildItemsRef.value[type]
      }
      triggerRef(activeChildItemsRef)
    },
    refreshActiveChildItemsRef () {
      triggerRef(activeChildItemsRef)
    }
  }
}

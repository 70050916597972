export const Blueprints = (flujax) => ({
  async fetchLockableFields () {
    return flujax.post('/object/getLockableFieldsByType')
  },
  async createNewDynamicTagInGoogleSheet (name, blueprintId) {
    return flujax.post(`/template/getOrAttachSheet?sheetName=${name}&contentTemplateId=${blueprintId}`)
  },
  async googleSheetRequestAccess (payload) {
    return flujax.post('/template/dataSource/requestOpen', payload)
  }
})

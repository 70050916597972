export const AuthV2 = (flujax) => ({
  async login ({ username, password }) {
    return flujax.post('/v2/auth/pub/authn', btoa(JSON.stringify({ username, password })))
  },
  async verifyFactor ({ oktaFactorId, stateToken, passCode }) {
    return flujax.post(`/v2/auth/pub/factor/${oktaFactorId}/verify`, btoa(JSON.stringify({ state_token: stateToken, pass_code: passCode })))
  },
  async enrollFactor (oktaUserId) {
    return flujax.post(`/v2/auth/priv/user/${oktaUserId}/factor/enroll`)
  },
  async activateFactor ({ passCode, oktaUserId, oktaFactorId }, opts) {
    return flujax.post(`/v2/auth/pub/user/${oktaUserId}/factor/${oktaFactorId}/activate`, btoa(JSON.stringify({ mfa_token: passCode })), opts)
  },
  async listFactors (oktaUserId) {
    return flujax.get(`/v2/auth/pub/user/${oktaUserId}/factors`)
  },
  async deleteFactor ({ oktaFactorId, oktaUserId }) {
    return flujax.delete(`/v2/auth/priv/user/${oktaUserId}/factor/${oktaFactorId}`)
  },
  async tokenExchange ({ code, redir }) {
    return flujax.post('/v2/auth/pub/token', btoa(JSON.stringify({ code, redir })))
  },
  async changePassword ({ oldPassword, newPassword, stateToken }) {
    return flujax.post('/v2/auth/pub/user/change_password', btoa(JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
      state_token: stateToken
    })))
  },
  async skip (stateToken) {
    return flujax.post('/v2/auth/pub/user/skip', btoa(JSON.stringify({ state_token: stateToken })))
  },
  async refresh (refreshToken) {
    return flujax.post('/v2/auth/pub/user/refresh', btoa(JSON.stringify({ refresh_token: refreshToken })))
  },
  async addIdp (data) {
    return flujax.post('/v2/auth/priv/idp/add', btoa(data))
  },
  async addCertificate (certificate) {
    return flujax.post('/v2/auth/priv/x509/add', btoa(certificate))
  },
  async getIdp (idpid) {
    return flujax.post(`/v2/auth/pub/idp/${idpid}`)
  },
  async getIdpIdFromDomain (domain) {
    return flujax.post('/v2/auth/pub/auth/domain', domain)
  }
})

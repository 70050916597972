
export const state = () => ({
  accountPlanId: null,
  mediaListData: [],
  loadingMedia: false,
  selectedMediaLists: []
})

export const mutations = {
  setAccountPlanId (state, accountPlanId) {
    if (state.accountPlanId !== accountPlanId) {
      state.accountPlanId = accountPlanId
    }
  },
  setMediaListData (state, mediaListData) {
    state.mediaListData = mediaListData
  },
  addMediaListData (state, defaults = {}) {
    let listData = [...state.mediaListData]
    listData.push({
      listName: '',
      content: '[]',
      source: 'media',
      ...defaults,
      accountPlanId: state.accountPlanId,
      listDataId: Math.floor(Math.random() * -1000),
      dirty: true,
      status: 'PENDING'
    })
    state.mediaListData = listData
  },
  updateMediaListData (state, { listItem, payload }) {
    const listData = [...state.mediaListData]
    let index = listData.indexOf(listItem)
    if (index < 0) {
      index = listData.findIndex(ld => ld.listDataId === listItem.listDataId) //fallback
    }
    if (index >= 0) {
      listData[index] = {
        ...listData[index],
        dirty: true,
        ...payload
      }
      state.mediaListData = listData
    }
  },
  setLoadingMedia (state, isLoading) {
    state.loadingMedia = isLoading
  }
}

export const actions = {
  async loadMediaForAccountPlan ({ commit, state }, { accountPlanId, forceFetch = false }) {
    if (accountPlanId && (state.accountPlanId !== accountPlanId || forceFetch)) {
      commit('setLoadingMedia', true)
      commit('setAccountPlanId', accountPlanId)

      const listData = await this.$res.fetch.listData({ accountPlanId })
      if (listData?.length > 0) {
        const media = listData.filter(l => l.source === 'media' && l.status !== 'REMOVED')
        commit('setMediaListData', media)
      }
      commit('setLoadingMedia', false)
    }
    return state.mediaListData
  },
  async updateSaveMediaListData ({ commit, state }, { listItem, payload }) {
    commit('updateMediaListData', { listItem, payload })

    let toSave = state.mediaListData
    if (!listItem.listDataId) {
      toSave.push({ ...listItem, ...payload })
    }
    toSave = toSave.filter(ld => ld.dirty)
      .map(ld => ({
        ...ld,
        content: tryStringifyJsonContent(ld.content)
      }))
    toSave.forEach(ld => {
      delete ld.dirty
      if (ld.listDataId <= 0) {
        delete ld.listDataId
      }
    })
    if (toSave.length > 0) {
      const responses = await Promise.all(toSave.map(p => this.$res.set.list(p)))

      if (responses) {
        // get a fresh copy of any list data that wasn't just saved
        const listData = state.mediaListData.filter(list => list.listDataId && !list.dirty)

        responses.forEach(response => {
          if (response) {
            const index = listData.findIndex(l => l.listDataId === response.listDataId) // find the response in this list

            if (index >= 0) {
              listData[index] = response
            } else {
              listData.push(response)
            }
          }
        })
        commit('setMediaListData', listData)
      }
    }
  },
  async deleteSaveMediaListData ({ commit, state, dispatch }, listItem) {
    if (listItem.listDataId < 0) {
      let index = state.mediaListData.indexOf(listItem)
      let listData = [...state.mediaListData]
      listData.splice(index, 1)
      commit('setMediaListData', listData)
    } else {
      await dispatch('updateSaveMediaListData', { listItem, payload: { status: 'REMOVED' } })
    }
  }
}

const tryStringifyJsonContent = function tryStringifyJsonContent (content) {
  let result = content
  if (typeof content === 'string' && ((content.indexOf('{') !== -1 && content.indexOf('}') !== -1) || (content.indexOf('[') !== -1 && content.indexOf(']') !== -1))) {
    try {
      result = JSON.stringify(JSON.parse(content))
    } catch (e) {}
  }
  return result
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


// Array extensions
Object.defineProperty(Array.prototype, 'distinct', {
  value: function (keySelectorFn, valueSelectorFn) {
    const isKeyFunc = typeof keySelectorFn === 'function'
    const isValueFunc = typeof valueSelectorFn === 'function'
    const distinct = this.reduce(function (map, item) {
      const key = isKeyFunc
        ? keySelectorFn(item)
        : item
      if (!map.has(key)) {
        const value = isValueFunc
          ? valueSelectorFn(item)
          : item
        map.set(key, value)
      }
      return map
    }, new Map())
    return Array.from(distinct.values())
  },
  enumerable: false,
  writable: false
})

Object.defineProperty(Array.prototype, 'flatMap', {
  value: function (flattenFn) {
    const results = []
    const isFunc = typeof flattenFn === 'function'
    this
      .map(item => isFunc ? flattenFn(item) : item)
      .forEach(subArray => {
        results.push.apply(results, subArray)
      })
    return results
  },
  enumerable: false,
  writable: false
})

Object.defineProperty(Array.prototype, 'groupBy', {
  value: function (keySelectorFn) {
    const isFunc = keySelectorFn instanceof Function
    return this.reduce(function (map, item) {
      const key = isFunc ? keySelectorFn(item) : item[keySelectorFn];
      (map[key] = map[key] || []).push(item)
      return map
    }, {})
  },
  enumerable: false,
  writable: false
})

// TODO toMap and toLookup are the same...i want to deprecate toMap and use toLookup
Object.defineProperty(Array.prototype, 'toMap', {
  value: function (keySelectorFn, valueSelectorFn) {
    const isKeyFunc = typeof keySelectorFn === 'function'
    const isValueFunc = typeof valueSelectorFn === 'function'
    return this.reduce((map, item, index) => {
      const key = isKeyFunc
        ? keySelectorFn(item)
        : index

      const value = isValueFunc
        ? valueSelectorFn(item)
        : item

      if (!map[key]) {
        map[key] = value
      }
      return map
    }, {})
  },
  enumerable: false,
  writable: false
})
Object.defineProperty(Array.prototype, 'toLookup', {
  value: function (keySelectorFn, valueSelectorFn) {
    const isKeyFunc = typeof keySelectorFn === 'function'
    const isValueFunc = typeof valueSelectorFn === 'function'
    return this.reduce((map, item, index) => {
      const key = isKeyFunc
        ? keySelectorFn(item)
        : index

      const value = isValueFunc
        ? valueSelectorFn(item)
        : item

      if (!map[key]) {
        map[key] = value
      }
      return map
    }, {})
  },
  enumerable: false,
  writable: false
})

Object.defineProperty(Array.prototype, 'sum', {
  value: function (valueSelectorFn) {
    const isFunc = valueSelectorFn instanceof Function
    return this.reduce(function (sumValue, item) {
      let value = isFunc ? valueSelectorFn(item) : item || 0
      if (isNaN(value)) {
        value = 0
      }
      return sumValue + value
    }, 0)
  },
  enumerable: false,
  writable: false
})

if (!Array.prototype.move) {
  Object.defineProperty(Array.prototype, 'move', {
    value: function (from, to) {
      if (from !== to) {
        this.splice(to, 0, this.splice(from, 1)[0])
      }
      return this
    },
    enumerable: false,
    writable: false
  })
}

Object.defineProperty(Array.prototype, 'chainedSort', {
  value: function (compareFn) {
    const valueCopy = [...this]
    valueCopy.sort(compareFn)
    return valueCopy
  },
  enumerable: false,
  writable: false
})

Object.defineProperty(Array.prototype, 'remove', {
  value: function (...items) {
    const removed = []
    for (const item of items) {
      const i = this.indexOf(item)
      if (i >= 0) {
        removed.push(...this.splice(i, 1))
      }
    }
    return removed
  },
  enumerable: false,
  writable: false
})

Object.defineProperty(Array, 'zip', {
  value: function (left, right, combinerFunction) {
    const results = []
    for (let counter = 0; counter < Math.min(left.length, right.length); counter++) {
      results.push(combinerFunction(left[counter], right[counter]))
    }
    return results
  }
})

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function (valueToFind, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }
      const o = Object(this)
      const len = o.length >>> 0
      if (len === 0) {
        return false
      }
      const n = fromIndex | 0
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

      function sameValueZero (x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
      }
      while (k < len) {
        if (sameValueZero(o[k], valueToFind)) {
          return true
        }
        k++
      }
      return false
    }
  })
}

Object.defineProperty(Element.prototype, 'css', {
  value: function (style) {
    if (style) {
      for (const property in style) {
        this.style[property] = style[property]
      }
    }
  }
})

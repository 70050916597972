const blankBulkAction = () => ({
  objectType: undefined
})

const blankFilterSet = (entityType) => ({
  entityType,
  filterStrategy: 'ALL_MATCH',
  filters: [blankFilter()]
})

const blankFilter = () => ({
  fieldName: undefined,
  operator: undefined,
  values: []
})

const initialBulkActions = () => ([blankBulkAction()])

const initialState = () => ({
  bulkActions: initialBulkActions(),
  returnPath: getLocalStore('returnPath'),
  totalPlans: {
    ACCOUNT: 0,
    BUDGET: 0,
    CAMPAIGN: 0,
    AD_GROUP: 0,
    CREATIVE: 0,
    KEYWORD: 0
  },
  selectedType: getLocalStore('selectedType') || 'ACCOUNT',
  filterSets: [],
  approvedBulkActions: undefined,
  approvedBulkFilters: undefined,
  filtersAllApplied: true,
  planIds: getLocalStore('planIds') || {},
  plans: {},
  loading: {
    counts: false,
    previewObjects: false,
    preview: false,
    apply: false
  },
  preview: {},
  includeReadOnly: false
})

const assembleBulkActionPayload = (getters) => {
  return {
    actions: getters.bulkActions.filter(a => a.objectType && a.key).map(bulkAction => {
      const { objectType, action, key, supplementalFieldData } = bulkAction
      const a = { objectType, action, key }
      if (supplementalFieldData && supplementalFieldData.length > 0) {
        a.supplementalFieldData = supplementalFieldData.map(data => ({ value: data.value, fieldName: data.fieldName, configuration: data.configuration }))
      }
      return a
    }),
    filter: { ...getters.planIds, filterSets: getters.filterSets, includeReadOnly: getters.includeReadOnly }
  }
}

const segmentParam = (rootGetters, otherParams) => {
  let params = otherParams || ''
  const segmentId = rootGetters.activeSegment?.savedSegmentId
  if (segmentId) {
    params = otherParams ? `${otherParams}&segmentId=${segmentId}` : `?segmentId=${segmentId}`
  }
  return params
}

const setLocalStore = (setting) => {
  if (localStorage) {
    let settings
    try {
      settings = JSON.parse(localStorage.getItem('backpack.bulkEdit') || '{}')
      settings = { ...settings, ...setting }
      localStorage.setItem('backpack.bulkEdit', JSON.stringify(settings))
    } catch (e) {
      console.log(e)
    }
  }
}

const getLocalStore = (setting) => {
  if (localStorage) {
    let settings
    try {
      settings = JSON.parse(localStorage.getItem('backpack.bulkEdit') || '{}')
      return settings[setting]
    } catch (e) {
      console.log(e)
    }
  }
}

export const state = initialState

export const getters = {
  approvedBulkActions (state) {
    return state.approvedBulkActions
  },
  approvedBulkFilters (state) {
    return state.approvedBulkFilters
  },
  bulkActions (state) {
    return state.bulkActions
  },
  returnPath (state) {
    return state.returnPath
  },
  totalPlans (state) {
    return state.totalPlans
  },
  selectedType (state) {
    return state.selectedType
  },
  filterSet: (state) => (type) => {
    return state.filterSets.find(f => f.entityType === type) || {}
  },
  filterSets (state) {
    return state.filterSets
  },
  filtersAllApplied (state) {
    return state.filtersAllApplied
  },
  planIds (state) {
    return state.planIds
  },
  plans (state) {
    return state.plans
  },
  preview (state) {
    return state.preview
  },
  loading: (state) => (type) => {
    return state.loading[type] || false
  },
  includeReadOnly (state) {
    return state.includeReadOnly
  }
}
export const mutations = {
  approvedBulkActions (state, value) {
    state.approvedBulkActions = value
  },
  approvedBulkFilters (state, value) {
    state.approvedBulkFilters = value
  },
  addBlankBulkAction (state) {
    state.bulkActions.push(blankBulkAction())
  },
  updateBulkAction (state, { index, action }) {
    const bulkActions = [...state.bulkActions]
    bulkActions[index] = action
    state.bulkActions = bulkActions
  },
  updateSupplementalFieldData (state, { index, dataIndex, data }) {
    const supplementalFieldData = [...state.bulkActions[index].supplementalFieldData]
    supplementalFieldData[dataIndex] = data
    state.bulkActions[index].supplementalFieldData = supplementalFieldData
  },
  deleteBulkAction (state, index) {
    const bulkActions = [...state.bulkActions]
    bulkActions.splice(index, 1)
    state.bulkActions = bulkActions
  },
  returnPath (state, path) {
    state.returnPath = path
    setLocalStore({ returnPath: path })
  },
  selectedType (state, type) {
    state.selectedType = type
    setLocalStore({ selectedType: type })
  },
  resetState (state) {
    localStorage && localStorage.removeItem('backpack.bulkEdit')
    Object.assign(state, initialState())
  },
  filterSetStrategy (state, { filterSet, strategy }) {
    const filterSets = [...state.filterSets]
    const i = filterSets.indexOf(filterSet)
    filterSets[i].filterStrategy = strategy
    state.filterSets = filterSets
  },
  addNewFilter (state, filterSet) {
    const filterSets = [...state.filterSets]
    const i = filterSets.indexOf(filterSet)
    filterSets[i].filters.push(blankFilter())
    state.filterSets = filterSets
  },
  addNewFilterSet (state, type) {
    const filterSets = [...state.filterSets]
    filterSets.push(blankFilterSet(type))
    state.filterSets = filterSets
  },
  addCompleteFilterSet(state, filterSet) {
    const filterSets = [...state.filterSets]
    filterSets.push(filterSet)
    state.filterSets = filterSets
  },
  updateFilter (state, { filterSet, filter, changes }) {
    const filterSets = [...state.filterSets]
    const i = filterSets.indexOf(filterSet)
    const filters = [...filterSets[i].filters]
    const ii = filters.indexOf(filter)
    filters[ii] = { ...filter, ...changes }
    filterSets[i].filters = filters
    state.filterSets = filterSets
    state.filtersAllApplied = false
  },
  deleteFilter (state, { filterSet, filter }) {
    const filterSets = [...state.filterSets]
    const i = filterSets.indexOf(filterSet)
    const filters = [...filterSets[i].filters]
    const ii = filters.indexOf(filter)
    filters.splice(ii, 1)
    if (filters.length === 0) {
      filterSets.splice(i, 1)
    } else {
      filterSet.filters = filters
    }
    state.filterSets = filterSets
    state.filtersAllApplied = false
  },
  filterSets (state, filterSets) {
    state.filterSets = filterSets
  },
  totalPlans (state, totalPlans) {
    state.totalPlans = totalPlans
  },
  filtersAllApplied (state, value) {
    state.filtersAllApplied = value
  },
  planIds (state, value) {
    state.planIds = value
    setLocalStore({ planIds: value })
  },
  plans (state, value) {
    state.plans = value
  },
  preview (state, value) {
    state.preview = value
  },
  loading (state, { type, value }) {
    const loading = { ...state.loading }
    loading[type] = value
    state.loading = loading
  },
  includeReadOnly (state, value) {
    state.includeReadOnly = value
  }
}

export const actions = {
  async applyFilters ({ getters, commit, dispatch }) {
    commit('loading', { type: 'counts', value: true })
    commit('loading', { type: 'previewObjects', value: true })

    let success = true
    const counts = await dispatch('fetchCounts')
    if (!counts) success = false

    if (getters.totalPlans[getters.selectedType] > 0) {
      const objs = await dispatch('fetchPreviewObjects', true)
      if (!objs) success = false
    } else {
      commit('plans', { ...getters.plans, [getters.selectedType]: [] })
      commit('loading', { type: 'previewObjects', value: false })
    }

    if (success) {
      commit('filtersAllApplied', true)
    }
  },
  async fetchPreviewObjects ({ getters, commit, rootGetters }, reset = false) {
    commit('loading', { type: 'previewObjects', value: true })
    const payload = { ...getters.planIds, filterSets: getters.filterSets, includeReadOnly: getters.includeReadOnly }
    const params = segmentParam(rootGetters, `?forType=${getters.selectedType}`)
    const plans = await this.$res.fetch.bulkPreviewObjects({ payload, params })
    commit('loading', { type: 'previewObjects', value: false })
    if (plans) {
      commit('plans', reset ? { [getters.selectedType]: plans } : { ...getters.plans, [getters.selectedType]: plans })
      return plans
    }
    return false
  },
  async fetchCounts ({ getters, commit, rootGetters }) {
    commit('loading', { type: 'counts', value: true })
    const payload = { ...getters.planIds, filterSets: getters.filterSets, includeReadOnly: getters.includeReadOnly }
    const params = segmentParam(rootGetters)
    const counts = await this.$res.fetch.bulkCounts({ payload, params })
    commit('loading', { type: 'counts', value: false })
    if (counts) {
      commit('totalPlans', counts)
      return counts
    }
    return false
  },
  async preview ({ getters, commit, rootGetters }) {
    commit('loading', { type: 'preview', value: true })
    const payload = assembleBulkActionPayload(getters)
    const params = segmentParam(rootGetters)
    const preview = await this.$res.fetch.bulkPreview({ payload, params })
    commit('loading', { type: 'preview', value: false })
    if (preview) {
      commit('preview', preview)
    }
  },
  async apply ({ getters, commit, rootGetters }, scheduledFor) {
    commit('loading', { type: 'apply', value: true })
    const payload = assembleBulkActionPayload(getters)
    const params = segmentParam(rootGetters)
    let apply
    if (scheduledFor) {
      payload.scheduledFor = scheduledFor
      apply = await this.$res.fetch.bulkSchedule({ payload, params })
    } else {
      apply = await this.$res.fetch.bulkApply({ payload, params })
    }
    commit('loading', { type: 'apply', value: false })
    if (apply) {
      return true
    } else {
      return false
    }
  },
  validate ({ getters }) {
    let hasActions = false
    for (const action of getters.bulkActions) {
      if (action.key && action.objectType) {
        hasActions = true
        break
      }
    }
    if (!hasActions) {
      return {
        valid: false,
        message: 'Please select at least one bulk action before proceeding.'
      }
    }

    /*
     * THIS VALIDATION CODE IS VERY SCOPED TO ONLY "Lockable Fields" RIGHT NOW, BUT COULD BE OPENED UP TO CHECK
     * ALL `supplementalFieldData` FIELDS QUITE EASILY.
     */
    let missingSupplementalData = false
    for (const action of getters.bulkActions) {
      if (action.key && ['LOCK_FIELDS', 'UNLOCK_FIELDS'].includes(action.key) && action.objectType && action.supplementalFieldData) {
        for (const supplement of action.supplementalFieldData) {
          if (['LOCKABLE'].includes(supplement?.fieldName) && !supplement.value) {
            missingSupplementalData = true
          }
          if (missingSupplementalData) {
            break
          }
        }
        if (missingSupplementalData) {
          break
        }
      }
    }
    if (missingSupplementalData) {
      return {
        valid: false,
        message: 'Please provide values for all fields pertaining to field locking.' // TODO: update this message if validation scope expanded
      }
    }

    let hasFilters = false
    for (const plans of Object.values(getters.planIds || {})) {
      if (plans?.length > 0) {
        hasFilters = true
        break
      }
    }
    if (!hasFilters) {
      for (const filterSet of (getters.filterSets || [])) {
        if (filterSet.filters?.some(filter => filter.fieldName && filter.operator)) {
          hasFilters = true
          break
        }
      }
    }
    if (!hasFilters) {
      return {
        valid: false,
        message: 'Performing bulk actions across your entire portfolio is not permitted due to high risk. You must have at least one filter applied.'
      }
    }
    return {
      valid: true
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

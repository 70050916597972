export const LocalServiceAds = (flujax) => ({
  async getCategories (campaignPlanId, accountPlanId) {
    return flujax.post('/localserviceads/getCategories', { campaignPlanId, accountPlanId })
  },
  async getAvailableServiceIds (campaignPlanId, accountPlanId) {
    return flujax.post('/localserviceads/getAvailableServiceIds', { campaignPlanId, accountPlanId })
  },
  async getEnabledServiceIds (campaignPlanId, accountPlanId) {
    return flujax.post('/localserviceads/getEnabledServiceIds', { campaignPlanId, accountPlanId })
  },
  async setServiceIds (campaignPlanId, services) {
    return flujax.post(`/localserviceads/setServiceIds?campaignPlanId=${campaignPlanId}`, services)
  }
})
/* This is a core vue plugin that each app can install
*
* either use all plugins
* import FluencyVuePlugin from 'core-ui/plugins/FluencyVue'
* Vue.use(FluencyVuePlugin)

* or import the ones you need
* import FluencyComponents from 'core-ui/plugins/FluencyComponents'
* Vue.use(FluencyComponents)
*
* or import individual ones
* import { FluencyIconPlugin } from 'core-ui/plugins/FluencyComponents'
* Vue.use(FluencyIconPlugin)
* */

import CoreUiComponents from './CoreUiComponents'
import { CoreUiFiltersPlugin } from './CoreUiFilters'
import CoreUiDirectives from './CoreUiDirectives'
import AlertConfirmPrompt from './alertConfirmPrompt'
import './extensions'
import { configureCompat } from 'vue'
import { vue3WithCompat } from 'core-ui/assets/js/vueCompatFlags'

configureCompat(vue3WithCompat())

export default {
  install (Vue, options) {
    CoreUiComponents.install(Vue, options)
    CoreUiFiltersPlugin.install(Vue, options)
    CoreUiDirectives.install(Vue, options)
    AlertConfirmPrompt.install(Vue, options)
  }
}

import { definePreset, palette, updatePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import PaginatorTheme from 'core-ui/primeVueBridge/theme/paginator.js'
import MessageTheme from 'core-ui/primeVueBridge/theme/message.js'
import ToastTheme, { pt as ToastPassThrough } from 'core-ui/primeVueBridge/theme/toast.js'

const FluencyTheme = definePreset(Aura, {
  semantic: {
    primary: palette('#6B9469'),
    success: palette('#6B9469'),
    warning: palette('#FFC107'),
    danger: palette('#E84A5F'),
    info: palette('#74ADD2'),
    colorScheme: {
      light: {
        green: palette('#6B9469'),
        sky: palette('#74ADD2'),
        orange: palette('#FFC107'),
        red: palette('#E84A5F'),
        gray: palette('#6c757d'),
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.600}',
          activeColor: '{primary.700}',
          lighten: '{primary.100}',
          darken: '{primary.700}'
        },
        success: {
          color: '{success.500}',
          contrastColor: '#ffffff',
          hoverColor: '{success.600}',
          activeColor: '{success.700}',
          lighten: '{success.100}',
          darken: '{success.700}'
        },
        info: {
          color: '{info.500}',
          contrastColor: '#ffffff',
          hoverColor: '{info.600}',
          activeColor: '{info.700}',
          lighten: '{info.100}',
          darken: '{info.800}'
        },
        warning: {
          color: '{warning.500}',
          contrastColor: '#ffffff',
          hoverColor: '{warning.600}',
          activeColor: '{warning.700}',
          lighten: '{warning.100}',
          darken: '{warning.800}'
        },
        danger: {
          color: '{danger.500}',
          contrastColor: '#ffffff',
          hoverColor: '{danger.600}',
          activeColor: '{danger.700}',
          lighten: '{danger.100}',
          darken: '{danger.800}'
        }
      },
      dark: {
        surface: palette('#B5BFCF')
      }
    }
  },
  components: {
    paginator: PaginatorTheme,
    message: MessageTheme,
    toast: ToastTheme
  }
})

const FluencyPassThrough = {
  toast: ToastPassThrough
}

export { FluencyTheme, FluencyPassThrough }

// when these themes are updated, use the updatePreset call in HMR so that we don't get a full page reload
if (import.meta.hot) {
  import.meta.hot.accept((newTheme) => {
    updatePreset(newTheme.default)
  })
}

export const Account = (flujax) => ({
  async getVideos (accountPlanId) {
    return flujax.post(`/video/get?accountPlanId=${accountPlanId}`)
  },
  async generateVideo (accountPlanId) {
    // FUTURE FUNCTIONALITY: ?regenerate=true
    return flujax.post(`/video/generateMonthlyPerformance?accountPlanId=${accountPlanId}`)
  },
  async watch (action, accountPlanIds) {
    return flujax.post(`account/${action}WatchAccountPlans`, accountPlanIds)
  },
  async getOrAttachSheet (accountPlanId, sheetName) {
    return flujax.post(`/account/getOrAttachSheet?accountPlanId=${accountPlanId}&sheetName=${sheetName}`)
  },
  async googleSheetRequestAccess (payload) {
    return flujax.post('/account/dataSource/requestOpen', payload)
  },
  async queueCrawler (accountPlanId) {
    return flujax.post(`/account/queueCrawler?accountPlanId=${accountPlanId}`)
  },
  async determineGeoEligibility (payload) {
    return flujax.post('/geo/determineEligibility', payload)
  },
  async geoAssetCreateAssetFromCampaignGeo (accountPlanId, makeDefault, payload) {
    // TURN A CAMPAIGN LEVEL GEO INTO AN ACCOUNT LEVEL GEO, SAVED WITH A NAME
    return flujax.post(`/geo/asset/createAssetFromCampaignGeo?accountPlanId=${accountPlanId}&makeDefault=${makeDefault}`, payload)
  },
  async geoAssetList (accountPlanId) {
    // LIST OUT GEO ASSETS AT THE ACCOUNT LEVEL
    return flujax.post(`/geo/asset/list?accountPlanId=${accountPlanId}`)
  },
  async geoAssetGet (geoAsset, accountPlanId) {
    return flujax.post(`/geo/asset/get?accountPlanId=${accountPlanId}`, geoAsset)
  },
  async geoAssetLink (geoAsset, campaignPlanId) {
    return flujax.post(`/geo/asset/link?campaignPlanId=${campaignPlanId}`, geoAsset)
  },
  async geoAssetApply (payload) {
    // LINK A CAMPAIGN TO AN EXISTING NAMED SET OF GEOS
    return flujax.post('/geo/asset/apply', payload)
  },
  async geoAssetApplyToGeoAsset (payload) {
    // TO UPDATE AN EXISTING GEO ASSET
    return flujax.post('/geo/asset/applyToGeoAsset', payload)
  },
  async geoAssetRemove (geoTargetId) {
    return flujax.post(`/geo/asset/remove?geoTargetId=${geoTargetId}`)
  },
  async getAsNewGeoTargetDTO (payload) {
    return flujax.post('/geo/asset/getAsNewGeoTargetDTO', payload)
  },
  async disconnectInfo (accountPlan, partnerId) {
    return flujax.post(`/account/disconnectSubAccountInfo?partnerId=${partnerId}`, accountPlan)
  },
  async disconnect (accountDisconnectRequest) {
    return flujax.post('/account/disconnectSubAccount', accountDisconnectRequest)
  },
  async getRemoved () {
    return flujax.get('/account/removed/get')
  },
  async cancel (accountPlans, params) {
    const { pauseEnabledCampaigns } = params
    return flujax.post(`/account/cancel?pauseEnabledCampaigns=${pauseEnabledCampaigns}`, accountPlans)
  },
  async restoreAccounts (accountPlans) {
    return flujax.fetch.post('/account/restore', accountPlans)
  },
  async archiveAccounts (accountPlans, params) {
    const { referenceIds } = params
    let urlParams = ''
    if (referenceIds) {
      urlParams = `delimitedMccIdsToDisconnect=${referenceIds}`
    }

    // TODO: actually make the call if this all looks correct
    console.log(`/account/archive?${urlParams}`)
    console.log(accountPlans)
    // return flujax.fetch.post(`/account/archive?${urlParams}`, accountPlans)
  },
  async getMccInfo (accountPlans) {
    return flujax.fetch.post('/account/mcc/info', accountPlans)
  }
})

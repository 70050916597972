import Bugsnag from '@bugsnag/js'
import Hierarchy from 'assets/js/hierarchy'
import { useAccountsById } from '@/composables/useAccountsById'
import { useActiveChildItems } from '../composables/useActiveChildItems'
import { useActiveBudgets } from '../composables/useActiveBudgets'
import { useCampaignNavigation } from '../composables/useCampaignNavigation'
import { useAdGroupNavigation } from '../composables/useAdGroupNavigation'

export default {
  updateOkta: (state, obj) => {
    state.okta = { ...state.okta, ...obj }
  },
  customerState: (state, obj) => {
    state.app.customerState = obj
    localStorage && localStorage.setItem('customerState', JSON.stringify(state.app.customerState))
  },
  customer: (state, obj) => {
    state.app.customer = obj
    localStorage && localStorage.setItem('customer', JSON.stringify(state.app.customer))
  },
  setting: (state, { key, value }) => {
    state.app.settings[key] = value
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  settings: (state, settingsObj) => {
    state.app.settings = {
      ...state.app.settings,
      ...settingsObj
    }
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  splitpanes: (state, { key, value }) => {
    state.app.settings.splitpanes[key] = value
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  timeRange: (state, { shortcut, custom }) => {
    state.app.settings.timeRangeShortcut = shortcut
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))

    state.app.customTimeRange = custom
  },
  showAthenaWarningForReporting: (state, bool) => {
    state.app.settings.showAthenaWarningForReporting = bool
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  setBackpackVersion: (state, version) => {
    state.app.settings.backpackVersion = version
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  setLocalDev: (state, isLocalDev) => {
    state.data.isLocalDev = isLocalDev
  },
  // manage / plan
  activeItem: (state, item) => {
    const oldItem = state.data.activeItem

    if (item) {
      const childTypes = Hierarchy.getChildTypes(item.planType) || []
      const oldItemChildTypes = oldItem ? Hierarchy.getChildTypes(oldItem?.planType) : []

      const isChildPlanType = oldItemChildTypes.includes(Hierarchy.getTableTypeFromManageType(item?.planType)) || Hierarchy.getTableTypeFromManageType(oldItem?.planType) === Hierarchy.getTableTypeFromManageType(item?.planType)
      const isNewHierarchy = oldItem?.[`${item.planType}PlanId`] !== item[`${item.planType}PlanId`]
      const isLeafNode = Hierarchy.isLeafNode(item?.planType)

      if (!oldItem || !isChildPlanType || (isNewHierarchy && !isLeafNode)) {
        useActiveChildItems().clearActiveChildItemsTypes(childTypes)
        // if (item.planType !== 'budget' && !state.data.activeChildItems[Hierarchy.getTableTypeFromManageType(item.planType)]?.has(item[`${item.planType}PlanId`])) {
        //   delete state.data.activeChildItems[Hierarchy.getTableTypeFromManageType(item.planType)] // clear self table (siblings)
        // }
      }
      if (oldItem && oldItem.accountPlanId && item.accountPlanId && oldItem.accountPlanId !== item.accountPlanId) {
        state.data.active.accountNotifications = null
      }
    } else {
      useActiveChildItems().clearActiveChildItems()
    }
    state.data.activeItem = { ...item }
  },
  settingsItem: (state, item) => {
    state.data.settingsItem = item
  },
  settingsItemInTable (state, item) {
    const type = Hierarchy.getTableTypeFromManageType(item.planType)
    const existingMap = useActiveChildItems().getActiveChildItems(type)
    if (item) {
      const tableItems = existingMap ? new Map(existingMap) : new Map()
      const id = item[`${item.planType}PlanId`]
      const tableItem = {
        ...(tableItems.get(id) || {}),
        ...item
      }
      tableItems.set(id, tableItem)
      useActiveChildItems().updateActiveChildItems(tableItems, type)
    }
    if (type === 'campaign') {
      useActiveChildItems().clearActiveChildItemsTypes(['budget'])
    }
  },
  activeChildItems: (state, { type, items }) => {
    useActiveChildItems().updateActiveChildItems(items, type)
  },
  resetActiveChildItems (state) {
    useActiveChildItems().clearActiveChildItems()
  },
  skeletons (state, { skeletons }) {
    skeletons.forEach(skeleton => {
      state.data.skeletons[skeleton.type] = skeleton.skeleton
    })
    localStorage && localStorage.setItem('skeletons', JSON.stringify(state.data.skeletons))
  },
  skeleton: (state, { type, skeleton }) => {
    state.data.skeletons[type] = skeleton
    localStorage && localStorage.setItem('skeletons', JSON.stringify(state.data.skeletons))
  },
  seConfig: (state, config) => {
    config = config || {}
    state.app.config.strategyEditorFields = config
  },
  seRecipes: (state, recipes) => {
    state.data.strategyEditor.recipes = recipes
  },
  templates: (state, templates) => {
    state.data.templates = templates
  },
  user: (state, user) => {
    state.user = user
    const email = user?.email ?? 'No User Set'
    Bugsnag.setUser('', email, '')
    delete state.user.isFluencyUser
    localStorage && localStorage.setItem('backpack.user', JSON.stringify(state.user))
  },
  userDefaultHomePage: (state, defaultHomePage) => {
    state.user.defaultHomePage = defaultHomePage
    localStorage && localStorage.setItem('backpack.user', JSON.stringify(state.user))
  },
  userMode: (state, mode) => {
    const user = { ...state.user }
    user.userMode = mode
    state.user = user
    localStorage && localStorage.setItem('backpack.user', JSON.stringify(state.user))
  },
  userDefaultManageTableType: (state, defaultManageTableType) => {
    state.user = {
      ...state.user,
      defaultManageTableType: defaultManageTableType
    }
    localStorage && localStorage.setItem('backpack.user', JSON.stringify(state.user))
  },
  enumeration: (state, { type, enumeration }) => {
    let enums = { ...state.data.enumerations }
    enums[type] = enumeration
    state.data.enumerations = enums
    localStorage && localStorage.setItem('enumerations', JSON.stringify(state.data.enumerations))
  },
  advertisingChannels: (state, advertisingChannels) => {
    state.data.advertisingChannels = advertisingChannels
    localStorage && localStorage.setItem('advertisingChannels', JSON.stringify(state.data.advertisingChannels))
  },
  partners: (state, partners) => {
    state.data.partners = partners
    localStorage && localStorage.setItem('partners', JSON.stringify(state.data.partners))
  },
  verticals: (state, verticals) => {
    state.data.verticals = verticals
    localStorage && localStorage.setItem('verticals', JSON.stringify(state.data.verticals))
  },
  init: (state, init) => {
    state.app.init = init
  },
  toast: (state, toast) => {
    const toasts = [...state.data.toast]
    const same = toasts.findIndex(t => {
      const prevMessage = t.msg.message || t.msg
      const newMessage = toast.msg.message || toast.msg
      return prevMessage === newMessage && t.type === toast.type
    })
    if (same >= 0) {
      const count = toasts[same].count || 1
      toasts.splice(same, 1)
      toasts.push({ ...toast, count: count + 1 })
    } else {
      toasts.push(toast)
    }
    state.data.toast = toasts
  },
  killToast: (state, key) => {
    const toastArray = state.data.toast.filter((n) => {
      return n.key !== key
    })

    state.data.toast = toastArray
  },
  copyBin: (state, item) => {
    state.app.copyBin = item
  },
  copyPlans: (state, val) => {
    const parentMap = {
      'campaign': 'account',
      'adGroup': 'campaign',
      'keyword': 'adGroup',
      'creative': 'adGroup'
    }

    try {
      let obj = {
        plans: val.plans,
        parent: parentMap[val.type],
        type: val.type
      }
      state.app.copyBin = obj
      localStorage.setItem('copiedPlans', JSON.stringify(obj))
    } catch (e) {
      throw e
    }
  },
  // mutations are always sync
  updateUserMode: (state, val) => {
    state.app.userMode = val
  },
  setScrolledPastHeader: (state, val) => {
    state.app.scrolledPastHeader = val
  },
  setHeaderHeight: (state, val) => {
    state.app.headerHeight = val
  },
  setBreadcrumbHeight: (state, val) => {
    state.app.breadcrumbHeight = val
  },
  setAppUnsavedTools: (state, val) => {
    let index = state.app.unsavedTools.indexOf(val.name)
    let toggle = val.value

    if (index !== -1 && !toggle) {
      state.app.unsavedTools.splice(index, 1)
    } else if (index === -1 && toggle) {
      state.app.unsavedTools.push(val.name)
    }
  },
  setActiveAccount: (state, account) => {
    state.data.active.account = account
  },
  setActiveAccountNotifications: (state, notifications) => {
    state.data.active.accountNotifications = notifications
  },
  toggleNotificationMuted (state, hashCode) {
    const notificationSections = [...state.data.active.accountNotifications]
    let sectionIndex = -1
    let taskListIndex = -1
    for (const [index, section] of notificationSections.entries()) {
      const i = section.dashboardTaskList.findIndex(t => t.hashCode === hashCode)
      if (i >= 0) {
        sectionIndex = index
        taskListIndex = i
      }
    }
    if (sectionIndex >= 0 && taskListIndex >= 0) {
      const section = {...notificationSections[sectionIndex]}
      const tasks = [...section.dashboardTaskList]
      const task = { ...tasks[taskListIndex], archived: !tasks[taskListIndex].archived }
      tasks[taskListIndex] = task
      section.dashboardTaskList = tasks
      notificationSections[sectionIndex] = section
      state.data.active.accountNotifications = notificationSections
    }
  },
  setAccountsById: (state, accountsById) => {
    useAccountsById().updateAccountsById(accountsById)
    state.data.accountsById = new Map()
  },
  updateAccountInAccountsById: (state, { id, changes }) => {
    const { accountsByIdRef, updateAccountsById } = useAccountsById()
    const account = accountsByIdRef.value.get(id)
    if (account) {
      accountsByIdRef.value.set(id, { ...account, ...changes })
      updateAccountsById(accountsByIdRef.value)
      state.data.accountsById = new Map()
    }
  },
  setActiveSegment: (state, activeSegment) => {
    state.app.settings.activeSegment = activeSegment
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  setTimeRangeCompare: (state, compare) => {
    state.app.settings.timeRangeCompare = compare
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  setTimeRangeCompareOverride: (state, val) => {
    state.app.settings.timeRangeCompareOverride = val
  },
  setAccountListFilter: (state, filter) => {
    state.data.accountListFilter = filter
  },
  setActiveCampaign: (state, campaign) => {
    state.data.active.campaign = campaign
  },
  setActiveAdGroup: (state, adgroup) => {
    state.data.active.adgroup = adgroup
  },
  setActiveAdGroups: (state, adgroup) => {
    state.data.active.adgroups = adgroup
  },
  setActiveAd: (state, ad) => {
    state.data.active.ad = ad
  },
  setActiveAds: (state, ads) => {
    state.data.active.ads = ads
  },
  setActiveKeyword: (state, keyword) => {
    state.data.active.keyword = keyword
  },
  setActiveKeywords: (state, keywords) => {
    state.data.active.keywords = keywords
  },
  setApiUri: (state, uri) => {
    state.data.apiUri = uri
  },
  // setTimeRange: (state, timeRange) => {
  //   state.data.timeRange = timeRange
  // },
  setClipboard: (state, clipboard) => {
    state.data.clipboard = clipboard
  },
  setOverrides: (state, overrides) => {
    state.data.overrides = overrides
  },
  setActiveBudgets: (state, budgets) => {
    useActiveBudgets().updateActiveBudgets(budgets)
  },
  updateBudgetInActiveBudgets: (state, budget) => {
    useActiveBudgets().updateBudgetInActiveBudgets(budget)
  },
  keywordAnalysisSummary: (state, keywordAnalysisSummary) => {
    state.data.keywordAnalysisSummary = keywordAnalysisSummary
  },
  setActiveReportDefinition: (state, reportDefinition) => {
    if (reportDefinition) {
      let existingDefIndex = state.data.reportDefinitions.findIndex(def =>
        def.googleReportDefinitionId === reportDefinition.googleReportDefinitionId)
      if (existingDefIndex >= 0) {
        state.data.reportDefinitions.splice(existingDefIndex, 1, reportDefinition)
      } else {
        state.data.reportDefinitions.push(reportDefinition)
      }
    }
    state.data.active.reportDefinition = reportDefinition
  },
  setIsReportDownloading: (state, isReportDownloading) => {
    state.data.active.isReportDownloading = isReportDownloading
  },
  setReportDownload: (state, download) => {
    state.data.active.reportDownload = download
  },
  setReportConfigurations: (state, configurations) => {
    state.data.reportConfigurations = configurations
  },
  setGoogleConfigReportFields: (state, fields) => {
    state.data.googleConfigReportFields = fields
  },
  setReportDefinitions: (state, definitions) => {
    state.data.reportDefinitions = [...definitions]
    localStorage && localStorage.setItem('reportDefinitions', JSON.stringify(state.data.reportDefinitions))
  },
  setInit: (state, init) => {
    state.app.init = init
  },
  setDashboardExpandedWidget: (state, expandedWidget) => {
    state.data.expandedWidget = expandedWidget
  },
  usersForCustomer: (state, users) => {
    state.data.usersForCustomer = users
  },
  customerSettings: (state, customerSettings) => {
    state.data.customerSettings = customerSettings
  },
  setShowPlanStatus: (state, showPlanStatus) => {
    state.app.settings.showPlanStatus = showPlanStatus
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  setIncludeReadOnlyAccounts: (state, includeReadOnlyAccounts) => {
    state.app.settings.includeReadOnlyAccounts = includeReadOnlyAccounts
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  setTableToggleFilters: (state, { table, toggle, value }) => {
    state.app.settings.tableToggleFilters[table][toggle] = value
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  showActiveBlueprintsOnly: (state, value) => {
    state.app.settings.showActiveBlueprintsOnly = value
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  broadcasts: (state, broadcasts) => {
    state.data.broadcasts = broadcasts
  },
  customerBroadcasts: (state, broadcasts) => {
    state.data.customerBroadcasts = broadcasts
  },
  accountBroadcastDraftsForCustomer: (state, broadcasts) => {
    state.data.accountBroadcastDraftsForCustomer = broadcasts
  },
  loading: (state, loading) => {
    state.data.loading = loading
  },
  setActingAsUser: (state, actingAsUser) => {
    state.data.actingAsUser = actingAsUser
    if (actingAsUser) {
      localStorage && localStorage.setItem('actingAsUser', state.data.actingAsUser)
    } else {
      localStorage && localStorage.removeItem('actingAsUser')
    }
  },
  audiencePlans: (state, audiencePlans) => {
    state.data.audiencePlans = audiencePlans
  },
  activeAudiencePlan: (state, audiencePlan) => {
    state.data.activeAudiencePlan = audiencePlan
  },
  jobStatus: (state, { objectId, objectType, job }) => {
    state.data.jobStatus = {
      ...state.data.jobStatus,
      [objectType]: {
        ...state.data.jobStatus[objectType],
        [objectId]: job
      }
    }
  },
  launchPlans: (state, launchPlans) => {
    state.data.launchPlans = launchPlans
  },
  navigationSearchTokens: (state, tokens) => {
    state.data.navigationSearchTokens = tokens
  },
  navigationSearchTree: (state, navData) => {
    state.data.navigationSearchTree = navData
  },
  googleContentLinkStatus: (state, pageLinkStatus) => {
    state.data.googleContentLinkStatus = pageLinkStatus
  },
  googleMyBusinessLinkStatus: (state, pageLinkStatus) => {
    state.data.googleMyBusinessLinkStatus = pageLinkStatus
  },
  facebookPageLinkStatus: (state, pageLinkStatus) => {
    state.data.facebookPageLinkStatus = pageLinkStatus
  },
  facebookAccountList: (state, facebookAccountList) => {
    state.data.facebookAccountList = facebookAccountList
  },
  userCoachmarks: (state, userCoachmarks) => {
    state.data.userCoachmarks = userCoachmarks
  },
  createSingle: (state, createSingle) => {
    state.app.createSingle = createSingle
  },
  // whiteLabel: (state, whiteLabel) => {
  //   state.data.whiteLabel = whiteLabel
  // },
  accountPartnerTrackingCodes: (state, trackingCodes) => {
    state.data.accountPartnerTrackingCodes = trackingCodes
  },
  blueprints: (state, blueprints) => {
    state.data.blueprints = blueprints
  },
  campaignNavigation: (state, nav = {}) => {
    useCampaignNavigation().updateCampaignNavigation(nav)
  },
  adGroupNavigation: (state, nav = {}) => {
    useAdGroupNavigation().updateAdGroupNavigation(nav)
  },
  creativeSpecs (state, specs) {
    state.data.creativeSpecs = specs
  },
  timeZones: (state, timeZones) => {
    state.data.timeZones = timeZones
    localStorage && localStorage.setItem('timeZones', JSON.stringify(state.data.timeZones))
  },
  countOfCollaboratePending: (state, count) => {
    const user = { ...state.user }
    user.countOfCollaboratePending = count
    state.user = user
  },
  channelGroups: (state, groups) => {
    state.data.channelGroups = groups
  },
  productScopePlans: (state, { scopes, advertisingChannelId }) => {
    const productScopePlans = { ...state.data.productScopePlans }
    productScopePlans[advertisingChannelId] = scopes
    state.data.productScopePlans = productScopePlans
  },
  evictProductScopePlans: (state) => {
    state.data.productScopePlans = {}
  },
  accountPollingData: (state, data) => {
    state.data.active.accountPollingData = data
  },
  accountQaStatus: (state, { accountPlanId, accountQaStatus }) => {
    state.data.accountQaStatus = {
      ...state.data.accountQaStatus,
      [accountPlanId]: accountQaStatus
    }
  },
  userSavedSegments: (state, userSavedSegments) => {
    state.data.userSavedSegments = userSavedSegments
    localStorage && localStorage.setItem('userSavedSegments', JSON.stringify(state.data.userSavedSegments))
  },
  savedSegmentFields: (state, savedSegmentFields) => {
    state.data.savedSegmentFields = savedSegmentFields
    localStorage && localStorage.setItem('savedSegmentFields', JSON.stringify(state.data.savedSegmentFields))
  },
  activelyRepublishingBlueprints: (state, arr) => {
    state.data.activelyRepublishingBlueprints = arr
  },
  partnerPages: (state, partnerPages) => {
    state.data.partnerPages = partnerPages
  },
  trainingVideos: (state, videos) => {
    state.app.trainingVideos = videos
  },
  accountVideos: (state, videos) => {
    state.app.accountVideos = videos
  },
  trainingVideosLastFetched: (state, val) => {
    state.app.lastFetchedTrainingVideos = val
  },
  toggleForkedUiElement: (state, { val, key }) => {
    state.app.settings.forks[key] = val
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  manageDrawerWidth: (state, val) => {
    state.app.settings.drawers.manage.width = val
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  manageDrawerBootstrapSize: (state, val) => {
    let bsVal = 'sm'

    if (val > 800) {
      bsVal = 'md'
    }
    if (val > 1000) {
      bsVal = 'lg'
    }
    if (val > 1200) {
      bsVal = 'xl'
    }

    state.app.settings.drawers.manage.bootstrapSize = bsVal
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  accountDataSaveInProgress (state, val) {
    state.data.accountDataSaveInProgress = val
  },
  reportRequestResponse (state, val) {
    state.data.reportRequestResponse = val
  },
  setBlueprintCurrentEditingObject (state, val) {
    state.data.blueprintEditing = val
  },
  questionnaireSpecs (state, val) {
    state.data.questionnaireSpecs = val
  },
  adTemplateDataChoices (state, val) {
    state.data.adTemplateDataChoices = val
  },
  setBlueprintEditorNavFilter (state, val) {
    state.app.settings.blueprintEditor2.navFilter = val
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  blueprintEditorCurrentDynamicTags (state, val) {
    state.data.blueprintEditor2.dynamicTags.all = val
  },
  blueprintEditorCurrentDynamicTagAliases (state, val) {
    state.data.blueprintEditor2.dynamicTags.aliases = val
  },
  dismissOfficeMateIntro (state, tool) {
    state.app.settings.officeMateIntroDismissed[tool] = true
    localStorage && localStorage.setItem('backpack.app.settings', JSON.stringify(state.app.settings))
  },
  activeReverseSync (state, val) {
    const { accountPlanId, running } = val

    let arr = state.app.active.reverseSyncs.slice()

    if (running) {
      arr.push(accountPlanId)
    } else {
      arr = arr.filter(el => el !== accountPlanId)
    }

    state.app.active.reverseSyncs = [...new Set(arr)] // unique array
  },
  peerInsights (state, val) {
    state.data.peerInsights = val
  },
  accountPageInstantExperiences (state, val) {
    state.data.accountPageInstantExperiences = val
  },
  instantExperienceLayouts (state, layouts) {
    state.data.instantExperienceLayouts = layouts
  },
  instantExperienceTemplates (state, templates) {
    state.data.instantExperienceTemplates = templates
  },
  layoutBuilderPath (state, val) {
    state.data.layoutBuilderPath = val
  },
  navigationForUser (state, nav) {
    state.app.navigationForUser = nav
  },
  activeSettingsLink (state, link) {
    state.app.activeSettingsLink = link
  }
}

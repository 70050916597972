import _pick from 'lodash.pick'

export const Reporting = (flujax) => ({
  async listHistoric (accountPlanId) {
    return flujax.post(`/reporting/listHistoricReports${accountPlanId ? '?accountPlanId=' + accountPlanId : ''}`)
  },
  async fetchHistoric (receipt) {
    return flujax.post('/reporting/fetchHistoricReport', receipt, { responseType: 'blob', returnFullResponse: true })
  },
  async bulkFetchHistoric (receipts) {
    return flujax.post('/reporting/fetchHistoricReports', receipts, { responseType: 'blob', returnFullResponse: true })
  },
  async fetchCurrentReportTemplates (reportId) {
    return flujax.get(`/reporting/fetchCurrentReportTemplates?reportId=${reportId}`)
  },
  async downloadTemplate (fileName, reportId) {
    return flujax.post(`/reporting/downloadTemplate?reportId=${reportId}&fileName=${fileName}`, null, { responseType: 'blob', returnFullResponse: true })
  },
  async reports (params) {
    let formatParam = ''
    if (params?.format) {
      formatParam = `?format=${params.format}`
    }
    return flujax.fetch.post(`/reporting/getReports${formatParam}`)
  },
  async reportScopes (params) {
    return flujax.fetch.post(`/reporting/getReportScopes?format=${params.format}`)
  },
  async reportFormats (params) {
    return flujax.fetch.post(`/reporting/getReportFormats?scope=${params.scope}`)
  },
  async pageLayouts (params) {
    return flujax.post(`/reporting/getPageLayouts?format=${params.format}`)
  },
  async reportRunFrequencies () {
    return flujax.fetch.post('/reporting/getRunFrequency')
  },
  async generateReportExport ({ reportId, accountPlanId }) {
    let queryString = `reportId=${reportId}`

    queryString += (accountPlanId) ? `&accountPlanId=${accountPlanId}` : ''

    return flujax.fetch.get(`/reporting/generateExport?${queryString}`)
  },
  async reportPdf ({ reportId, accountPlanId }) {
    let accountPlanParam = ''
    if (accountPlanId && accountPlanId > -1) {
      accountPlanParam = `&accountPlanId=${accountPlanId}`
    }
    return flujax.fetch.get(`/reporting/getPdf?reportId=${reportId}${accountPlanParam}&mode=view`, {}, { responseType: 'arraybuffer' })
  },
  async reportPdf2 ({ reportId, payload, accountPlanId }) {
    let accountPlanParam = ''
    if (accountPlanId && accountPlanId > -1) {
      accountPlanParam = `&accountPlanId=${accountPlanId}`
    }
    return flujax.fetch.post(`/reporting/renderReport?reportId=${reportId}${accountPlanParam}`, payload)
  },
  async requestReport ({ reportId, payload, accountPlanId }) {
    let accountPlanParam = ''
    if (accountPlanId && accountPlanId > -1) {
      accountPlanParam = `&accountPlanId=${accountPlanId}`
    }
    return flujax.fetch.post(`/reporting/requestReport?reportId=${reportId}${accountPlanParam}`, payload)
  },
  async reportRequestStatus (payload) {
    return flujax.fetch.post('/reporting/getReportRequestStatus', payload)
  },
  async sectionsForReport (report) { // sections that are attached to a report
    return flujax.fetch.post('/reporting/getSectionsForReport', report)
  },
  async availableSectionsForReport (report) { // available sections you can attach to a report
    return flujax.fetch.post('/reporting/getAvailableSectionsForReport', report)
  },
  async reportDateRanges () {
    return flujax.fetch.post('/reporting/getDateRanges')
  },
  async googleConfigReports () {
    return flujax.fetch.post('/reporting/google/config/listReports')
  },
  async searchQuery (keywordPlan) {
    return flujax.fetch.post('/keyword/searchPhrases', keywordPlan)
  },
  async googleConfigReportFields (reportName) {
    return flujax.fetch.post(`/reporting/google/config/getByName?reportName=${reportName}`)
  },
  async reportDefinitions (reportDefinitionId) {
    const payload = reportDefinitionId ? { googleReportDefinitionId: reportDefinitionId } : {}
    return flujax.fetch.post('reporting/google/getConfiguredReport', payload)
  },
  async allReportDefinitions () {
    return flujax.fetch.post('reporting/google/getConfiguredReport')
  },
  async googleReportById (reportDefinitionId) {
    return flujax.fetch.post('/reporting/google/downloadById', { googleReportDefinitionId: reportDefinitionId })
  },
  async googleReportByDefinition (reportDefinition) {
    const payload = _pick(reportDefinition, 'name', 'during', 'report', 'selectedFields', 'criterionList', 'accountPlans')
    payload.selectedFields = payload.selectedFields.map(field => _pick(field, 'name'))
    payload.criterionList = payload.criterionList.map(field => {
      field.fieldDefinition = _pick(field.fieldDefinition, 'name')
      return _pick(field, 'fieldDefinition', 'operator', 'criterionValues')
    })
    payload.accountPlans = payload.accountPlans.map(plan => _pick(plan, 'accountPlanId'))

    return flujax.fetch.post('reporting/google/downloadByDefinition?noDemo=1', payload)
  },
  async googleReportDefinition (reportDefinition) {
    const payload = _pick(reportDefinition, 'googleReportDefinitionId', 'name', 'during', 'report', 'selectedFields', 'criterionList', 'accountPlans')
    payload.selectedFields = payload.selectedFields.map(field => _pick(field, 'name'))
    payload.criterionList = payload.criterionList.map(field => {
      field.fieldDefinition = _pick(field.fieldDefinition, 'name')
      return _pick(field, 'fieldDefinition', 'operator', 'criterionValues')
    })
    payload.accountPlans = payload.accountPlans.map(plan => _pick(plan, 'accountPlanId'))

    return flujax.set.post('reporting/google/saveConfiguration', payload)
  },
  async set (report) {
    if (report) {
      return flujax.set.post('/reporting/set', report)
    }
  },
  async setSection (section) {
    return flujax.set.post('/reporting/section/set', section)
  },
  async processPresentation (report, accountPlanId) {
    let url = '/reporting/processPresentation'
    if (accountPlanId) {
      url = `${url}?accountPlanId=${accountPlanId}`
    }
    return flujax.post(url, report, { responseType: 'blob', returnFullResponse: true })
  },
  async asyncProcessPresentation (report, accountPlanId) {
    let url = '/reporting/asyncProcessPresentation'
    if (accountPlanId) {
      url = `${url}?accountPlanId=${accountPlanId}`
    }
    return flujax.post(url, report)
  },
  async processPowerPoint (file, accountPlanId) {
    let url = '/reporting/processPowerPoint'
    if (accountPlanId) {
      url = `${url}?accountPlanId=${accountPlanId}`
    }
    return flujax.post(url, file, { responseType: 'blob', returnFullResponse: true })
  },
  async asyncProcessPowerPoint (file, accountPlanId) {
    let url = '/reporting/asyncProcessPowerPoint'
    if (accountPlanId) {
      url = `${url}?accountPlanId=${accountPlanId}`
    }
    return flujax.post(url, file)
  },
  async uploadPowerPointToReport (file, reportId) {
    return flujax.post(`/reporting/uploadPowerPoint?reportId=${reportId}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
    // return flujax.post(`/reporting/uploadPowerPoint?reportId=${reportId}`, file)
  },
  async deletePowerPointForReport (file, reportId) {
    return flujax.post(`/reporting/deleteTemplate?reportId=${reportId}&fileName=${file}`)
  },
  async getPluginInfo () {
    return flujax.post('/reporting/getPluginInfo')
  },
  async getReportContent (reportId, accountPlanId, userInput) {
    let path = `/reporting/getReportContent?reportId=${reportId}`
    if (accountPlanId) path += `&accountPlanId=${accountPlanId}`
    return flujax.post(path, userInput)
  },
  async fetchData (payload, sync) {
    let path = '/reporting/fetchData?updatedFormat=true'
    if (!sync) {
      path += '&async=true'
    }
    return flujax.post(path, { ...payload, includeHiddenAttributes: true })
  },
  async fetchDataSetFields () {
    return flujax.post('/reporting/fetchDatasetFields?updatedFormat=true&concise=true')
  },
  async fetchDataSets () {
    return flujax.post('/reporting/dataset/get')
  },
  async saveDataSet (payload) {
    return flujax.set.post('/reporting/dataset/set', payload)
  },
  async fetchNarrationAvatars () {
    return flujax.post('/reporting/narrate/avatars')
  },
  async fetchNarrationVoices () {
    return flujax.post('/reporting/narrate/voices')
  },
  async sendEmailOnCompletion (asyncDelegatedResponse) {
    return flujax.post('/reporting/dataset/sendEmailOnCompletion', asyncDelegatedResponse)
  },
  async promoteToInternal (payload) {
    return flujax.post('/reporting/dataset/promoteToInternal', payload)
  }
})

import { shallowRef, triggerRef } from 'vue'

const adGroupNavigationRef = shallowRef()
adGroupNavigationRef.value = {}

export function useAdGroupNavigation () {
  return {
    adGroupNavigationRef,
    getAdGroupNavigation: () => adGroupNavigationRef.value,
    updateAdGroupNavigation (newAdGroupNavigation) {
      adGroupNavigationRef.value = { ...adGroupNavigationRef.value, ...newAdGroupNavigation }
      triggerRef(adGroupNavigationRef)
    },
    clearAdGroupNavigation () {
      adGroupNavigationRef.value = {}
    }
  }
}

import { Navigation, Settings, Reporting, PagesNotInNavigation } from '@/assets/js/constants/navigation'
import { defineNuxtRouteMiddleware, useNuxtApp, createError, showError } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to, from) => {
  const nuxtApp = useNuxtApp()
  const roles = nuxtApp.$store.getters.user?.roles

  const checkRole = function (navItem) {
    // if the navigation item matches
    if (to.path.includes(navItem.link) || to.fullPath.includes(navItem.link)) {
      if (navItem.roles && !navItem.roles.some(r => (roles || []).includes(r))) {
        return false
        // error({ statusCode: 404, message: 'This page does not exist.' })
      }
    }
    return true
  }

  const abortNav = () => {
    // TODO matt -vue 3 migration - i'm not entirely sure which one to use here.
    // i think we need a global error page, but was having trouble wiring that up.
    createError({ statusCode: 404, message: 'This page does not exist', fatal: true })
    // abortNavigation('This page does not exist')
  }

  if (roles) {
    for (const navItem of [...Navigation, ...Reporting]) {
      // if the nav item has roles and none of those roles are contained within user roles
      if (!checkRole(navItem)) return abortNav()
      if (navItem.dropDown && navItem.dropDown.length > 0) {
        for (const subNavItem of navItem.dropDown) {
          if (!checkRole(subNavItem)) return abortNav()
        }
      }
    }
    for (const topLevelItem of Settings) {
      for (const section of topLevelItem.sections) {
        for (const link of section) {
          if (!checkRole(link)) return abortNav()
        }
      }
    }
    for (const link of PagesNotInNavigation) {
      if (!checkRole(link)) return abortNav()
    }
  }
})

export const Forum = (flujax) => ({
  async setChannel (channel) {
    return flujax.post('/forum/channel/set', channel)
  },
  async setTopic (topic, forumChannelId) {
    return flujax.post(`/forum/topics/set?channelId=${forumChannelId}`, topic)
  },
  async setReply (reply, forumTopicId) {
    return flujax.post(`/forum/reply/set?topicId=${forumTopicId}`, reply)
  },
  async setReplyUndo (reply, forumTopicId, undo) {
    return flujax.post(`/forum/reply/set?topicId=${forumTopicId}&undoAcceptedAnswer=${undo}`, reply)
  },
  async topicUpvote (id) {
    return flujax.post(`/forum/topic/upVote?topicId=${id}`)
  },
  async topicDownvote (id) {
    return flujax.post(`/forum/topic/downVote?topicId=${id}`)
  },
  async replyUpvote (id) {
    return flujax.post(`/forum/reply/upVote?replyId=${id}`)
  },
  async replyDownvote (id) {
    return flujax.post(`/forum/reply/downVote?replyId=${id}`)
  },
  async acceptReply (id) {
    return flujax.post(`/forum/reply/accept?replyId=${id}`)
  },
  async requestFluency (forumTopicId, value) {
    return flujax.post(`/forum/topic/requestFluency?topicId=${forumTopicId}&value=${value}`)
  },
  async requestFluencyDev (forumTopicId, value) {
    return flujax.post(`/forum/topic/requestFluencyDev?topicId=${forumTopicId}&value=${value}`)
  },
  async subscribe (topic, claimed) {
    return flujax.post(`/forum/topics/subscribe?claimed=${claimed}`, topic)
  },
  async deleteTopic (topic) {
    return flujax.post('/forum/topics/delete', topic)
  },
  async deleteReply (reply) {
    return flujax.post('/forum/topics/reply/delete', reply)
  },
  async channel () {
    return flujax.post('/forum/channels/get')
  },
  async topic (channel) {
    return flujax.post('/forum/topics/get', channel)
  },
  async search ({ searchPhrase, searchTags, channel, forumTopicId, headers }) {
    if (forumTopicId) {
      return flujax.post(`/forum/topics/getById?forumTopicId=${forumTopicId}`, undefined, { headers, returnFullResponse: true })
    }
    return flujax.post(`/forum/topics/search?searchQuery=${searchPhrase}&tagQuery=${(searchTags || []).join()}`, channel, { headers, returnFullResponse: true })
  },
  async listAll () {
    return flujax.post('/forum/topics/search')
  }
})

export const Audience = (flujax) => ({
  async getCustomerAudiences () {
    return flujax.post('/audience/getCustomerAudiences')
  },
  async setCustomerAudience (sharedAudiencePlan) {
    return flujax.post('/audience/setCustomerAudience', sharedAudiencePlan)
  },
  async refreshCustomerAudience (sharedAudiencePlan) {
    return flujax.post('/audience/refreshCustomerAudience', sharedAudiencePlan)
  },
  async getEligibleSharedAudiences (accountPlanId) {
    return flujax.post(`/audience/getEligibleSharedAudiences?accountPlanId=${accountPlanId}`)
  },
  async utilizeSharedAudience (accountPlanId, sharedAudiencePlanId) {
    return flujax.post(`/audience/utilizeSharedAudience?accountPlanId=${accountPlanId}&sharedAudiencePlanId=${sharedAudiencePlanId}`)
  },
  async fetchRtbAudiences (opt = {}) {
    const { creativePlanId, advertisingChannelId } = opt
    let urlParams = null

    if (creativePlanId) {
      urlParams = `creativePlanId=${creativePlanId}`
    }

    if (advertisingChannelId) {
      urlParams = `advertisingChannelId=${advertisingChannelId}`
    }

    if (urlParams) {
      return flujax.post(`/user-list/getDDCRTBTargeting?${urlParams}`)
    }
  }
})

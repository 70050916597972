/*
This file is used as the vue alias in the nuxt.config file for vue3
We need all the old vue 2 stuff to pull in vue/compat
But vue/compat doesn't export unref and version, so nuxt errors.
This "hoists" (idk if i'm using that word right) the exports to get things to compile
 */

import Vue from '@vue/compat'

export const version = '3'
export { unref } from '@vue/reactivity'
export { isVNode } from '@vue/runtime-dom'
export * from '@vue/compat'
export default Vue

import PhoneNumberFormats from 'core-ui/assets/js/constants/phoneNumberFormats'
import { Currency } from 'core-ui/assets/js/constants/i18n/currency'

export const currency = (num, rounding = 0, opts = {}) => {
  const { symbol, markup, code } = opts

  const cc = code || 'USD'
  const sym = (symbol === false) ? '' : Currency?.[cc]?.symbol || '$'
  const symbolOutput = (markup) ? `<span class="format-currency">${sym}</span>` : sym

  let n = parseFloat(num)
  const c = rounding
  const d = '.'
  const t = ','
  const s = n < 0 ? '-' : ''
  const i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)))
  let j = i.length
  j = (j > 3) ? j % 3 : 0
  const retVal = symbolOutput + s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
  return retVal.replace(`${symbolOutput}-`, `-${symbolOutput}`)
}

export const currencyRounder = (num, dir = 'round') => {
  switch (dir) {
    case 'floor':
      return Math.floor(num * 100) / 100
    case 'ceil':
      return Math.ceil(num * 100) / 100
    default:
      return Math.round(num * 100) / 100
  }
}

// percentage, round
export const percentage = (num, round, markup = false) => {
  if (!round && round !== 0) {
    round = 2
  }
  const per = markup ? '<span class="format-percentage">%</span>' : '%'
  return (parseFloat(num) * 100).toFixed(round) + per
}
export const number = (num, decimal, includeCommas) => {
  if (!num && num !== 0) {
    return ''
  }
  const d = (decimal) || 0
  const x = parseFloat(num).toFixed(d)
  if (includeCommas) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return x
  }
}

export const friendlyRoundedBuffer = (num, buffer = 1.1) => {
  const padded = num * buffer
  const rounded = Math.ceil(padded)
  const places = rounded.toString().length - 2 // determine how many places to round to
  const tens = (places < 1) ? 1 : places // always round to at least the 10's place
  const roundTo = Math.pow(10, tens) // value for rounding to 10, 100, 1000, etc
  const returnVal = Math.ceil(padded / roundTo) * roundTo

  // console.table(num, '=>', rounded, '=>', returnVal)

  return returnVal
}

export const convertFromCapsUnderscore = (str = '') => {
  if (typeof str === 'string') {
    const frags = str?.split('_') ?? []
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase()
    }
    return frags.join(' ')
  }
  return str
}

export const capitalize = (str = '') => {
  if (typeof str === 'string') {
    return str.split(' ')
      .map(frag => frag.charAt(0).toUpperCase() + frag.slice(1))
      .join(' ')
  }
  return str
}

export const convertFromCamelCase = (val) => {
  if (val === 'cpc') {
    return 'CPC'
  } else if (val === 'ctr') {
    return 'CTR'
  } else if (val === 'ipAddresses') {
    return 'IP Addresses'
  }

  if (val && typeof val === 'string') {
    if (val.indexOf(' ') > 0) {
      // This is preformatted
      return val
    }

    if (val.indexOf('_') > 0) {
      return convertFromCapsUnderscore(val)
    }

    const result = val
      .replace(/([A-Z][a-z])/g, ' $1')
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/ +/g, ' ')
      .replace(/^ +/g, '')

    return result.charAt(0).toUpperCase() + result.slice(1)
  }
  return ''
}

export const convertFromCapsKebab = (str) => {
  if (typeof str === 'string') {
    const frags = str.split('-')
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase()
    }
    return frags.join(' ')
  }
  return str
}

export const convertToCamelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
  }).replace(/\s+/g, '')
}

export const titlecase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const phoneNumber = (str, countryCode = 'US') => {
  if (!str) return ''

  // handle 1 or +1 prefix
  if ((str.startsWith('1') || str.startsWith('+'))) {
    const format = PhoneNumberFormats[countryCode + 1] || countryCode

    const formatted = str.replace(/[^0-9]/g, '')
      .slice(0, 11)
      .replace(...format)
      
    return str.startsWith('+') ? '+' + formatted : formatted
  } else {
    return str.replace(/[^0-9]/g, '')
      .slice(0, 10)
      .replace(...PhoneNumberFormats[countryCode])
  }
}

export const convertFromCamelCaseToCapsUnderscore = (val) => {
  if (val && typeof val === 'string') {
    const result = val.replace(/([a-z])([A-Z])/g, '$1_$2')
    const finalResult = result.toUpperCase()
    return finalResult
  }
  return ''
}

export const kebabCase = (val) => {
  if (val && typeof val === 'string') {
    const result = val.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    return result
  }
  return ''
}

export const CoreUiFiltersPlugin = {
  install (Vue, options) {
    Vue.config.globalProperties.$filters = {
      ...(Vue.config.globalProperties.$filters || {}),
      currency,
      currencyRounder,
      percentage,
      number,
      friendlyRoundedBuffer,
      convertFromCamelCase,
      convertFromCapsUnderscore,
      convertFromCapsKebab,
      convertToCamelCase,
      capitalize,
      titlecase,
      phoneNumber,
      convertFromCamelCaseToCapsUnderscore,
      kebabCase
    }
  }
}

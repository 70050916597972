import moment from 'moment'

// beginning of year
const boy = date => {
  date.setMonth(0)

  return date
}

// beginning of month
const bom = date => {
  date.setDate(1)

  return date
}

// beginning of day
const bod = date => {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}
// end of day
const eod = date => {
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(999)

  return date
}
// end of month
const eom = date => {
  date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  date = eod(date)

  return date
}

// subtract days
const sub = (date, days) => {
  date.setDate(date.getDate() - days)

  return date
}

// add months
const addMonth = (date, months) => {
  date.setMonth(date.getMonth() + months)
  return date
}

const shortcuts = {
  today: {
    text: 'Today',
    dateRange: [bod(new Date()), eod(new Date())]
  },
  yesterday: {
    text: 'Yesterday',
    dateRange: [sub(bod(new Date()), 1), sub(eod(new Date()), 1)]
  },
  thisWeek: {
    text: 'This Week (Sun - Today)',
    dateRange: [sub(bod(new Date()), new Date().getDay()), eod(new Date())]
  },
  lastSevenDays: {
    text: 'Last 7 Days',
    dateRange: [sub(bod(new Date()), 7), sub(eod(new Date()), 1)]
  },
  lastFourteenDays: {
    text: 'Last 14 Days',
    dateRange: [sub(bod(new Date()), 14), sub(eod(new Date()), 1)]
  },
  thisMonth: {
    text: 'This Month',
    // start = beginning of month of today - 1, so if today is the first, we get a full month of data
    dateRange: [bom(sub(bod(new Date()), 1)), eom(sub(eod(new Date()), 1))]
  },
  lastMonth: {
    text: 'Last Month',
    dateRange: [bom(sub(bom(bod(new Date())), 1)), sub(bom(eod(new Date())), 1)]
  },
  nextMonth: {
    text: 'Next Month',
    dateRange: [bom(addMonth(bod(new Date()), 1)), eom(addMonth(eod(new Date()), 1))]
  },
  allTime: {
    text: 'All Time',
    dateRange: [boy(bom(bod(new Date()))), eod(new Date())]
  },
  Today: { dateRange: [moment(), moment()] },
  Yesterday: { dateRange: [moment().subtract(1, 'days'), moment().subtract(1, 'days')] },
  'This week': { dateRange: [moment().startOf('week'), moment()] },
  'This Week': { dateRange: [moment().startOf('week'), moment()] },
  'Last week': { dateRange: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')] },
  'Last Week': { dateRange: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')] },
  'Last 14 Days': { dateRange: [moment().subtract(14, 'days'), moment()] },
  'Last 30 Days': { dateRange: [moment().subtract(30, 'days'), moment()] },
  'Last 60 Days': { dateRange: [moment().subtract(60, 'days'), moment()] },
  'This month': { dateRange: [moment().startOf('month'), moment().endOf('month')] },
  'This Month': { dateRange: [moment().startOf('month'), moment().endOf('month')] },
  'Last month': { dateRange: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] },
  'Last Month': { dateRange: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] },
  'This Year': { dateRange: [moment().startOf('year'), moment().endOf('year')] },
  'Last Year': { dateRange: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')] },
  'Last 7 Days': { dateRange: [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'day').endOf('day')] },
  'Last 28 Days': { dateRange: [moment().subtract(28, 'days').startOf('day'), moment().subtract(1, 'day').endOf('day')] },
  'Last 90 Days': { dateRange: [moment().subtract(90, 'days').startOf('day'), moment().subtract(1, 'day').endOf('day')] },
  'Last 13 Months': { dateRange: [moment().subtract(13, 'months').startOf('month'), moment().subtract(1, 'month').endOf('month')] },

  shiftDays (shortcutKey, shiftValue) {
    const shortcutObj = { ...this[shortcutKey], dateRange: [...this[shortcutKey].dateRange] }
    shortcutObj.dateRange[0] = moment(shortcutObj.dateRange[0]).add(shiftValue, 'days')
    shortcutObj.dateRange[1] = moment(shortcutObj.dateRange[1]).add(shiftValue, 'days')
    return shortcutObj
  },

  createRangeFromPref (value) {
    const [nothing, days, offset] = value.split(':')
    const start = parseInt(offset) + parseInt(days) - 1
    const end = parseInt(offset)
    return {
      [value]: [moment().subtract(start, 'days'), moment().subtract(end, 'days')]
    }
  }
}
export default shortcuts

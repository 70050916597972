<template>
  <span v-if="isOnBlueprint"
        v-p-tooltip.top="tooltip"
        :class="['lock-icon', {'btn btn-ghost-light': btns, 'd-inline-block mr-2': !btns && !displayLabelFlex, 'd-flex align-items-baseline': displayLabelFlex}]"
        @click.stop="toggle(isFieldLocked ? 'unlock' : 'lock')">
    <fluency-icon :type="isFieldLocked ? 'lock' : 'unlock'"
                  class="lock-icon"
                  :class="{ 'locked-label': isFieldLocked, 'text-muted': !isFieldLocked}" />
    <span v-if="lockLabel" class="d-block">{{isFieldLocked ? lockedLabel : unlockedLabel}}</span>
  </span>
  <span v-else
        v-p-tooltip.top="tooltipNoBlueprint"
        class="text-muted text-line-through opacity-half">
    <fluency-icon type="unlock" class="lock-icon"/>
  </span>
</template>

<script>

export const addLocks = function addLocks ({ lockedFields }, ...fields) {
  let lockedSet
  if (!lockedFields) {
    lockedFields = ''
    lockedSet = new Set([...fields])
  } else {
    lockedSet = new Set([...lockedFields.split(','), ...fields])
  }
  return Array.from(lockedSet).join(',')
}
export const removeLocks = function removeLocks ({ lockedFields }, ...fields) {
  if (!lockedFields) {
    return ''
  }
  const lockedSet = new Set([...lockedFields.split(',')])
  fields.forEach(lockedSet.delete)
  return [...lockedSet].filter(field => field?.length > 0).join(',')
}

export default {
  name: 'lockedFieldV3',
  props: {
    value: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      required: true
    },
    lockLabel: {
      type: Boolean,
      default: false
    },
    btns: {
      type: Boolean,
      default: false
    },
    lockedLabel: {
      type: String,
      default: 'Locked'
    },
    unlockedLabel: {
      type: String,
      default: 'Unlocked'
    },
    displayLabelFlex: {
      type: Boolean,
      default: false
    },
    contentTemplateId: {
      type: Number
      // optionally send in content template id to discern if this is on a blueprint for cases where there is no active item
    },
    tooltip: {
      type: String,
      default: 'Locking prevents overwriting of this field when feed data or Blueprint changes.'
    },
    tooltipNoBlueprint: {
      type: String,
      default: 'This object is not attached to a blueprint and therefore does not need to be locked.'
    }
  },
  data () {
    return {}
  },
  computed: {
    settingsItem () {
      return this.$store.getters.settingsItem
    },
    isOnBlueprint () {
      if (this.contentTemplateId) {
        return true
      } else if (!this.settingsItem) {
        return false
      }
      if (this.settingsItem.planType === 'budget') { // special handling for budget
        return true
      }
      return !!this.settingsItem.contentTemplateId
    },
    lockedSet () {
      if (this.value && this.value.length > 0) {
        return new Set(this.value.split(','))
      } else {
        return new Set()
      }
    },
    isFieldLocked () {
      return this.lockedSet.has(this.field)
    }
  },
  methods: {
    toggle () {
      const copy = (this.value) ? new Set(this.value.split(',')) : new Set() // handle case where value doesn't exist
      if (this.isFieldLocked) {
        copy.delete(this.field)
      } else {
        copy.add(this.field)
      }
      const val = [...copy].filter(field => field?.length > 0).join(',')
      this.$emit('input', val)
    }
  },
  addLocks

}
</script>

<style lang='scss' scoped>
  .lock-icon:hover {
    cursor: pointer;
  }
</style>

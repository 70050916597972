
const initialState = () => ({
  searchPhrase: '',
  searchTags: [],
  selectedChannelId: -1,
  manualTopicId: null,
  fullModal: false,
  channels: [],
  searchResults: [],
  authoring: false,
  editingTopic: null,
  loading: false,
  customGroup: null,
  cacheHeaders: {
    page: 1,
    pageSize: 8,
    sortBy: 'createdOn desc',
    'req-cache-key': ''
  }
})

export const state = initialState

export const getters = {
  cacheHeaders (state) {
    return state.cacheHeaders
  },
  channels (state) {
    return state.channels
  },
  authoring (state) {
    return state.authoring
  },
  fullModal (state) {
    return state.fullModal
  },
  searchPhrase (state) {
    return state.searchPhrase
  },
  searchTags (state) {
    return state.searchTags
  },
  manualTopicId (state) {
    return state.manualTopicId
  },
  selectedChannelId (state) {
    return state.selectedChannelId
  },
  searchResults (state) {
    return state.searchResults
  },
  loading (state) {
    return state.loading
  },
  editingTopic (state) {
    return state.editingTopic
  },
  customGroup (state) {
    return state.customGroup
  }
}

export const mutations = {
  resetState (state) {
    Object.assign(state, initialState())
  },
  cacheHeaders (state, changes) {
    state.cacheHeaders = {
      ...state.cacheHeaders,
      ...changes
    }
  },
  authoring (state, val) {
    state.authoring = val
    if (!val && state.editingTopic) {
      state.editingTopic = null
    }
  },
  editingTopic (state, topic) {
    if (topic) {
      state.editingTopic = topic
      state.authoring = true
    } else {
      state.editingTopic = null
      state.authoring = false
    }
  },
  fullModal (state, val) {
    state.fullModal = val
  },
  channels (state, channels) {
    state.channels = channels
  },
  searchPhrase (state, phrase) {
    state.searchPhrase = phrase
  },
  searchTags (state, tags) {
    state.searchTags = tags
  },
  manualTopicId (state, id) {
    state.manualTopicId = id
  },
  selectedChannelId (state, id) {
    state.selectedChannelId = id
  },
  searchResults (state, searchResults) {
    state.searchResults = searchResults
  },
  customGroup (state, group) {
    state.customGroup = group
  },
  modifyReplyInSearchResults (state, newData) {
    const { forumTopicReplyId, forumTopicId } = newData
    const searchResults = [...state.searchResults]
    const topicIndex = searchResults.findIndex(t => t.forumTopicId === forumTopicId)
    const topic = { ...searchResults[topicIndex] }
    let replyIndex = -1
    if (topic.replies) {
      replyIndex = topic.replies.findIndex(r => r.forumTopicReplyId === forumTopicReplyId)
    }
    if (replyIndex >= 0) {
      topic.replies[replyIndex] = {
        ...topic.replies[replyIndex],
        ...newData
      }
    } else {
      topic.replies = [...(topic.replies || []), newData]
    }
    searchResults[topicIndex] = topic
    state.searchResults = searchResults
  },
  modifyTopicInSearchResults (state, newData) {
    const { forumTopicId } = newData
    const searchResults = [...state.searchResults]
    const topicIndex = searchResults.findIndex(t => t.forumTopicId === forumTopicId)
    if (topicIndex >= 0) {
      searchResults[topicIndex] = {
        ...searchResults[topicIndex],
        ...newData
      }
    } else {
      searchResults.push(newData)
    }
    state.searchResults = searchResults
  },
  bustCache (state) {
    state.cacheHeaders['req-cache-key'] = ''
  },
  loading (state, value) {
    state.loading = value
  }
}

export const actions = {
  async init ({ commit }, query) {
    const channels = await this.$res.forum.channel()
    if (channels) {
      commit('channels', channels)
    }
    if (query) {
      const { searchPhrase, channel, id } = query
      if (searchPhrase) {
        commit('searchPhrase', searchPhrase)
      }
      if (channel && !isNaN(parseInt(channel))) {
        const chanelId = parseInt(channel)
        if (channels.some(ch => ch.forumChannelId === chanelId)) {
          commit('selectedChannelId', chanelId)
        }
      }
      if (id && !isNaN(parseInt(id))) {
        commit('manualTopicId', parseInt(id))
      }
    }
  },
  async search ({ getters, commit }, options) {
    if (!options?.keepCache) {
      commit('loading', true)
      commit('cacheHeaders', { page: 1, 'req-cache-key': '' })
    }
    if (!getters.fullModal) {
      commit('fullModal', true)
    }
    if (getters.customGroup) {
      await commit('customGroup', null)
    }
    let channel
    const { searchPhrase, searchTags, selectedChannelId, channels, cacheHeaders, manualTopicId } = getters
    if (selectedChannelId >= 0) {
      channel = channels.find(ch => ch.forumChannelId === selectedChannelId)
    }
    const response = await this.$res.forum.search({
      searchPhrase,
      searchTags,
      channel,
      forumTopicId: manualTopicId,
      headers: cacheHeaders
    })
    if (response) {
      const { data, headers } = response
      if (headers) {
        commit('cacheHeaders', {
          totalrecords: headers.totalrecords,
          'req-cache-key': headers['req-cache-key']
        })
      }
      if (data) {
        commit('searchResults', data)
      }
    }
    if (getters.loading) {
      commit('loading', false)
    }
  },
  async pageSearchResults ({ commit, dispatch }, page) {
    await commit('cacheHeaders', { page })
    await dispatch('search', { keepCache: true })
  },
  async changeSort ({ commit, dispatch }, sortBy) {
    await commit('cacheHeaders', { sortBy })
    await dispatch('search', { keepCache: true })
  },
  async replyVote ({ commit }, { forumTopicReplyId, up }) {
    let resp
    if (up) {
      resp = await this.$res.forum.replyUpvote(forumTopicReplyId)
    } else {
      resp = await this.$res.forum.replyDownvote(forumTopicReplyId)
    }
    if (resp) {
      commit('bustCache')
      commit('modifyReplyInSearchResults', resp)
    }
  },
  async topicVote ({ commit }, { forumTopicId, up }) {
    let resp
    if (up) {
      resp = await this.$res.forum.topicUpvote(forumTopicId)
    } else {
      resp = await this.$res.forum.topicDownvote(forumTopicId)
    }
    if (resp) {
      commit('bustCache')
      commit('modifyTopicInSearchResults', resp)
    }
  },
  async acceptReply ({ commit, dispatch }, reply) {
    const resp = await this.$res.forum.acceptReply(reply.forumTopicReplyId)
    if (resp) {
      dispatch('requireFluency', { forumTopicId: reply.forumTopicId, value: false })
      commit('bustCache')
      commit('modifyReplyInSearchResults', resp)
    }
  },
  async submitReply ({ commit }, { topic, reply }) {
    const resp = await this.$res.forum.setReply(reply, topic.forumTopicId)
    if (resp) {
      this.$toast('Reply Sent!', 'success')
      commit('bustCache')
      commit('modifyReplyInSearchResults', resp)
      return resp
    }
    return false
  },
  async submitReplyUndo ({ commit }, { topic, reply, undo }) {
    const resp = await this.$res.forum.setReplyUndo(reply, topic.forumTopicId, undo)
    if (resp) {
      this.$toast('Reply Sent!', 'success')
      commit('bustCache')
      commit('modifyReplyInSearchResults', resp)
      return resp
    }
    return false
  },
  async submitTopic ({ getters, dispatch, commit }, { channelName, description, topic }) {
    let { forumChannelId } = topic

    if (!forumChannelId) {
      // get or create channel
      let channel = getters.channels.find(ch => ch.name === channelName)
      if (!channel) {
        channel = await this.$res.forum.setChannel({ name: channelName, description })
        if (channel) {
          const channels = [...getters.channels]
          channels.unshift(channel)
          commit('channels', channels)
        }
      }
      forumChannelId = channel.forumChannelId
    }

    if (forumChannelId) {
      // create/edit topic
      const newTopic = await this.$res.forum.setTopic(topic, forumChannelId)
      if (newTopic) {
        commit('bustCache')
        commit('modifyTopicInSearchResults', newTopic)
        return newTopic
      }
      return false
    }
    return false
  },
  async requireFluency ({ commit }, { forumTopicId, value }) {
    const resp = await this.$res.forum.requestFluency(forumTopicId, value)
    if (resp) {
      const toastMessage = value ? 'Requested Fluency Assistance' : 'Removed Request for Fluency Assistance'
      this.$toast(toastMessage, 'success')
      commit('bustCache')
      commit('modifyTopicInSearchResults', resp)
    }
  },
  async requireFluencyDev ({ commit }, { forumTopicId, value }) {
    const resp = await this.$res.forum.requestFluencyDev(forumTopicId, value)
    if (resp) {
      const toastMessage = value ? 'Requested Fluency Dev Assistance' : 'Removed Request for Fluency Dev Assistance'
      this.$toast(toastMessage, 'success')
      commit('bustCache')
      commit('modifyTopicInSearchResults', resp)
    }
  },
  async subscribe ({ commit, rootGetters }, { topic, claimed }) {
    const res = await this.$res.forum.subscribe(topic, claimed)
    if (res) {
      const message = claimed ? 'Claimed!' : 'Subscribed!'
      this.$toast(message, 'success')
      const { firstName, lastName, userName } = rootGetters.user
      const newWatch = {
        ...res,
        userName,
        firstName,
        lastName
      }
      let watches = topic.watches ? [...topic.watches] : []
      const index = watches?.findIndex(w => w.forumTopicWatchId === res.forumTopicWatchId)
      if (typeof index === 'number' && index >= 0) {
        watches[index] = newWatch
      } else {
        watches = [...watches, newWatch]
      }
      commit('modifyTopicInSearchResults', {
        ...topic,
        watches
      })
      commit('bustCache')
    }
  },
  async deleteTopic ({ commit }, topic) {
    const resp = await this.$res.forum.deleteTopic(topic)
    if (resp) {
      this.$toast('Deleted Topic!', 'success')
      commit('modifyTopicInSearchResults', resp)
      commit('bustCache')
    }
  },
  async deleteReply ({ commit }, reply) {
    const resp = await this.$res.forum.deleteReply(reply)
    if (resp) {
      this.$toast('Deleted Reply!', 'success')
      commit('modifyReplyInSearchResults', resp)
      commit('bustCache')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import _isFunction from 'lodash.isfunction'
import _isPlainObject from 'lodash.isplainobject'

let releaseStage = 'production'
if (document.location.hostname.includes('staging')) {
  releaseStage = 'staging'
} else if (document.location.hostname.includes('canary')) {
  releaseStage = 'canary'
}

const onErrorCallback = (event) => {
  if (event.errors[0].errorClass.includes('NavigationDuplicated')) {
    return false
  } else if (event.errors[0].errorClass.includes('AuthApiError')) {
    return false
  }
}

export const bugsnagInstallNuxt = (nuxtApp) => {
  if (process.env.NODE_ENV === 'production') {
    Bugsnag.start({
      apiKey: '6ee23a4e43b1e4e59aad7746ddb1c01e',
      plugins: [new BugsnagPluginVue()],
      autoTrackSessions: true,
      onError: onErrorCallback,
      enabledBreadcrumbTypes: ['navigation', 'user'],
      releaseStage: releaseStage,
      user: { email: (nuxtApp.$store?.getters.user?.email?.length > 0 ? nuxtApp.$store.getters.user.email : 'no user set') } // TODO add this in the onError callback
    })

    const bugsnagVue = Bugsnag.getPlugin('vue')
    nuxtApp.vueApp.use(bugsnagVue)

    // objOrCallback is
    //    either send in any object {key: value}, a v6 compatibility object like { metadata: { key: value} },
    //        (every key will appear as a custom tab in bugsnag)
    //    or an onError callback like (event) => { }
    // (see busnag doc for more info)
    const compatibleBugsnagNotify = (error, metadataOrCallback, postReportCallback) => {
      const onError = (event) => {
        if (_isFunction(metadataOrCallback)) {
          return metadataOrCallback(event)
        } else if (_isPlainObject(metadataOrCallback)) {
          const metadata = metadataOrCallback.metadata || metadataOrCallback
          Object.entries(metadata).forEach(entry => {
            const value = _isPlainObject(entry[1]) ? entry[1] : { value: entry[1] } // value must be an object. synthesize one if needed
            event.addMetadata(entry[0], value)
          })
        }
      }
      Bugsnag.notify(error, onError, postReportCallback)
    }
    nuxtApp.vueApp.config.globalProperties.$bugsnag = compatibleBugsnagNotify
  } else {
    const notifyDev = (err, objOrCallback, postReportCallback) => {
      console.groupCollapsed('Bugsnag handled error')
      console.error({
        err,
        objOrCallback,
        postReportCallback
      })
      console.groupEnd()
    }
    nuxtApp.vueApp.config.globalProperties.$bugsnag = notifyDev
  }
}

const registeredEvents = {}
const eventBus = {
  $emit (eventType, data) {
    if (typeof eventType !== 'string') {
      throw Error('eventType must be a string')
    }
    for (const callback of (registeredEvents[eventType] || [])) {
      callback(data)
    }
  },
  $on (eventType, callback) {
    if (typeof eventType !== 'string') {
      throw Error('eventType must be a string')
    }
    if (typeof callback !== 'function') {
      throw Error('callback must be a function')
    }
    if (!registeredEvents[eventType]) {
      registeredEvents[eventType] = []
    }
    registeredEvents[eventType].push(callback)
  },
  $off (eventType, callback) {
    if (typeof eventType !== 'string') {
      throw Error('eventType must be a string')
    }
    if (typeof callback !== 'function') {
      throw Error('callback must be a function')
    }
    if (!registeredEvents[eventType]) {
      return
    }
    const i = registeredEvents[eventType].indexOf(callback)
    if (i >= 0) {
      registeredEvents[eventType].splice(i, 1)
    }
  }
}
// export default function (vue, inject) {
//   inject('eventBus', eventBus)
// }
export const eventBusNuxtInstall = (nuxtApp) => {
  nuxtApp.vueApp.config.globalProperties.$eventBus = eventBus
  nuxtApp.provide('eventBus', eventBus)
}

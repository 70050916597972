
let vueApp = null
const $toast = function (...args) {
  if (vueApp) {
    return vueApp.config.globalProperties.$toast(...args)
  }
}

export const ClickCopy = {
  beforeMount (el, binding, vnode) {
    el.classList.add('cursor-pointer')
    el.addEventListener('click', clickCopyListener.bind({ el, binding, vnode }))
  },
  unmounted (el) {
    el.removeEventListener('click', clickCopyListener)
  }
}

async function clickCopyListener (event) {
  event.stopPropagation()
  const text = this.binding.value || event.target.innerText
  if (navigator?.clipboard?.writeText) {
    await navigator.clipboard.writeText(text) // this only works over https connection, will fail on staging
    $toast('Copied', 'success')
  }
}

export const ClickCopyPlugin = {
  install (Vue, options) {
    vueApp = Vue
    Vue.directive('click-copy', ClickCopy)
  }
}

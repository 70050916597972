import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { withTrailingSlash } from '../assets/js/trailingSlash'

export default defineNuxtRouteMiddleware((to, from) => {
  if (!to.path?.endsWith('/')) {
    try {
      const resultPath = withTrailingSlash(to.path)
      return navigateTo({ path: resultPath, query: to.query })
    } catch (e) {
      console.log(e)
    }
  }
})

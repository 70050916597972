// similar to { ...obj1, ...obj2 } but
// this will spread nested objects and arrays too
export const deepSpread = (obj1 = {}, obj2 = {}) => {
  const combined = Array.isArray(obj1) ? [] : {}
  for (const key of [...Object.keys(obj1), ...Object.keys(obj2)].reduce((accumulator, current) => {
    if (accumulator.includes(current)) {
      return accumulator
    } else {
      return [...accumulator, current]
    }
  }, [])) {
    if (typeof obj1[key] === 'object' && obj1[key] !== null && typeof obj2[key] === 'object' && obj2[key] !== null) {
      combined[key] = deepSpread(obj1[key], obj2[key])
    } else if (obj2.hasOwnProperty(key)) {
      combined[key] = obj2[key]
    } else if (obj1.hasOwnProperty(key)) {
      combined[key] = obj1[key]
    }
  }
  return combined
}

export const deepDiff = (obj1 = {}, obj2 = {}, diff = [], accumulativeKey = '') => {
  for (const key of [...Object.keys(obj1), ...Object.keys(obj2)].reduce((accumulator, current) => {
    if (accumulator.includes(current)) {
      return accumulator
    } else {
      return [...accumulator, current]
    }
  }, [])) {
    if (typeof obj1[key] === 'object' && obj1[key] !== null && typeof obj2[key] === 'object' && obj2[key] !== null) {
      diff = deepDiff(obj1[key], obj2[key], diff, `${accumulativeKey}${accumulativeKey ? '.' : ''}${key}`)
    } else if (obj2.hasOwnProperty(key) && obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        diff.push({
          key,
          accumulativeKey,
          diff: 'changed',
          oldVal: obj1[key],
          newVal: obj2[key]
        })
      }
    } else if (obj2.hasOwnProperty(key)) {
      diff.push({
        key,
        accumulativeKey,
        diff: 'added',
        newVal: obj2[key]
      })
    } else if (obj1.hasOwnProperty(key)) {
      diff.push({
        key,
        accumulativeKey,
        diff: 'removed',
        oldVal: obj1[key]
      })
    }
  }
  return diff
}

import routerOptions0 from "/home/production/git/fluent-ui/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  linkActiveClass: "active-link",
  linkExactActiveClass: "exact-active-link",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
}
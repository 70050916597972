const severityMap = {
  High: 3,
  Elevated: 2,
  Normal: 1
}

export const convertToMuteDTO = (tasks) => {
  return tasks.map(task => {
    return {
      sectionTitle: task.section,
      accountPlanId: task.accountPlan.accountPlanId,
      hashCode: task.hashCode
    }
  })
}

const calculateHighestSeverity = (tasks) => {
  return tasks.reduce((highest, current) => {
    if (severityMap[current.severity] > severityMap[highest]) {
      return current.severity
    } else {
      return highest
    }
  }, 'Normal')
}

const actionableTaskTypes = [
  'BUDGET_CONFIGURATION_TASK',
  'EXPIRED_CREATIVE_MESSAGING_TASK',
  'PROTECTED_TERMS_TASK',
  'GROUPED_BUDGET_TASK',
  'BLOCKED_KEYWORDS_TASK',
  'BUDGET_REALLOCATION_TASK',
  'OPTIMIZATION_TASK',
  'LINKED_ACCOUNT_STATUS_TASK'
]

const initialState = () => ({
  byCategory: false,
  byAccount: false,
  selected: [],
  sections: [],
  actionableSections: [],
  loading: false,
  loadingSections: {},
  error: false,
  viewByAccount: false,
  noNotifications: false,
  showArchived: false,
  showCompletedSections: false,
  accountPlanIds: undefined,
  fetchProgress: false,
  workshopNotification: false,
  receipt: false,
  customExperience: '',
  actionable: [],
  skipCount: 0,
  pending: {},
  complete: {},
  // user events
  workshopOpenOnPageLoad: undefined,
  sameAccountAlertsData: null,
  priority: null
})

export const state = initialState

export const getters = {
  accountPlanIds (state) {
    return state.accountPlanIds
  },
  viewByAccount (state) {
    return state.viewByAccount
  },
  byCategory (state) {
    return state.byCategory
  },
  byAccount (state) {
    return state.byAccount
  },
  priority (state) {
    return state.priority
  },
  selected (state) {
    return state.selected
  },
  loading (state) {
    return state.loading
  },
  loadingSections (state) {
    return state.loadingSections
  },
  fetchProgress (state) {
    return state.fetchProgress
  },
  error (state) {
    return state.error
  },
  noNotifications (state) {
    return state.noNotifications
  },
  showArchived (state) {
    return state.showArchived
  },
  sections (state) {
    return state.sections
  },
  workshopNotification (state) {
    return state.workshopNotification
  },
  sameAccountAlertsData (state) {
    return state.sameAccountAlertsData
  },
  receipt (state) {
    return state.receipt
  },
  customExperience (state) {
    return state.customExperience
  },
  actionable (state) {
    return state.actionable
  },
  skipCount (state) {
    return state.skipCount
  },
  pending (state) {
    return state.pending
  },
  complete (state) {
    return state.complete
  },
  actionableSections (state) {
    return state.actionableSections
  },
  showCompletedSections (state) {
    return state.showCompletedSections
  },
  // user events
  workshopOpenOnPageLoad (state) {
    return state.workshopOpenOnPageLoad
  }
}

export const mutations = {
  accountPlanIds (state, value) {
    state.accountPlanIds = value
  },
  resetState (state) {
    Object.assign(state, initialState())
  },
  loading (state, value) {
    state.loading = value
  },
  loadingSections (state, value) {
    state.loadingSections = value
  },
  toggleLoadingSection (state, value) {
    state.loadingSections = {
      ...state.loadingSections,
      [value]: !state.loadingSections[value]
    }
  },
  fetchProgress (state, value) {
    state.fetchProgress = value
  },
  error (state, value) {
    state.error = value
  },
  byCategory (state, value) {
    state.byCategory = value
  },
  priority (state, value) {
    state.priority = value
  },
  byAccount (state, value) {
    state.byAccount = value
  },
  showCompletedSections (state, value) {
    state.showCompletedSections = value
  },
  selected (state, value) {
    state.selected = value
  },
  viewByAccount (state, value) {
    state.viewByAccount = value
  },
  noNotifications (state, value) {
    state.noNotifications = value
  },
  showArchived (state, value) {
    state.showArchived = value
  },
  sections (state, value) {
    state.sections = value
  },
  workshopNotification (state, value) {
    state.workshopNotification = value
    if (state.customExperience) {
      state.customExperience = ''
    }
    if (state.sameAccountAlertsData) {
      state.sameAccountAlertsData = null
    }
  },
  sameAccountAlertsData (state, value) {
    state.sameAccountAlertsData = value
  },
  receipt (state, value) {
    state.receipt = value
  },
  customExperience (state, value) {
    state.customExperience = value
  },
  actionable (state, value) {
    state.actionable = value
  },
  mergeActionable (state, value) {
    // spread new hash codes into current value
    const hashes = state.actionable.map(task => task.hashCode)
    const actionable = [...state.actionable, ...value.filter(task => !hashes.includes(task.hashCode))]
    state.actionable = actionable.sort((a, b) => {
      const aPriority = state.actionableSections.indexOf(a.sectionType)
      const bPriority = state.actionableSections.indexOf(b.sectionType)
      if (aPriority === bPriority) {
        return 0
      } else if (aPriority < bPriority) {
        return -1
      } else {
        return 1
      }
    })
  },
  skipCount (state, value) {
    state.skipCount = value
  },
  pending (state, value) {
    state.pending = value
  },
  setAsPending (state, value) {
    const pending = { ...state.complete }
    pending[value] = true
    state.pending = pending
  },
  complete (state, value) {
    state.complete = value
  },
  setAsComplete (state, value) {
    const complete = { ...state.complete }
    complete[value] = true
    state.complete = complete
  },
  actionableSections (state, value) {
    state.actionableSections = value
  },
  // user events
  workshopOpenOnPageLoad (state, value) {
    state.workshopOpenOnPageLoad = value
  }
}

export const actions = {
  async kickOff ({ commit, dispatch, getters, rootGetters }, query) {
    dispatch('view', query)
    dispatch('fetchPending')
    dispatch('taskPriority')
    const resp = await this.$res.fetch.getTasksList(rootGetters.activeSegment?.savedSegmentId)
    if (resp) {
      const { accountPlanIds, taskSections } = resp
      if (accountPlanIds.length > 0) {
        commit('fetchProgress', taskSections.length)
        commit('sections', taskSections)
        commit('actionableSections', taskSections.filter(section => actionableTaskTypes.includes(section)))
        commit('accountPlanIds', accountPlanIds)
        await Promise.all(taskSections.map(section => dispatch('fetchSection', { section, query })))
        if (getters.viewByAccount) {
          dispatch('select', query)
        }
      }
    } else {
      commit('error', true)
    }
  },

  async taskPriority ({ commit, getters }) {
    const priorities = await this.$res.fetch.notificationPriority()
    if (priorities) {
      commit('priority', priorities)
      // console.log('priorities', priorities)
    }
  },

  async fetchPending ({ commit, getters }) {
    const pending = { ...getters.pending }
    const complete = { ...getters.complete }
    // fetch new list of pending tasks
    const newPending = await this.$res.fetch.getPendingNotifications()
    if (newPending) {
      // loop through previously pending tasks and set them to complete if they are no longer pending
      for (const [hashCode, task] of Object.entries(pending)) {
        if (!newPending[hashCode]) {
          complete[hashCode] = task
        }
      }
      commit('complete', complete)
      commit('pending', newPending)
    }
  },

  async fetchSection ({ commit, getters, dispatch }, { section, query, reFetch }) {
    const resp = await this.$res.fetch.getNotificationSection({ section, accountPlanIds: getters.accountPlanIds })
    if (resp && resp.dashboardTaskList) {
      // take out tasks of this section from tasks by account object
      if (reFetch && getters.byAccount) {
        const byAccount = { ...getters.byAccount }
        for (const id of Object.keys(byAccount)) {
          const dashboardTaskList = byAccount[id].dashboardTaskList.filter(t => t.sectionType !== section)
          const highestSeverity = calculateHighestSeverity(dashboardTaskList)
          byAccount[id] = { ...byAccount[id], dashboardTaskList, highestSeverity }
        }
        commit('byAccount', byAccount)
      }

      // take out tasks from this section from actionable tasks
      if (reFetch && getters.actionable.length > 0) {
        const actionable = getters.actionable.filter(task => task.sectionType !== section)
        commit('actionable', actionable)
      }

      await dispatch('processTasksIntoCategories', { ...resp, sectionType: section })
      if ((reFetch && query) || (!getters.viewByAccount && query?.types?.includes(section))) {
        dispatch('select', query)
      }
    }
    if (!reFetch) {
      commit('fetchProgress', getters.fetchProgress - 1)
    }
  },

  async processTasksIntoCategories ({ commit, getters, dispatch }, section) {
    // enhance data for UI and merge into tasks by category
    const byCategory = { ...getters.byCategory }
    const enhancedSection = {
      ...section,
      dashboardTaskList: section.dashboardTaskList.map(task => ({
        ...task,
        category: section.category,
        section: section.sectionTitle,
        sectionType: section.sectionType,
        accountName: task?.accountPlan?.name
      })),
      highestSeverity: calculateHighestSeverity(section.dashboardTaskList)
    }
    /*
    byCategory[section.category] = {
      ...byCategory[section.category],
      [section.sectionTitle]: enhancedSection
    }

     */
    if (!byCategory[section.category]) {
      byCategory[section.category] = []
    }
    // const taskIndex = getters.priority.indexOf(enhancedSection.sectionTitle)
    const sortingArr = getters.priority
    const oldIndex = byCategory[section.category].findIndex(section => section.sectionType === enhancedSection.sectionType)
    if (oldIndex >= 0) {
      // replace section if it already exists
      byCategory[section.category][oldIndex] = enhancedSection
    } else {
      // else push and sort
      byCategory[section.category].push(enhancedSection)
      if (sortingArr) {
        byCategory[section.category] = byCategory[section.category].sort(function (a, b) {
          // console.log('sort', sortingArr.indexOf(a.sectionTitle), sortingArr.indexOf(b.sectionTitle))
          return sortingArr.indexOf(a.sectionTitle) - sortingArr.indexOf(b.sectionTitle)
        })
      }
    }

    // console.log('enhancedSection', getters.priority.indexOf(enhancedSection.sectionTitle))
    // console.log('byCategory', byCategory)
    commit('byCategory', byCategory)

    // merge new tasks into tasks by account
    await dispatch('processTasksIntoAccounts', [enhancedSection])

    // merge actionable tasks
    const actionable = enhancedSection.dashboardTaskList.filter(
      t => getters.actionableSections.includes(t.sectionType) &&
      !t.archived &&
      !getters.complete[t.hashCode] &&
        (!getters.pending[t.hashCode] || getters.pending[t.hashCode].lastError)
    )
    if (actionable.length > 0) {
      commit('mergeActionable', actionable)
    }
  },

  async processTasksIntoAccounts ({ commit, getters }, sections) {
    const sectionsToProcess = sections || Object.values(getters.byCategory || {}).reduce((total, current) => [...total, ...Object.values(current)], [])
    const byAccount = { ...getters.byAccount }

    for (const section of sectionsToProcess) {
      for (const task of section.dashboardTaskList) {
        if (task?.accountPlan?.accountPlanId) {
          byAccount[task.accountPlan.accountPlanId] = {
            name: task.accountPlan.name,
            highestSeverity: byAccount[task.accountPlan.accountPlanId]?.highestSeverity &&
            severityMap[byAccount[task.accountPlan.accountPlanId].highestSeverity] > severityMap[task.severity]
              ? byAccount[task.accountPlan.accountPlanId].highestSeverity
              : task.severity,
            dashboardTaskList: [...byAccount[task.accountPlan.accountPlanId]?.dashboardTaskList || [], task],
            readOnlyMode: task.accountPlan.readOnlyMode
          }
        }
      }
    }

    commit('byAccount', byAccount)
  },

  async toggleHideSection ({ getters, commit, dispatch }, { categoryName, sectionName, query }) {
    // save hidden sections for user
    const category = getters.byCategory[categoryName]
    const section = category.find(section => section.sectionTitle === sectionName)
    commit('loadingSections', { ...getters.loadingSections, [sectionName]: true })
    const resp = await this.$res.set.userPreference({
      type: 'NOTIFICATION',
      key: sectionName,
      value: !section.hidden
    })
    if (resp) {
      await dispatch('fetchSection', { section: section.sectionType, reFetch: true, query })
    } else {
      this.$toast(`Sorry, there was a problem saving ${sectionName}`)
    }
    commit('loadingSections', { ...getters.loadingSections, [sectionName]: false })
  },

  select ({ getters, commit, rootGetters, dispatch }, { types, accounts }) {
    // put tasks into selected task array based on url params
    let selected = []
    const incReadOnly = rootGetters.includeReadOnlyAccounts
    const showArchived = getters.showArchived
    if (types && !getters.viewByAccount) {
      for (const category of Object.values(getters.byCategory)) {
        for (const section of Object.values(category)) {
          if (types === encodeURIComponent(section.sectionType)) {
            let sectionTasks = section.dashboardTaskList
            if (!incReadOnly) {
              sectionTasks = sectionTasks.filter(task => !task.accountPlan.readOnlyMode)
            }
            if (!showArchived) {
              sectionTasks = sectionTasks.filter(task => !task.archived)
            }
            selected = [...selected, ...sectionTasks]
          }
        }
      }
    }
    if (accounts && getters.viewByAccount) {
      const byAccount = getters.byAccount || {}
      for (const [id, tasks] of Object.entries(byAccount)) {
        if (accounts.includes(id) && (incReadOnly || !tasks.readOnlyMode)) {
          let accountTasks = tasks.dashboardTaskList
          if (!showArchived) {
            accountTasks = accountTasks.filter(task => !task.archived)
          }
          selected = [...selected, ...accountTasks]
        }
      }
    }
    commit('selected', selected)
    dispatch('fetchPending')
  },

  async view ({ getters, commit, dispatch }, { view }) {
    // toggle between view by notification type/account based on url param
    if (view) {
      if (view === 'account' && getters.byCategory && !getters.byAccount) {
        await dispatch('processTasksIntoAccounts')
      }
      commit('viewByAccount', view === 'account')
    }
  },

  async query ({ dispatch, getters, commit }, query) {
    // clear out Same account notifications if that id open
    if (getters.sameAccountAlertsData && (!query.accounts?.includes(getters.sameAccountAlertsData.accountPlan.accountPlanId.toString()) || query.view !== 'account')) {
      commit('sameAccountAlertsData', null)
    }
    // select tasks and set view based on url params
    if ((getters.viewByAccount && (query.view === 'notification' || !query.view)) ||
      (!getters.viewByAccount && query.view === 'account')) {
      await dispatch('view', query)
    }
    dispatch('select', query)
  },

  async edit ({ getters, commit }, { items, action }) {
    for (const task of items) {
      const { category, section, accountPlan, hashCode } = task

      // edit task in tasks by category
      const storeCategory = getters.byCategory[category]
      const sectionIndex = storeCategory.findIndex(s => s.sectionTitle === section)
      const sectionTasks = [...storeCategory[sectionIndex].dashboardTaskList]
      let index = sectionTasks.findIndex(dtl => dtl.hashCode === hashCode)
      switch (action) {
        case 'remove':
          sectionTasks.splice(index, 1)
          break
        case 'mute':
          sectionTasks[index] = { ...sectionTasks[index], archived: true }
          break
        case 'unmute':
          sectionTasks[index] = { ...sectionTasks[index], archived: false }
          break
      }
      commit('byCategory', { ...getters.byCategory, [category]: [...storeCategory.slice(0, sectionIndex), { ...storeCategory[sectionIndex], dashboardTaskList: sectionTasks }, ...storeCategory.slice(sectionIndex + 1)] })
      // commit('byCategory', { ...getters.byCategory, [category]: { ...getters.byCategory[category], [section]: { ...getters.byCategory[category][section], dashboardTaskList: sectionTasks } } })

      if (getters.byAccount) {
        // edit task in tasks by account
        const accountTasks = [...getters.byAccount[accountPlan.accountPlanId].dashboardTaskList]
        index = accountTasks.findIndex(dtl => dtl.hashCode === hashCode)
        switch (action) {
          case 'remove':
            accountTasks.splice(index, 1)
            break
          case 'mute':
            accountTasks[index] = { ...accountTasks[index], archived: true }
            break
          case 'unmute':
            accountTasks[index] = { ...accountTasks[index], archived: false }
            break
        }
        commit('byAccount', { ...getters.byAccount, [accountPlan.accountPlanId]: { ...getters.byAccount[accountPlan.accountPlanId], dashboardTaskList: accountTasks } })
      }

      if (action === 'complete') {
        commit('setAsComplete', hashCode)
      }

      // adjust actionable array
      const actionable = [...getters.actionable]
      const actionableIndex = actionable.findIndex(a => a.hashCode === hashCode)
      if (actionableIndex >= 0 && ['remove', 'mute', 'complete'].includes(action)) {
        // task is in actionable and is being muted or removed so also remove it from actionable array
        actionable.splice(actionableIndex, 1)
        commit('actionable', actionable)
      } else if (task.taskActions && ['unmute'].includes(action)) {
        // task is actionable so put it in actionable array
        actionable.push(task)
        commit('actionable', actionable)
      }
    }
  },

  async mute ({ dispatch }, { items, mute, label, query }) {
    let resp
    if (mute) {
      resp = await this.$res.set.muteNotifications(label, convertToMuteDTO(items))
    } else {
      resp = await this.$res.set.unmuteNotifications(convertToMuteDTO(items))
    }
    if (resp) {
      const text = `Successfully ${mute ? 'muted' : 'unmuted'} ${items.length} notification${items.length > 1 ? 's' : ''}.`
      await dispatch('edit', { items, action: mute ? 'mute' : 'unmute' })
      await dispatch('select', query)
      this.$toast(text, 'success')
    }
    return resp
  },

  async performAction ({ commit, dispatch }, value) {
    const actions = Array.isArray(value) ? value : [value]
    const resp = await this.$res.set.notificationsPerformAction(actions)
    if (resp) {
      await dispatch('fetchPending')
      return resp
    } else {
      return false
    }
  },

  openWorkshop ({ commit, rootGetters }) {
    const splitPaneSizes = { ...rootGetters.splitpanesAlertsWorkshop }
    if (splitPaneSizes.C < 25) {
      splitPaneSizes.C = 25
      splitPaneSizes.B = 100 - (splitPaneSizes.A + splitPaneSizes.C)
      commit('splitpanes', { key: 'alertsWorkshop', value: splitPaneSizes }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}


const poller = {
  blueprintPolling: null
}

export const pollerNuxtInstall = (nuxtApp) => {
  nuxtApp.vueApp.config.globalProperties.$poller = poller
}

// export default defineNuxtPlugin(nuxtApp => {
//   nuxtApp.vueApp.config.globalProperties.$poller = poller
// })
// export default function (vue, inject) {
//   inject('poller', poller)
// }


const manageTypeTiers = [
  ['account'],
  ['budget'],
  ['campaign'],
  ['adGroup', 'criterion'],
  ['ad', 'keyword', 'extension', 'form']
]

const leafNodeTypes = ['ad', 'creative', 'keyword', 'extension', 'form', 'criterion']

const list = manageTypeTiers.flatMap(t => t)

const manageTypeToTableType = {
  adgroup: 'adGroup',
  ad: 'creative'
}

const getNextTierTypes = (activeItemType) => {
  const type = manageTypeToTableType[activeItemType] ?? activeItemType
  const activeTypeTierIndex = manageTypeTiers.findIndex(tier => tier.includes(type))
  const nextTierIndex = Math.min(activeTypeTierIndex + 1, manageTypeTiers.length - 1)
  return manageTypeTiers[nextTierIndex].map(getTableTypeFromManageType)
}

const getChildTypes = (activeItemType) => {
  const type = manageTypeToTableType[activeItemType] ?? activeItemType
  const activeItemIndex = list.indexOf(type)
  return list.slice(activeItemIndex + 1).map(getTableTypeFromManageType)
}

const getParentTypes = (activeItemType) => {
  const type = manageTypeToTableType[activeItemType] ?? activeItemType

  const activeItemIndex = list.indexOf(type)
  return list.slice(0, activeItemIndex).map(getTableTypeFromManageType)
}

const getTableTypeFromManageType = (activeItemType) => {
  return manageTypeToTableType[activeItemType] ?? activeItemType
}
const getManageTypeFromTableType = (tableType) => {
  return manageTypeToTableType[tableType] ?? tableType
}
const isLeafNode = (type) => {
  return leafNodeTypes.includes(type)
}

export default {
  getChildTypes,
  getParentTypes,
  getNextTierTypes,
  getTableTypeFromManageType,
  getManageTypeFromTableType,
  isLeafNode
}

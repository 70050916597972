const defaultPreferences = {
  metricsBarItems: [
    'cost',
    'pacing',
    'clicks',
    'conversions'
  ],
  metricsBarItemsCondensed: [
    'cost',
    'pacing'
  ]
}

export default defaultPreferences

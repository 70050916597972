<script setup>

</script>

<template>
  <div class="list-group">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">

</style>

import ShortKey from 'vue3-shortkey'
import VueScrollTo from 'vue-scrollto'
import moment from 'moment'

import { bootstrapVueNuxtInstall } from 'core-ui/plugins/CustomBootstrapVue.js'
import { primeVueInstall } from 'core-ui/plugins/PrimeVue'

import { defineAsyncComponent } from 'vue'

import FluencyVue from 'core-ui/plugins/FluencyVue'
import { BackpackFiltersPlugin } from '@/assets/js/pluginUtils/BackpackFilters.js'
import { timezonesNuxtInstall } from 'core-ui/plugins/injected/timezones'

import colorPalette from '@/assets/scss/color.scss?inline'
import store from '@/store/index'

import { authnNuxtInstall } from './plugin-fragments/authn'
import { blueprintsNuxtInstall } from './plugin-fragments/blueprints'
import { pageTitleNuxtInstall } from './plugin-fragments/pageTitle'
import { toastNuxtInstall } from './plugin-fragments/toast'
import { bugsnagInstallNuxt } from './plugin-fragments/bugsnag'
import { fluentResourceNuxtInstall } from './plugin-fragments/fluent-resource'
import { pollerNuxtInstall } from './plugin-fragments/poller'
import { trackNuxtInstall } from './plugin-fragments/track'
import { errorNuxtInstall } from './plugin-fragments/error'
import { eventBusNuxtInstall } from 'core-ui/plugins/injected/eventBus'
import { useServiceWorker } from '../composables/useServiceWorker'
import ToastService from 'primevue/toastservice'

// This file is automatically executed by Nuxt because it exists in the plugins folder.

let pluginsApplied = false // this is some weird behavior where nuxt is calling this multiple times? not sure yet

export default defineNuxtPlugin(async nuxtApp => {
  if (!pluginsApplied) {
    await useServiceWorker().activateWaitingServiceWorker()
    useServiceWorker().initServiceWorkerInterval()

    // eslint-disable-next-line no-undef
    if (__IS_DEV_BUILD__) {
      const hooksPlugin = await import('core-ui/plugins/NuxtRuntimeHooksExample.js')
      await hooksPlugin.default(nuxtApp) // inside this plugin, there's a flag you need to turn on to actually see when the hooks are firing
    }

    const Vue = nuxtApp.vueApp

    // authn.js
    authnNuxtInstall(nuxtApp)

    // blueprints.js
    blueprintsNuxtInstall(nuxtApp)

    // eventBus.js
    eventBusNuxtInstall(nuxtApp)

    // this.$color
    Vue.config.globalProperties.$color = { ...colorPalette } // this.$color

    // moment.js
    Vue.config.globalProperties.$moment = moment // this line adds $moment to components for access like this.$moment within the vue2 style options api

    // boostrap vue
    // debugger
    // Vue.use(BootstrapVue)
    bootstrapVueNuxtInstall(nuxtApp)
    primeVueInstall(nuxtApp)

    // third party components
    Vue.use(ShortKey)
    Vue.use(VueScrollTo) // will need to see if this is compatible with vue3

    // global components, directives, filters, alertConfirm
    // filters are still installed using vue2 compatibility Vue.filters
    Vue.use(FluencyVue)
    Vue.use(BackpackFiltersPlugin)
    nuxtApp.provide('filters', Vue.config.globalProperties.$filters)

    // this is how vueCompat adds compatibility for $set.
    // since we want to get off of vueCompat eventually, we migrated to our own $setCompat.
    // Note, you no longer need to use $setCompat - this is just so we don't have to manually remove $set all at once.
    Vue.config.globalProperties.$setCompat = function (target, key, value) {
      target[key] = value
    }

    // backpack global async components
    Vue.component('FluentTable', defineAsyncComponent(() => import('../components/test/fluentTable.vue')))

    // timezones
    timezonesNuxtInstall(nuxtApp)

    // pageTitle
    pageTitleNuxtInstall(nuxtApp)

    // poller
    pollerNuxtInstall(nuxtApp)

    // vuex store
    Vue.use(store)
    nuxtApp.provide('store', Vue.config.globalProperties.$store)

    // bugsnag
    // depends on $store
    bugsnagInstallNuxt(nuxtApp)
    nuxtApp.provide('bugsnag', Vue.config.globalProperties.$bugsnag)

    // toast, uses prime vues $toast and reassigns to $pvtoast, then registers our own $toast
    // depends on $bugsnag
    Vue.use(ToastService)
    toastNuxtInstall(nuxtApp)

    // fluent-resource
    // depends on store and authn, toast, bugsnag
    await fluentResourceNuxtInstall(nuxtApp)

    // track
    // depends on $res and $store
    trackNuxtInstall(nuxtApp)

    // error
    // depends on toast
    errorNuxtInstall(nuxtApp)

    // add to store
    Vue.config.globalProperties.$store.$res = Vue.config.globalProperties.$res
    Vue.config.globalProperties.$store.$pageTitle = Vue.config.globalProperties.$pageTitle
    Vue.config.globalProperties.$store.$authn = Vue.config.globalProperties.$authn
    Vue.config.globalProperties.$store.$router = useRouter()
    Vue.config.globalProperties.$store.$toast = Vue.config.globalProperties.$toast
    Vue.config.globalProperties.$store.$bugsnag = Vue.config.globalProperties.$bugsnag


    pluginsApplied = true
  }
})

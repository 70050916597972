const bpev2Meta = {
  id: '__backpackMetaData',
  availableFields: 'availableFields'
}

const tokenReplaceVal = function (match, fields, onlyOne = false) {
  let returnStr = '&lt;no-data&gt;'

  // const fields = this.activeBlueprint?.[bpev2Meta.id]?.[bpev2Meta.availableFields]

  if (!fields) {
    return returnStr
  }

  if (Array.isArray(fields)) {
    const results = fields.filter(function (entry) {
      return entry.hint === match
    })

    if (results.length > 0) {
      if (Array.isArray(results[0].example)) {
        if (onlyOne) {
          returnStr = results[0].example[0]
        } else {
          returnStr = results[0].example.join(', ')
        }
      } else {
        returnStr = results[0].example
      }
    }
  }

  return returnStr
}
const tokenReplaceValSingle = function (match, fields) {
  return tokenReplaceVal(match, fields, true)
}
const tokenReplaceValMulti = function (match, fields) {
  return `<li>${match} - ${tokenReplaceVal(match, fields)}</li>`
}

const blueprints = {
  meta: bpev2Meta,
  blueprintIndexer: function (t = -1, c = -1, c1 = -1, c2 = -1, last = 'k') {
    /*
     * return a String value like `t0-c4-a2-cr-1
     */
    let output = ''

    output += (t >= 0 && t !== null) ? `t${t}-` : ''
    output += (c >= 0 && c !== null) ? `c${c}-` : ''

    output += (c1 !== null && c1.hasOwnProperty('label') && c1.hasOwnProperty('value')) ? `${c1.label}${c1.value || ''}-` : (c1 >= 0 && c1 !== null) ? `a${c1}-` : ''

    output += (c2 !== null && c2.hasOwnProperty('label') && c2.hasOwnProperty('value')) ? `${c2.label}${c2.value || ''}-` : (c2 >= 0 && c2 !== null) ? `${last}${c2}-` : ''

    return output
  },
  buildActiveItem: function (bp = -1, cmp = -1, ag = -1, cr = -1) {
    /*
     * return an Object that maps to the current selected item in Blueprint Editor
     */
    const active = {
      bp: (bp >= 0) ? bp : null,
      cmp: (cmp >= 0) ? cmp : null,
      ag: (ag >= 0) ? ag : null,
      cr: (cr >= 0) ? cr : null
    }

    return active
  },
  dissectKey: function (sKey) {
    /*
     * takes in an `sKey` like string and returns an `activeItem` like Object
     */
    const parts = sKey.split('-')
    const obj = {
      bp: null,
      cmp: null,
      ag: null,
      cr: null
    }

    parts.forEach(part => {
      obj.bp = (part.indexOf('t') === 0) ? parseInt(part.replace('t', '')) : obj.bp
      obj.cmp = (part.indexOf('c') === 0 && part.indexOf('cr') === -1) ? parseInt(part.replace('c', '')) : obj.cmp
      obj.ag = (part.indexOf('a') === 0) ? parseInt(part.replace('a', '')) : obj.ag
      obj.cr = (part.indexOf('cr') === 0) ? parseInt(part.replace('cr', '')) : obj.cr
    })

    return obj
  },
  labelColors: [
    'rgb(116,173,210)',
    'rgb(57,75,99)',
    'rgb(255,132,124)',
    'rgb(254,206,168)',
    'rgb(153,184,152)',
    'rgb(164,212,230)',
    'rgb(180,156,187)',
    'rgb(207,229,237)',
    'rgb(79,117,175)',
    'rgb(193,173,233)',
    'rgb(184,212,255)',
    'rgb(84, 150, 149)',
    'rgb(190, 227, 188)',
    'rgb(104, 92, 150)',
    'rgb(92, 121, 150)',
    'rgb(227, 155, 150)',
    'rgb(121, 150, 114)',
    'rgb(255, 165, 124)'
  ],
  // tokenReplaceVal,
  tokenReplaceValSingle,
  tokenReplaceValMulti
}

// export default function (vue, inject) {
//   inject('blueprints', blueprints)
// }
export const blueprintsNuxtInstall = (nuxtApp) => {
  nuxtApp.vueApp.config.globalProperties.$blueprints = blueprints

}
// export default defineNuxtPlugin(nuxtApp => {
//   nuxtApp.vueApp.config.globalProperties.$blueprints = blueprints
// })

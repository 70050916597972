const toLocaleStringDefaultOptions = {
  // https://apimirror.com/javascript/global_objects/date/tolocalestring
  formatMatcher: 'best fit',
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'short'
}

const timezones = {
  utcToLocal: function (inputDate, inputOptions) {
    const date = new Date(`${inputDate}Z`)
    const options = { ...toLocaleStringDefaultOptions, ...inputOptions }

    return date.toLocaleString('en-US', options)
  },
  utcToAccount: function (inputDate, timeZone = null, inputOptions = {}) {
    if (timeZone) {
      return this.utcToLocal(inputDate, { timeZone, ...inputOptions })
    }

    return this.utcToLocal(inputDate, inputOptions)
  }
}
let pluginsApplied = false

export const timezonesNuxtInstall = (nuxtApp) => {
  if (!pluginsApplied) {
    const Vue = nuxtApp.vueApp
    Vue.config.globalProperties.$timezones = timezones
    pluginsApplied = true
  }
}

export default defineNuxtPlugin(nuxtApp => {
  timezonesNuxtInstall(nuxtApp)
})

// export default function (vue, inject) {
//   inject('timezones', timezones)
// }

import { ref, isRef } from 'vue'
import { setActiveInterval } from 'core-ui/utils/setActiveInterval'

/* Data Storage */
const partnerPollingData = {
  AccountPlan: {},
  BudgetPlan: {},
  CampaignPlan: {},
  AdGroupPlan: {},
  CreativePlan: {},
  KeywordPlan: {},
  UserListPlan: {},
  FormPlan: {}
}

const addPartnerPollingData = ({ partnerId, planId, type, pendingOperations, lastError, ...otherProperties }) => {
  // if no type, bugsnag
  if (!partnerPollingData[type]) {
    partnerPollingData[type] = {}
    $bugsnag(new Error(`@Matt - New Type Discovered - ${type} - when adding pending work`))
  }
  if (isRef(partnerPollingData[type][planId])) {
    partnerPollingData[type][planId].value = {
      partnerId, planId, type, pendingOperations, lastError, ...otherProperties
    }
  } else {
    partnerPollingData[type][planId] = ref({ partnerId, planId, type, pendingOperations, lastError, ...otherProperties })
  }
}

/*
 *The following three functions are *public*
 */
const getPendingWork = ({ type, planId }) => {
  if (!partnerPollingData[type]) {
    $bugsnag(new Error(`@Matt - Invalid Type - ${type} - when getting pending work`))
  }
  return partnerPollingData[type]?.[planId]?.value ?? {}
}

const addPendingWork = (pendingWork = []) => {
  pendingWork.forEach(work => {
    addPartnerPollingData({
      ...work
    })
  })
  setPollingIfNull()
}
const clearAllPendingWork = () => {
  Object.keys(partnerPollingData)
    .forEach(planType => {
      const planPendingWork = partnerPollingData[planType]
      Object.keys(planPendingWork).forEach(planId => {
        planPendingWork[planId] = null
        delete planPendingWork[planId]
      })
    })
}
/*****************************/

/* Data Fetching and polling */
let stalePollingCount = 0
const checkAndRefreshPendingWork = async () => {
  const allPollingValueRefs = Object.values(partnerPollingData).flatMap(planData => Object.values(planData))
  const onlyPendingData = allPollingValueRefs
    .filter(workRef => workRef.value.pendingOperations?.length > 0)
    .map(workRef => workRef.value)

  if ($resFetchPartnerPollingData && onlyPendingData.length > 0) {
    const responseData = await $resFetchPartnerPollingData(onlyPendingData)
    if (Array.isArray(responseData)) {
      responseData.forEach(work => {
        addPartnerPollingData(work)
      })
    }
  }

  // bump stalePolling count if there's no pending work. set it to 0 if there is
  if (onlyPendingData.length === 0) {
    stalePollingCount++
  } else {
    stalePollingCount = 0
  }

  if (stalePollingCount > 5) {
    // once the stale polling count reaches 5, stop polling
    destroyPolling()
    stalePollingCount = 0
  }
}

/*
* Functions to manage the polling interval.
* */
const INTERVAL_TIMEOUT = 2000
let pollingInterval = null
const setupPolling = function () {
  destroyPolling()
  setPollingIfNull()
}
const setPollingIfNull = () => {
  if (pollingInterval === null) {
    pollingInterval = setActiveInterval(checkAndRefreshPendingWork, INTERVAL_TIMEOUT)
  }
}
const destroyPolling = function () {
  clearInterval(pollingInterval)
  pollingInterval = null
}
// invoke polling immediately
setupPolling()

/*
* The exported composable
* */
let $resFetchPartnerPollingData = null
let $bugsnag = null

export const usePartnerPolling = function () {
  if (!$resFetchPartnerPollingData) {
    $resFetchPartnerPollingData = useNuxtApp().$res.fetch.partnerPollingData
  }
  if (!$bugsnag) {
    $bugsnag = useNuxtApp().$bugsnag
  }
  return {
    getPendingWork,
    addPendingWork,
    clearAllPendingWork
  }
}

export const errorNuxtInstall = (nuxtApp) => {
  let lastError = null

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    if (error?.message === lastError?.message) {
      return 
    }

    const { $toast, $bugsnag } = nuxtApp.vueApp.config.globalProperties

    // Log in console
    console.error(error)

    // Log in Bugsnag
    $bugsnag(error)

    // Show as toast
    $toast(error.message, 'danger')

    lastError = error
  }
}
